import React from "react";
import { Section } from "../styles";
import Lottie from "lottie-react";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { ContactContainer } from "./styles";
import Map from "../../assets/images/allesxr-map.svg";

const Contact = () => {
  const Lottie1 = require("../../assets/lottie/contact.json");

  return (
    <ContactContainer>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft mt-5 mt-sm-0">
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{
                    height: 350,
                  }}
                />
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="Get in Touch with AllesXR.  Thank you for your interest in AllesXR. We would love to
                        hear from you!
                        "
                        className="text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </section>

      <section className="bg-white animate__animated animate__bounceInUp">
        <div className="container">
          <Section className="d-flex  pt-0 pt-sm-5">
            <div className="d-flex">
              <div className="m-0 row margin-0 d-flex align-items-center">
                <div className="col-12 col-sm-6 padding-0">
                  <div className="contact-address">
                    <PrimaryHeading
                      primaryText="Make contact right away!
"
                    />
                    <div className="mb-5">
                      <p>
                        Our team is committed to providing exceptional customer
                        service and will strive to respond to your inquiries in
                        a timely manner.
                      </p>

                      <p>
                        We look forward to connecting with you and exploring how
                        AllesXR can assist you in your AR and VR endeavors.
                      </p>
                    </div>

                    <div className="row m-0">
                      <GridBox className="col-12 col-sm-12 justify-content-start align-items-start ps-5">
                        <div className="d-flex align-items-center my-3">
                          <i className="fa fa-map-marker me-3" />
                          <div className="address-text">
                            4101 34 Rd #34, Dallas, Tax 2353, United States{" "}
                          </div>
                        </div>

                        <div className="d-flex align-items-center my-3">
                          <i className="fa fa-phone me-3" />
                          <div className="address-text">
                            <a href="tel: +1 469-213-6438"> +1 112-333-4444</a>
                          </div>
                        </div>

                        <div className="d-flex align-items-center my-3">
                          <i className="fa fa-envelope me-3" />
                          <div className="address-text">
                            <a href="mailto:info@allesxr.com">
                              info@allesxr.com
                            </a>
                          </div>
                        </div>
                      </GridBox>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 padding-0">
                  <div className="contact-address">
                    <img src={Map} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </section>

      <div className="bg-gray p-5">
        <div className="container">
          <div className="card rounded-5 shadow-lg py-3 p-sm-4 p-md-5">
            <div className="card-body position-relative zindex-2">
              <PrimaryHeading primaryText="How may we assist you today?" />
              <p className="text-black">
                Please submit your contact information and enquiry information
                to get in touch.
              </p>

              <form>
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="qbb1gapMo8EZg9Dc1qKCNarwH28RoXajgNx0xmz9"
                />{" "}
                <div className="row">
                  <div className="col-md-6 mb-3 pb-1">
                    <label className="form-label">
                      First name<sup className="text-danger ms-1">*</sup>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="first_name"
                      id="first_name"
                    />
                    <div className="invalid-feedback">
                      Please enter your first name!
                    </div>
                    <div className="valid-feedback">You're awesome!</div>
                  </div>
                  <div className="col-md-6 mb-3 pb-1">
                    <label className="form-label">
                      Last name
                      <sup className="text-danger ms-1" />
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="last_name"
                      id="last_name"
                    />
                    <div className="invalid-feedback">
                      Please enter your last name!
                    </div>
                    <div className="valid-feedback"> </div>
                  </div>
                </div>
                <div className="mb-3 pb-1">
                  <label className="form-label">
                    Email address<sup className="text-danger ms-1">*</sup>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="name@example.com"
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address!
                  </div>
                  <div className="valid-feedback">You're awesome!</div>
                </div>
                <div className="mb-3 pb-1">
                  <label className="form-label">
                    Message<sup className="text-danger ms-1">*</sup>
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    id="cont-message"
                    placeholder="Write a message"
                    defaultValue={""}
                  />
                  <div className="invalid-feedback">
                    Please write a message!
                  </div>
                  <div className="valid-feedback">You're awesome!</div>
                </div>
                <div className="text-center pt-2">
                  <button
                    id="submitEnquiryBtn"
                    className="btn btn-outline-dark"
                    type="submit"
                  >
                    <i className="button-symbol" />
                    <i
                      className="button-symbol-loading d-none spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="button-text">Submit Enquiry</span>
                    <span className="button-text-loading d-none">
                      Sending..
                    </span>
                  </button>
                </div>
                <br />
                <div
                  className="alert alert-success mb-4 d-none"
                  id="form-response-success"
                ></div>
                <div
                  className="alert alert-warning mb-4 d-none"
                  id="form-response-error"
                ></div>
                <input
                  type="hidden"
                  name="recaptcha"
                  id="recaptchaContactForm"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </ContactContainer>
  );
};

export default Contact;
