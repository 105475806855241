import React from "react";
import { StyledTabContainer } from "./styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function SectionTabs() {
  const VRImg2 = require("../../assets/images/vr-2.gif");

  return (
    <section className="bg-white">
      <StyledTabContainer className="pt-5 mt-md-0 pt-md-5 pt-sm-2 mb-0 mb-sm-0">
        <div className="container">
          <div className="m-0 row justify-content-center text-center pt-md-3 pb-4 py-lg-5 mb-1">
            <div className="col-xl-12 col-lg-10 col-md-9 ">
              <Primaryheading
                primaryText="Unleash your potential with AllesXR VR"
                secondaryText=""
              />
              <div className="col-md-8 col-12 offset-md-2 text-center align-items center">
                <p className="fs-5 mb-0">
                  Experience the power of immersive VR technology and unlock new
                  possibilities with AllesXR's cutting-edge VR products and
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 pt-lg-4">
          <div>
            <div className="container">
              <div id="convert">
                <div className="m-0 row d-flex align-items-center">
                  <div className="col-12 col-sm-6">
                    <div className="text-center text-sm-start mx-auto">
                      {/* <h3 className="h1 pb-2 pb-sm-0 text-dark">Lorem Ipsum</h3> */}
                      <p className="d-none d-md-block">
                        AllesXR is dedicated to providing you with the most
                        cutting-edge technology for immersive virtual reality
                        experiences.
                      </p>
                      <p className="pb-3 pb-lg-4 mb-1">
                        Our innovative products and services are designed to
                        take your experience to the next level, whether you're a
                        gamer looking for the ultimate immersive experience, a
                        business looking to create engaging VR content for your
                        customers, or an educator looking to bring your lessons
                        to life.
                      </p>
                      <ul className="d-md-block d-none text-start">
                        <li className="mb-2">
                          AllesXR offers cutting-edge technology for immersive
                          VR experiences, Our products and services are designed
                          to take your experience to the next level
                        </li>
                        <li className="mb-2">
                          We offer VR experiences for exploring new worlds,
                          learning new skills, and connecting with others Our
                          team is passionate about creating immersive and
                          interactive experiences
                        </li>
                        <li className="mb-5">
                          We're constantly innovating and developing new
                          products and services to enhance your experience
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 position-relative">
                    <img src={VRImg2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute w-100 opacity-25 d-none"
          style={{ right: 0, top: "23%", paddingLeft: "35%" }}
        >
          <svg
            className="text- ms-n4"
            width={200}
            height={200}
            viewBox="0 0 200 211"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </StyledTabContainer>
    </section>
  );
}
