import styled from "styled-components";

export const Tabs = styled.div`
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0;

    li {
      margin: 0 16px;
      background-color: white;
      border-radius: 24px;
      padding: 2px 16px;
      color: #146ad3;
      cursor: pointer;
      border: solid 1px #146ad3;

      &:hover {
        background-color: #146ad3;
        color: white;
      }

      @media (max-width: 480px) {
        margin: 10px 5px;
      }
    }

    .active {
      background-color: #146ad3;
      color: white;
    }
  }
`;

export const NoProjectsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px;
  color: red;
`;

export const ProjectGrid = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;

  img {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  p {
    font-weight: 600;
  }

  &:hover {
    transform: scale(1.02);
  }

  .disabled {
    cursor: no-drop;
    opacity: 0.7;
  }
`;

export const GalleryContainer = styled.div`
  .gallery-custom-wrapper {
    display: flex;
    flex-wrap: wrap;

    a {
      flex: 1;
      margin: 16px 0;
      border: solid 1px #eee;
      border-radius: 12px;
      margin: 10px;
    }
  }
`;
