import React from "react";
import { PrimaryHeading } from "../styles";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

export default function HeaderBanner() {
  const Lottie1 = require("../../assets/lottie/1.json");

  return (
    <section className="bg-dark ">
      <div className="container">
        <div
          className="m-0 row d-flex align-items-center nimate__animated animate__fadeInUp"
          style={{ minHeight: "700px" }}
        >
          <div className="col-md-6 col-sm-12 d-flex flex-column mt-md-4 pt-5 pb-3 pb-sm-4 py-md-5 text-md-start text-center">
            <PrimaryHeading className="section-heading display-1 pb-2 pb-sm-3">
              Experience reality like never before with AllesXR.
            </PrimaryHeading>
            <h2 className="fs-5 fw-normal pb-2 pb-md-0 mb-4 mb-md-5 text-white ps-2 pe-4">
              We specialize in creating immersive virtual experiences that
              transport you to other worlds and allow you to explore, learn, and
              connect in ways you never thought possible.
            </h2>
            <div className="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
              <Link
                to="/Showcase"
                className="btn btn-primary rounded-pill border-0 shadow-dark"
              >
                Showcase Projects
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex justify-content-center">
            <Lottie animationData={Lottie1} loop={true} />
          </div>
        </div>
      </div>
    </section>
  );
}
