import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import P1 from "../../../assets/images/creative.svg";
import P2 from "../../../assets/images/creative-1.svg";

export default function XRRemoteCollaboration() {
  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft mb-5 mb-sm-0">
                <img src={P1} alt="Images" className="w-100" />
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="XR Remote Collaboration"
                        className="text-white"
                      />
                      <p className="sub-text text-white">
                        XR remote collaboration refers to the use of virtual and
                        augmented reality technologies to facilitate remote
                        collaboration between team members. With XR remote
                        collaboration, team members can work together in
                        real-time from different locations, interact with
                        virtual objects, and collaborate on projects as if they
                        were in the same physical space.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-black">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-7 order-1 order-sm-0">
                <div className="sec-title text-white">
                  <h1 className="text-white">
                    Why Choose Us for XR Remote Collaboration?
                  </h1>
                  {/* <p className="margin-0 pt-15">
                    One of the key advantages of XR Remote Collaboration is its
                    ability to transcend traditional communication barriers.
                    Team members can visualize and manipulate complex data,
                    models, or designs together, gaining a shared understanding
                    of the project.
                  </p> */}
                  <p className="margin-0 pt-15">
                    This leads to faster decision-making, increased
                    productivity, and reduced errors. Whether it's a design
                    review, a training session, or a remote meeting, XR Remote
                    Collaboration empowers teams to work together seamlessly as
                    if they were physically present in the same location.
                  </p>
                  {/* <p className="margin-0 pt-15">
                    XR remote collaboration can improve collaboration between
                    team members by providing a more immersive and interactive
                    experience.
                  </p>
                  <p className="margin-0 pt-15">
                    By working together in real-time, team members can increase
                    their efficiency and productivity.
                  </p>
                  <p className="margin-0 pt-15">
                    XR remote collaboration eliminates the need for travel,
                    reducing travel costs and improving work-life balance.
                  </p>
                  <p className="margin-0 pt-15">
                    With XR remote collaboration, team members can access
                    experts from different locations and collaborate with them
                    on projects.
                  </p> */}
                </div>
              </div>

              <div className="col-12 col-sm-5  order-0 order-sm-1 mb-5 mb-sm-0">
                <div className="images-part">
                  <img src={P2} alt="Images" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        ={" "}
      </Section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="XR Remote Collaboration Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                   
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Virtual Collaboration
                          <br />
                          Spaces
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We create virtual collaboration spaces where team
                        members can meet and interact with each other in
                        real-time.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Virtual Object <br /> Interaction
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We use augmented reality technologies to allow team
                        members to interact with virtual objects as if they were
                        in the same physical space.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Remote Training <br /> & Support
                        </a>
                      </h5>
                      <p className="services-txt">
                        We use virtual and augmented reality technologies to
                        provide remote training and support to team members who
                        need help with a project or task.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Facilitating Seamless Collaboration with XR Remote Collaboration" />
              <p className="mt-5">
                XR Remote Collaboration is revolutionizing the way teams work
                together by enabling immersive and real-time collaboration
                regardless of geographical distances. At AllesXR, we specialize
                in developing XR Remote Collaboration solutions that leverage
                augmented reality (AR), virtual reality (VR), and mixed reality
                (MR) technologies to connect team members, enhance
                communication, and streamline collaborative workflows.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="By embracing XR Remote Collaboration" />
              <p className="mt-5">
                Businesses can unlock new levels of productivity, innovation,
                and efficiency. At AllesXR, we are committed to helping
                organizations harness the power of XR technologies to transform
                the way they collaborate, break down geographical barriers, and
                unlock the full potential of their teams.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
