import styled from "styled-components";

export const ContactContainer = styled.div`
  .address-text,
  a,
  i {
    font-size: 18px;
    font-weight: 600;
    color: #0d6efd;
    text-decoration: none;
  }
`;
