import React from "react";
import { SectionHeading } from "../styles";

interface PrimaryHeadingProps {
  primaryText?: string;
  secondaryText?: string;
  tagline?: string;
  className?: string;
}

export default function PrimaryHeading(props: PrimaryHeadingProps) {
  return (
    <SectionHeading className={props.className}>
      <div className="d-flex align-items-center pb-0 pb-sm-4">
        <span>
          <b>{props.primaryText}</b>
        </span>
        <span>{props.secondaryText}</span>
      </div>
      <p className="tagline">{props.tagline}</p>
    </SectionHeading>
  );
}
