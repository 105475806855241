import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { GridBox } from "../../UIComponents/FourGrid/styles";
import { BackgroundImage } from "../../styles";
import Img2 from "../../../assets/images/frame.svg";
import E3 from "../../../assets/images/e3.svg";
import Img23 from "../../../assets/images/23.svg";
import Img24 from "../../../assets/images/24.svg";
import Img25 from "../../../assets/images/25.svg";

export default function Education() {
  return (
    <div>
      <section className="bg-light py-5rem">
        <div className="container pt-0 pt-sm-5">
          <div className="row pt-3 pb-2 py-md-4 align-items-center">
            <div className="col-12 col-sm-6 order-0 animate__animated animate__fadeInLeft">
              <PrimaryHeading
                primaryText="Revolutionizing Education: The Impact of AR/VR in Learning"
                className="text-black align-items-start text-gradient-dark"
              />
              <h2 className="text-black">Education</h2>

              <p className="mb-4 pb-4 text-black">
                AR and VR technologies have transformed the field of education,
                offering immersive and interactive experiences that enhance
                learning outcomes. By integrating AR and VR into classrooms,
                students can engage with subjects in a whole new way, making
                learning more engaging and meaningful.
              </p>
            </div>
            <div className="col-12 col-sm-6 order-1 pt-5 pt-sm-0 animate__animated animate__fadeInRight">
              <img
                src={E3}
                className="card-img card-img-bottom bg-size-cover bg-repeat-0 bg-position-center"
                alt="Home Interior"
              />
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="bg-gradiant1 py-5rem "
        style={{
          background: `url(${Img2})`,
        }}
      >
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 animate__animated animate__fadeInUp">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6  mt-5 mt-sm-0 order-1 order-sm-0">
                <img src={Img25} alt="Home Interior" />
              </div>
              <div className="col-12 col-sm-6   order-0 order-sm-1 ps-0 ps-sm-5">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="">
                      <PrimaryHeading
                        primaryText="Enhance Education with Immersive Technology"
                        className="text-white align-items-start"
                      />
                      <p className="sub-text text-white">
                        AR and VR revolutionize training with immersive
                        experiences and VR wearables. Learners can adapt easily
                        and receive effective training on various topics such as
                        safety, product presentation, and sales simulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </BackgroundImage>

      <Section>
        <div className="container position-relative">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <PrimaryHeading primaryText="Immersive Training Solutions for Corporates" />
              <p className="fs-5 mb-4 pb-4 ">
                Immerse participants in realistic, high-fidelity simulations
                with virtual elements.
              </p>
            </div>
          </div>
          <div>
            <div className="row g-4 pb-2 pb-sm-4 mb-sm-2">
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Enhancing
                      <span className="text-warning"> Collaborative</span>{" "}
                      Learning
                    </h3>
                    {/* <CanvasContainer>
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: 100,
                          height: "100%",
                        }}
                      />
                    </CanvasContainer> */}
                    <img
                      src={Img23}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img23}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      AR/VR simulations eliminate geographical barriers,
                      enabling seamless online training. Learners can connect
                      and collaborate with fellow corporate learners
                      effortlessly, enhancing the learning experience.
                    </p>
                  </div>
                </GridBox>
              </div>
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Learning with
                      <span className="text-warning"> Interactive </span> 3D
                      Models
                    </h3>
                    {/* <CanvasContainer>
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: 100,
                          height: "100%",
                        }}
                      />
                    </CanvasContainer> */}
                    <img
                      src={Img24}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img24}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      There are several complicated jobs that demand displaying
                      a rich, interactive experience through vivid realistic 3D
                      representations of the relevant topics, allowing learners
                      to comprehend and grasp them more easily.
                    </p>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section className="bg-black">
        <div className="rs-about">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional augmented reality. our team is ready
                    to assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
