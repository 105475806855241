import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { GridBox } from "../../UIComponents/FourGrid/styles";

import { BackgroundImage } from "../../styles";
import Img1 from "../../../assets/images/manufacturing.svg";
import Img2 from "../../../assets/images/frame.svg";
import Img3 from "../../../assets/images/manufacturing1.png";
import Img19 from "../../../assets/images/19.png";
import Img26 from "../../../assets/images/26.svg";

export default function Manufacturing() {
  return (
    <div>
      <section className="bg-light py-5rem">
        <div className="container pt-0 pt-sm-5">
          <div className="row pt-3 pb-2 py-md-4 align-items-center">
            <div className="col-12 col-sm-6 order-0 animate__animated animate__fadeInLeft">
              <PrimaryHeading
                primaryText="Ignite Manufacturing Excellence with Immersive AR/VR Training"
                className="text-black align-items-start text-gradient-dark"
              />
              <h2 className="text-black">Advancing Manufacturing Skills</h2>

              <p className="mb-4 pb-4 text-black">
                AllesXR revolutionizes manufacturing training with immersive
                AR/VR courses. Covering machine and product designs, plant
                layouts, and safety lessons, our programs offer a safe and
                practical learning environment. Say goodbye to traditional
                methods and embrace the future of manufacturing training with
                AllesXR.
              </p>
            </div>
            <div className="col-12 col-sm-6 order-1 pt-5 pt-sm-0 animate__animated animate__fadeInRight">
              <img
                src={Img1}
                className="card-img card-img-bottom bg-size-cover bg-repeat-0 bg-position-center"
                alt="Home Interior"
              />
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="bg-gradiant1 py-5rem"
        style={{
          background: `url(${Img2})`,
        }}
      >
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 animate__animated animate__fadeInUp">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6  mt-5 mt-sm-0 order-1 order-sm-0">
                <img src={Img3} alt="Home Interior" />
              </div>
              <div className="col-12 col-sm-6   order-0 order-sm-1 ps-0 ps-sm-5">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="">
                      <PrimaryHeading
                        primaryText=" Streamlining Assembly and Quality Control with AR/VR Training"
                        className="text-white align-items-start"
                      />
                      <p className="sub-text text-white">
                        AllesXR's immersive AR/VR training, employees can say
                        goodbye to traditional paper manuals and embrace a
                        dynamic learning experience. Our training programs
                        provide a safe environment for practicing complex
                        assemblies and mastering quality control techniques,
                        resulting in reduced errors and enhanced efficiency on
                        the job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </BackgroundImage>

      <Section>
        <div className="container position-relative">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <PrimaryHeading primaryText="Employee Training in Real Time" />
              <p className="fs-5 mb-4 pb-4 ">
                No matter your industry, 3D Product Configurators can help you
                demonstrate why your products are the best!
              </p>
            </div>
          </div>
          <div>
            <div className="row g-4 pb-2 pb-sm-4 mb-sm-2">
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Immersive
                      <span className="text-warning"> Simulations</span> for
                      Effective Training
                    </h3>
                    {/* <CanvasContainer>
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: 100,
                          height: "100%",
                        }}
                      />
                    </CanvasContainer> */}
                    <img
                      src={Img19}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img19}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Leverage the power of AR and VR for employee training
                      through immersive simulations. Replicating various
                      scenarios, employees gain practical experience and improve
                      their skills.
                    </p>
                  </div>
                </GridBox>
              </div>
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Real-Time
                      <span className="text-warning"> Guidance </span>
                      for Efficient Production
                    </h3>

                    <img
                      src={Img26}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img26}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Our custom AR/VR solutions offer real-time guidance to
                      assembly line workers, providing detailed instructions,
                      virtual assistance, production processes, saves time, and
                      reduces errors.
                    </p>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section className="bg-black">
        <div className="rs-about">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional augmented reality. our team is ready
                    to assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
