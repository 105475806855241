import React, { useEffect, useRef } from "react";
import { CanvasContainer, Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default function ThreeDModelling() {
  const canvasRef = useRef(null);
  const canvasRefOne = useRef(null);

  const webAR1 = require("../../../assets/three/american-football-5524917.glb");
  const webAR2 = require("../../../assets/three/camera-5976277.glb");

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      750,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current ?? document.createElement("canvas"),
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);

    const loader = new GLTFLoader();
    loader.load(webAR1, (gltf) => {
      const model = gltf.scene;
      model.position.set(-1, -1, -1);
      scene.add(model);

      const animate = () => {
        requestAnimationFrame(animate);

        // Rotate the model
        model.rotation.y += 0.01; // Adjust the rotation speed as needed
        renderer.render(scene, camera);
      };

      animate();
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(1, 5, 10);
    controls.update();

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(1, 2, 5);
    scene.add(pointLight);
  });

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      750,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRefOne.current ?? document.createElement("canvas"),
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);

    const loader = new GLTFLoader();
    loader.load(webAR2, (gltf) => {
      const model = gltf.scene;
      model.position.set(0, -4, -3);
      scene.add(model);

      const animate = () => {
        requestAnimationFrame(animate);

        // Rotate the model
        model.rotation.y += 0.01; // Adjust the rotation speed as needed
        renderer.render(scene, camera);
      };

      animate();
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(1, 5, 10);
    controls.update();

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(1, 2, 5);
    scene.add(pointLight);
  });

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft mb-5 mb-sm-0">
                <CanvasContainer>
                  <canvas
                    ref={canvasRef}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </CanvasContainer>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="3D Modelling and Animation"
                        className="text-white"
                      />
                      <p className="sub-text text-white">
                        3D modelling and animation refers to the process of
                        creating three-dimensional digital models and animations
                        using specialized software. 3D models are used in a
                        variety of industries, including architecture,
                        engineering, film and video game production, and product
                        design.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-black">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-7 order-1 order-sm-0">
                <div className="sec-title text-white">
                  <h1 className="text-white">
                    Why Choose Us for 3D Modelling and Animation?
                  </h1>

                  <p className="margin-0 pt-15">
                    3D modelling and animation can increase efficiency in design
                    and prototyping by allowing for quick and easy changes to
                    the model.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-5  order-0 order-sm-1 mb-5 mb-sm-0">
                <div className="images-part">
                  <CanvasContainer>
                    <canvas
                      ref={canvasRefOne}
                      // style={{
                      //   width: "100%",
                      //   height: "100%",
                      // }}
                    />
                  </CanvasContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="3D Modelling and Animation Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Product <br />
                          Design
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We create 3D models and animations of products to aid in
                        design and prototyping.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Architectural <br /> Visualization
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We create 3D models and animations of buildings and
                        environments to aid in architectural visualization.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Film and Video <br /> Game Production
                        </a>
                      </h5>
                      <p className="services-txt">
                        We create 3D models and animations for use in film and
                        video game production.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Bringing Your Ideas to Life with Stunning Visual Detail" />
              <p className="mt-5">
                At AllesXR, we specialize in 3D modeling and animation services
                that bring your ideas to life in stunning visual detail. Our
                team of skilled artists and designers leverage cutting-edge
                tools and techniques to create realistic and captivating 3D
                models and animations for various industries and applications.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText=" Specializing in Cutting-Edge 3D Modeling and Animation Techniques" />
              <p className="mt-5">
                We understand the importance of quality and realism in 3D
                modeling and animation, as they play a crucial role in creating
                immersive and captivating experiences. Our team meticulously
                crafts textures, lighting, and physics to ensure that the final
                output is visually stunning and realistic, resonating with your
                target audience and leaving a lasting impression.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
