import React, { useEffect, useRef } from "react";
import { CanvasContainer, Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { GridBox } from "../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { BackgroundImage } from "../../styles";
import Img2 from "../../../assets/images/frame.svg";
import PillarImg from "../../../assets/images/pillar.svg";
import VirtualImg from "../../../assets/images/virtual.svg";
import convert3DImg from "../../../assets/images/2d-fp.svg";

export default function RealEstate() {
  const canvasRef = useRef(null);
  const Img1 = require("../../../assets/images/adding.gif");

  const webAR1 = require("../../../assets/three/chair.glb");

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      750,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current ?? document.createElement("canvas"),
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);

    const loader = new GLTFLoader();
    loader.load(webAR1, (gltf) => {
      const model = gltf.scene;
      model.position.set(0, 0, 0);
      scene.add(model);

      const animate = () => {
        requestAnimationFrame(animate);
        model.rotation.y += 0.01;
        renderer.render(scene, camera);
      };

      animate();
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(1, 1, 5);
    controls.update();

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(2, 1, 5);
    scene.add(pointLight);
  });

  return (
    <div>
      <section className="bg-dark py-5rem">
        <div className="container pt-0 pt-sm-5">
          <div className="row pt-3 pb-2 py-md-4 align-items-center">
            <div className="col-12 col-sm-6 order-0 animate__animated animate__fadeInLeft">
              <PrimaryHeading
                primaryText="Revolutionize Your Real Estate Experience with AllesXR?
                "
                className="text-white align-items-start text-gradient-light"
              />
              <h2 className="text-white">
                Engaging Floor Plan Experiences{" "}
                <b className="text-danger">2D to 3D</b>
              </h2>

              <p className="mb-4 pb-4 text-white">
                AllesXR takes floor plans to the next level by transforming them
                into interactive experiences. Through AR, users can place
                virtual furniture, decor, and other design elements into a floor
                plan, giving them a realistic sense of how the space will look
                and feel.This helps buyers visualize their future home or office
                layout, making it easier for them to make informed decisions.
              </p>
              {/* <div className="d-flex justify-content-center justify-content-md-start">
                <a
                  className="btn btn-primary rounded-pill border-0 shadow-dark"
                  href="https://www.radiusmedia.de/christmas/index.html"
                  target="_blank"
                >
                  To View in AR
                </a>
              </div> */}
            </div>
            <div className="col-12 col-sm-6 order-1 pt-5 pt-sm-0 animate__animated animate__fadeInRight">
              <img
                src={convert3DImg}
                className="card-img card-img-bottom bg-size-cover bg-repeat-0 bg-position-center "
                alt="Home Interior"
              />
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="bg-gradiant1 py-5rem "
        style={{
          background: `url(${Img2})`,
        }}
      >
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 animate__animated animate__fadeInUp">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6  mt-5 mt-sm-0 order-1 order-sm-0">
                <img
                  src={Img1}
                  alt="Home Interior"
                  className="img-box-shadow"
                />
              </div>
              <div className="col-12 col-sm-6   order-0 order-sm-1 ps-0 ps-sm-5">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="">
                      <PrimaryHeading
                        primaryText="Experience Realistic Visualization experience with AllesXR!"
                        className="text-white align-items-start"
                      />
                      <p className="sub-text text-white">
                        Experience interactive 3D home tours and staging with
                        AllesXR, empowering buyers to customize interiors and
                        easily order furniture, curtains, and more using
                        cutting-edge AR/VR technology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </BackgroundImage>

      <section className="bg-light d-flex justify-content-center align-items-center flex-column">
        <PrimaryHeading
          primaryText="Explore Products Up-Close
          "
          className="py-5 text-black"
        />
        <CanvasContainer style={{ width: "50%" }}>
          <canvas ref={canvasRef} />
        </CanvasContainer>
      </section>

      <Section className="bg-white">
        <div className="container position-relative">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <PrimaryHeading primaryText="Reimagining Real Estate with AR/VR Technologies" />
              <p className="fs-5 mb-4 pb-4 ">
                Revolutionizing real estate with the power of AR/VR, enhancing
                property exploration, visualization, and decision-making for
                buyers and investors.
              </p>
            </div>
          </div>
          <div>
            <div className="row g-4 pb-2 pb-sm-4 mb-sm-2">
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Immersive Virtual <br />
                      <span className="text-warning"> Staging Experiences</span>
                    </h3>

                    <img
                      src={VirtualImg}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={VirtualImg}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Transform Property Showcasing with AllesXR's Virtual
                      Interior Staging. Create immersive virtual interiors for
                      unfinished properties through our AR/VR platform,
                      empowering buyers to make quicker decisions and streamline
                      the property buying process.
                    </p>
                  </div>
                </GridBox>
              </div>
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Unleashing <br />
                      <span className="text-warning">
                        Architectural Vision{" "}
                      </span>
                    </h3>

                    <img
                      src={PillarImg}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={PillarImg}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Revolutionize Property Marketing with AllesXR's AR/VR
                      Solutions. Showcase realistic 3D models with fully
                      developed interiors, enabling buyers to visualize the
                      final product before it's built, transforming the way real
                      estate is presented.
                    </p>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section className="bg-black">
        <div className="rs-about">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional augmented reality. our team is ready
                    to assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
