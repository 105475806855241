import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Lottie from "lottie-react";
import BusinnesIdeaImg from "../../assets/images/man-and-woman-getting-business-idea.png";

export default function AboutUs() {
  const ExpertARDeveloper = require("../../assets/lottie/graphic-design-student.json");
  const Solutions = require("../../assets/lottie/man-thinking-about-load.json");
  const Understanding = require("../../assets/lottie/understanding.json");
  const Technology = require("../../assets/lottie/Technology.json");
  const Partnership = require("../../assets/lottie/business-partnership-deal-successful.json");

  return (
    <div className="bg-white">
      <div className="container">
        <Section className="animate__animated animate__fadeIn">
          <div className="m-0 row">
            <Primaryheading
              primaryText="Why Choose AllesXR?"
              secondaryText=""
            />
            <p className="px-5">
              Choose AllesXR as your trusted partner in unlocking the full
              potential of augmented reality (AR) and virtual reality (VR).
              Together, let's unlock the true potential of AR and VR,
              transforming the way you engage, educate, and entertain. Contact
              us today to embark on an immersive journey with AllesXR, where
              innovation meets imagination.
            </p>
            <div className={"col-12 col-sm-4"}>
              <GridBox>
                <Lottie
                  animationData={ExpertARDeveloper}
                  loop={true}
                  style={{
                    height: 200,
                  }}
                />
                <h2>Expert AR/VR Development</h2>
                <p>
                  Our team of skilled developers and designers specializes in
                  creating immersive and interactive AR & VR applications.We
                  leverage the latest advancements in technology to deliver
                </p>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-4"}>
              <GridBox>
                <Lottie
                  animationData={Technology}
                  loop={true}
                  style={{
                    height: 200,
                  }}
                />
                <h2> Cutting-Edge Technology</h2>
                <p>
                  We stay at the forefront of AR and VR technology to provide
                  you with the most advanced and impactful solutions.From
                  augmented reality applications that overlay digital content
                  onto the real world
                </p>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-4"}>
              <GridBox>
                <Lottie
                  animationData={Solutions}
                  loop={true}
                  style={{
                    height: 200,
                  }}
                />
                <h2>Tailored Solutions</h2>
                <p>
                  We understand that each client is unique. That's why we take a
                  personalized approach to develop AR and VR solutions that
                  align with your specific goals and requirements.
                </p>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-4"}>
              <GridBox>
                <Lottie
                  animationData={Understanding}
                  loop={true}
                  style={{
                    height: 200,
                  }}
                />
                <h2>Industry Understanding</h2>
                <p>
                  With extensive experience across various sectors, including
                  real estate, manufacturing, e-commerce, education, and
                  healthcare, we have a deep understanding of industry-specific
                  challenges and opportunities.
                </p>
              </GridBox>
            </div>

            <div className={"col-12 col-sm-4"}>
              <GridBox>
                <Lottie
                  animationData={Partnership}
                  loop={true}
                  style={{
                    height: 200,
                  }}
                />
                <h2>Collaborative Partnership</h2>
                <p>
                  We place great value on fostering strong partnerships with our
                  clients. We work closely with you throughout the development
                  process, ensuring clear communication, regular updates, and
                  feedback-driven iterations.
                </p>
              </GridBox>
            </div>
          </div>
        </Section>

        <Section className="about-main-content ">
          <div className="m-0 row pt-5">
            <Primaryheading
              primaryText="Pioneering the Future of Interaction with Innovative Solutions"
              secondaryText=""
            />
          </div>
          <div className="m-0 row pt-5">
            <div className="col-12 col-sm-6">
              <p>
                We are a team of visionary innovators who are dedicated to
                transforming the way people interact with technology. Our
                passion for augmented reality (AR) and virtual reality (VR)
                fuels our drive to push the boundaries of what's possible.
              </p>
              <p>
                We prioritize creating seamless and immersive user experiences.
                Whether it's through stunning visuals, intuitive interactions,
                or lifelike simulations, we strive to transport users to new
                realms where imagination becomes reality.
              </p>
              <p>
                With experience across various industries, including real
                estate, manufacturing, e-commerce, education, and healthcare, we
                bring a deep understanding of your sector's unique challenges
                and opportunities. We'll collaborate closely with you to deliver
                solutions that drive tangible results.
              </p>
              <p>
                With experience across various industries, including real
                estate, manufacturing, e-commerce, education, and healthcare, we
                bring valuable insights and domain expertise to every project.
                We leverage this knowledge to develop industry-specific AR and
                VR solutions.{" "}
              </p>
              <p>
                We stay at the forefront of technology trends, continually
                exploring the latest advancements in AR and VR. From advanced
                tracking systems and motion capture to spatial audio and haptic
                feedback, we harness cutting-edge tools to create truly
                immersive experiences.
              </p>
              <p>
                Collaboration is at the heart of our process. We believe in
                working closely with our clients, involving you at every stage
                of development. We value your input, feedback, and insights,
                ensuring the end product aligns with your vision and meets your
                business objectives.
              </p>
            </div>
            <div className="col-12 col-sm-6">
              <img src={BusinnesIdeaImg} alt="" className="w-100" />
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
}
