import React, { FunctionComponent } from "react";
import { FooterSection, CopyrightBar } from "./styles";
import Map from "../../../assets/images/map.svg";
import Mail from "../../../assets/images/mail.svg";
import Phone from "../../../assets/images/phone.svg";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/allesxr-dark-logo.svg";

const Footer: FunctionComponent = () => {
  return (
    <>
      <FooterSection>
        <div className="container">
          <div className="m-0 row">
            <div className="col-12 col-sm-3">
              <img src={logoImg} alt="logo" className={"logo"} />
              <p className="pt-3">
                So why settle for the ordinary when you can experience the
                extraordinary with AllesXR? Join us today and discover a whole
                new world of possibilities.
              </p>
            </div>
            <div className="col-12 col-sm-3 ps-sm-5">
              <h5>Products & services</h5>
              <ul>
                <li>
                  <Link to="/AugmentedReality" className="dropdown-item">
                    Augmented Reality
                  </Link>
                </li>
                <li>
                  <Link to="/VirtualReality" className="dropdown-item">
                    Virtual Reality
                  </Link>
                </li>
                <li>
                  <Link to="/MixedReality" className="dropdown-item">
                    Mixed Reality
                  </Link>
                </li>
                <li>
                  <Link to="/WebAR" className="dropdown-item">
                    Web AR
                  </Link>
                </li>
                <li>
                  <Link to="/DigitalTwins" className="dropdown-item">
                    Digital Twins
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Industries</h5>
              <ul>
                <li>
                  <Link to="/" className="dropdown-item py-1">
                    Real Estate
                  </Link>
                </li>
                <li>
                  <Link to="/" className="dropdown-item py-1">
                    Manufacturing
                  </Link>
                </li>
                <li>
                  <Link to="/" className="dropdown-item py-1">
                    eCommerce
                  </Link>
                </li>
                <li>
                  <Link to="/" className="dropdown-item py-1">
                    Education
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Contact</h5>
              <ul>
                <li>
                  <img src={Map} className="pe-3" alt="" />
                  342 Saand Rd #33, Dallas, Tax 759, United States{" "}
                </li>
                <li>
                  {" "}
                  <img src={Mail} className="pe-3" alt="" />
                  info@allesxr.com
                </li>
                <li>
                  {" "}
                  <img src={Phone} className="pe-3" alt="" />
                  +1 000-012-1234
                </li>
              </ul>
            </div>
            {/* <div className="col-12 col-sm-2">
              <h5>Follow us</h5>
              <ul>
                <li>
                  <img src={FB} />
                </li>
                <li>
                  <img src={Instagram} />
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </FooterSection>

      <CopyrightBar>
        <p>
          AllesXR Copyright © 2020. All rights reserved. Developed by NEWDEVEX
          Solutions Pvt, Ltd,
        </p>
      </CopyrightBar>
    </>
  );
};

export default Footer;
