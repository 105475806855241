import styled from "styled-components";
import { flexCenter } from "../../styles";
import { fontSize_BASE, fontSize_XXL } from "../../customFonts";

export const StyledGrid = styled.div`
  padding: 18px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
`;

export const GridBox = styled.div`
  padding: 50px 36px;
  ${flexCenter};
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;
  /* height: 230px; */

  @media (max-width: 767px) {
    width: 100%;
  }

  h5,
  h2 {
    ${fontSize_BASE};
    line-height: 24px;
    text-align: center;
    font-weight: 600;
  }

  h2 {
    color: #000;
    text-align: left;
    ${fontSize_XXL};
    padding-bottom: 14px;
  }

  p {
    text-align: center;
  }

  b {
    ${fontSize_BASE};
    font-weight: 900;
  }

  img {
    padding-bottom: 26px;
    width: 30% !important;

    @media (max-width: 480px) {
      padding: 30px 0;
    }
  }

  h5 {
    font-weight: bold;
  }
`;
