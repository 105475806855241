import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer } from "../styles";
import MixedRealityImg from "../../../../assets/images/mixed-reality-mr.svg";
import PR2 from "../../../../assets/images/pr-2.jpeg";
import ProjectBG from "../../../../assets/images/project-bg-1.svg";

export default function TemplateOne() {
  const canvasRef = useRef(null);
  const webAR1 = require("../../../../assets/three/chair.glb");
  const ARVideo = require("../../../../assets/images/arvideo.gif");

  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState([
    {
      id: "1",
      size: "1400-933",
      src: PR2,
      thumb: PR2,
      subHtml: ``,
    },

    {
      id: "2",
      size: "1400-933",
      src: MixedRealityImg,
      thumb: MixedRealityImg,
      subHtml: ``,
    },
    {
      id: "3",
      size: "1400-932",
      src: PR2,
      thumb: PR2,
      subHtml: ``,
    },
    {
      id: "4",
      size: "1400-932",
      src: PR2,
      thumb: PR2,
      subHtml: ``,
    },
    {
      id: "5",
      size: "1400-932",
      src: PR2,
      thumb: PR2,
      subHtml: ``,
    },
    {
      id: "6",
      size: "1400-932",
      src: PR2,
      thumb: PR2,
      subHtml: ``,
    },
  ]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img className="img-responsive" src={item.thumb} />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
    <div className="d-flex vh-100 bg-white">
      <div className="col-3 d-none d-sm-block">
        <img
          src={ProjectBG}
          alt=""
          className="w-100 vh-100"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="col-12 col-sm-9 p-0 p-sm-5 overflow-auto mt-5 pt-5">
        <section className="bg-white">
          <div className="container">
            <div className="row">
              <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                <div className="col-12 d-flex justify-content-center">
                  <PrimaryHeading className="display-3 pb-2 pb-sm-3">
                    Robot simulation in VR & AR
                  </PrimaryHeading>
                </div>
                <div className="col-12 col-sm-9">
                  <p className="mb-0">
                    The project is designed to teach users how to work with a
                    robot using a 3D printed controller and Bluetooth module in
                    Virtual Reality environment. The project is built for the
                    HTC Vive platform and provides an immersive environment
                    where users can learn to interact with a robot manually.
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-5">
                  <img src={PR2} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <PrimaryHeading className="heading-hero text-gradient-primary  pb-2 mb-lg-2 pb-sm-2 pt-4">
                  Revolutionizing Medical Education with Immersive AR Training
                  Simulations
                </PrimaryHeading>

                <p className="py-2">
                  We are proud to have partnered with medical institutions to
                  develop immersive AR training simulations for medical
                  education. Our AR project simulates real-life medical
                  procedures and scenarios, allowing aspiring doctors and
                  healthcare professionals to practice and refine their skills
                  in a safe and controlled environment. Through realistic
                  visuals, interactive interfaces, and haptic feedback, our AR
                  simulations provide a hands-on learning experience that
                  bridges the gap between theory and practice, ultimately
                  improving patient care and safety.
                </p>
                <div className="d-sm-flex justify-content-between pb-3">
                  <Button>View in AR</Button>
                </div>
              </div>
            </div>

            <div className="row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <PrimaryHeading className="heading-hero text-gradient-primary  pb-2 mb-lg-2 pb-sm-2 pt-4">
                  Enter a New Dimension of Gaming: Augmented Reality Redefining
                  the Gaming Landscape
                </PrimaryHeading>

                <p className="py-2">
                  Immerse yourself in a world of thrilling gaming adventures
                  with our AR gaming project. By integrating virtual elements
                  into the real world, we create captivating and interactive
                  gaming experiences that blur the line between fantasy and
                  reality. Whether battling virtual creatures in your living
                  room, solving AR puzzles in outdoor environments, or
                  participating in multiplayer AR battles, our gaming project
                  offers a new level of excitement and immersion that redefines
                  the gaming landscape.
                </p>
                <div className="d-sm-flex justify-content-between pb-3">
                  <Button>View in AR</Button>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-5">
                  <img
                    src={ARVideo}
                    style={{
                      height: 300,
                      objectFit: "cover",
                      borderRadius: 12,
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container">
            <GalleryContainer>
              <LightGallery
                plugins={[lgVideo, lgZoom]}
                elementClassNames="gallery-custom-wrapper row flex-column flex-sm-row"
                onInit={onInit}
              >
                {getItems()}
              </LightGallery>
            </GalleryContainer>
          </div>
        </section>
      </div>
    </div>
  );
}
