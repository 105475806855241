import React from "react";
import Header from "./UIComponents/Header";
import Footer from "./UIComponents/Footer";
import Router from "./routes";

function AppContainer() {
  return (
    <>
      <div className={"main"}>
        <Header />
        <Router />
        <Footer />
      </div>
    </>
  );
}

export default AppContainer;
