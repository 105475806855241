import React, { useRef, useEffect, useState } from "react";
import ShowCaseBanner from "./ShowCaseBanner";
import { Section } from "../../styles";
import { PrimaryHeading } from "../../styles";
import { Tabs, NoProjectsContainer, ProjectGrid } from "./styles";
import { Link } from "react-router-dom";
import Chair1 from "../../../assets/images/chair1.png";
import PR1 from "../../../assets/images/pr-1.jpeg";
import PR2 from "../../../assets/images/pr-2.jpeg";

export default function ShowCase() {
  const [selectedTab, setSelectedTab] = useState("All Projects");

  const items = [
    {
      id: 1,
      title: "Robot simulation in VR & AR",
      src: PR1,
      linkTo: "/TemplateOne",
      category: "All Projects", // Add category for each item
      className: "",
    },
    {
      id: 2,
      title: "QC Assist",
      src: PR2,
      linkTo: "",
      category: "AR Development",
      className: "disabled",
    },
    {
      id: 3,
      title: "HOLOWORK",
      src: PR1,
      linkTo: "",
      category: "VR Development",
      className: "disabled",
    },
    {
      id: 4,
      src: PR1,
      linkTo: "",
      title: "ARGOHYTOS AR",
      category: "Web AR",
      className: "disabled",
    },
    {
      id: 5,
      src: PR1,
      linkTo: "",
      title: "RyllMesse",
      category: "Web AR",
      className: "disabled",
    },
    {
      id: 6,
      src: PR2,
      linkTo: "",
      title: "Pilkington AR",
      category: "Web AR",
      className: "disabled",
    },
  ];

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const filteredItems =
    selectedTab === "All Projects"
      ? items
      : items.filter((item) => item.category === selectedTab);

  return (
    <main className="page-wrapper">
      <Section className="bg-white">
        <div className="container position-relative">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <PrimaryHeading className="section-heading display-1 cta-colorful-text1 d-dark-mode-block pb-2 mb-lg-2 pb-sm-2">
                Our Works & Portfolio
              </PrimaryHeading>
              <p className="fs-5 mb-4 pb-4 ">
                No matter your industry, 3D Product Configurators can help you
                demonstrate why your products are the best!
              </p>
              <Tabs>
                <ul>
                  <li
                    onClick={() => handleTabClick("All Projects")}
                    className={selectedTab === "All Projects" ? "active" : ""}
                  >
                    All Projects
                  </li>
                  <li
                    onClick={() => handleTabClick("AR Development")}
                    className={selectedTab === "AR Development" ? "active" : ""}
                  >
                    AR Development
                  </li>
                  <li
                    onClick={() => handleTabClick("VR Development")}
                    className={selectedTab === "VR Development" ? "active" : ""}
                  >
                    VR Development
                  </li>
                  <li
                    onClick={() => handleTabClick("Web AR")}
                    className={selectedTab === "Web AR" ? "active" : ""}
                  >
                    Web AR
                  </li>
                  <li
                    onClick={() => handleTabClick("3D Modelling")}
                    className={selectedTab === "3D Modelling" ? "active" : ""}
                  >
                    3D Modelling
                  </li>
                </ul>
              </Tabs>
            </div>
          </div>

          {filteredItems.length > 0 ? (
            <div className="row g-4 mt-5">
              {filteredItems.map((item, index) => (
                <div className="col-12 col-sm-4">
                  <ProjectGrid>
                    <div className={item.className}>
                      <img
                        src={item.src}
                        alt="Home Interior"
                        className="w-100 pb-3"
                      />
                      <div className="d-flex align-items-center justify-content-between flex-column">
                        <Link to={item.linkTo}>
                          <p>{item.title}</p>
                        </Link>
                      </div>
                    </div>
                  </ProjectGrid>
                </div>
              ))}
            </div>
          ) : (
            <NoProjectsContainer>
              No projects found for the selected category.
            </NoProjectsContainer>
          )}
        </div>
      </Section>

      <section
        className="bg-dark dark-mode mt-n1 mt-md-0 py-5 bg-size-cover bg-repeat-1 bg-position-bottom position-relative overflow-hidden py-5 border-1"
        style={{
          backgroundImage:
            "url(/frontend3/assets/cm-img/backgrounds/bg-blur-p-1080.png)",
        }}
      >
        <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
          <div className="position-relative bg-dark rounded-3 overflow-hidden px-5 mb-3 aos-init aos-animate">
            <div className="row align-items-center pt-5">
              <div className="col-12 mb-2">
                <PrimaryHeading className="section-heading display-1 cta-colorful-text1 d-dark-mode-block pb-2 mb-lg-2 pb-sm-2">
                  Get Your Products in Customers' Homes Faster
                </PrimaryHeading>
              </div>
              <div className="col-12">
                <p className="fs-4 mb-4 pb-4 pt-2">
                  Harnessing the capabilities of AllesXR's Augmented Reality
                  platform, you can offer your customers an unparalleled
                  opportunity to "experience-before-they-purchase." This
                  remarkable feature allows them to dive into a virtual world
                  where they can interact with your products, ensuring complete
                  confidence in their buying decisions. Best of all, this
                  invaluable experience comes at no additional cost to you or
                  your customers.
                </p>
              </div>
            </div>
            <div
              className="position-absolute top-0 start-0 w-100 h-100 shadow-3 shadow-primary rounded-5 pt-5"
              style={{ backgroundColor: "rgba(255,255,255, .09)" }}
            />
            <div
              className="dark-mode bg-dark bg-size-cover bg-position-center bg-repeat-0 position-relative overflow-hidden rounded-5 mb-5"
              style={{
                background:
                  "linear-gradient(-270deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)",
              }}
            >
              <div className="row position-relative zindex-2 py-5 my-1 my-md-3">
                <div className="col-md-6">
                  <div className="mx-auto" style={{ maxWidth: "440px" }}>
                    <h1 className="section-heading display-5 pb-2 mb-lg-3 pb-sm-2 text-dark">
                      Ideal choices for your living space
                    </h1>

                    <h3 className="fs-4 pb-md-3 pb-lg-4">
                      With our innovative solutions, you can confidently explore
                      and decide on the ideal choices for your living space, all
                      from the convenience of your own home.
                    </h3>

                    <a
                      href="/"
                      className="btn btn-primary rounded-5 border-0 shadow-primary d-none d-lg-inline-flex"
                    >
                      AR View
                    </a>
                  </div>
                </div>
                <div className="col-md-6 pt-lg-3 pt-xl-4">
                  <img
                    src={Chair1}
                    alt="Home Interior"
                    className="w-100 w-sm-10 py-5"
                    style={{ height: 350, objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
