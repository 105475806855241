import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";
import P1 from "../../../assets/images/digital-twin.svg";

export default function DigitalTwins() {
  const Lottie1 = require("../../../assets/lottie/website-layout.json");

  return (
    <div>
      <Section className="bg-black">
        <div className="rs-about py-0">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-6 pb-5 pb-sm-0">
                <div className="images-part">
                  <img src={P1} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 ps-0 ps-sm-5">
                <div className="sec-title text-white">
                  <h1 className="text-white">
                    Why Choose Us for DigitalTwins?
                  </h1>
                  <p className="margin-0 pt-15">
                    By analyzing data from digital twins, we can identify areas
                    of improvement and optimize performance to improve
                    efficiency and reduce costs.
                  </p>
                  <p className="margin-0 pt-15">
                    Digital twins allow us to monitor real-world objects and
                    systems remotely, enabling predictive maintenance and
                    identifying potential issues before they become major
                    problems.
                  </p>
                  <p className="margin-0 pt-15">
                    Digital twins can be used to simulate different scenarios
                    and test changes or optimizations before implementing them
                    in the real world.
                  </p>
                  <p className="margin-0 pt-15">
                    AllesXR can help create digital twins of manufacturing
                    processes and systems, allowing for optimization and cost
                    reduction.
                  </p>
                  <p className="margin-0 pt-15">
                    Digital twins of infrastructure, such as buildings, bridges,
                    and roads, can be used to monitor and predict maintenance
                    needs, ensuring safety and longevity.
                  </p>
                  <p className="margin-0 pt-15">
                    AllesXR can create digital twins of human anatomy and
                    medical procedures, enabling better planning and
                    optimization of treatments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="Digital Twins"
                        className="text-white"
                      />
                      <span className="sub-text text-white">
                        A digital twin is a virtual representation of a
                        real-world object, process, or system. By creating a
                        digital twin, we can analyze and optimize its
                        performance, identify potential problems, and simulate
                        different scenarios to improve its functionality and
                        efficiency.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight mt-5 mt-sm-0">
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{
                    height: 350,
                  }}
                />
              </div>
            </div>
          </Section>
        </div>
      </section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="DigitalTwins Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Digital Twin <br />
                          Creation
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Our team of experts can create digital twins of
                        real-world objects, processes, or systems, using the
                        latest technology and techniques.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Data Analysis and <br />
                          Optimization
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        AllesXR can analyze data from digital twins to identify
                        areas of improvement and optimize performance and
                        efficiency.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Simulation and <br /> Testing
                        </a>
                      </h5>
                      <p className="services-txt">
                        We can use digital twins to simulate different scenarios
                        and test changes or optimizations before implementing
                        them in the real world.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText=" Immersive Simulations for Real-World Insights" />
              <p className="mt-5">
                Our Digital Twins solutions enable you to create immersive
                simulations that provide real-world insights and analytics. By
                modeling physical assets, processes, or systems in a virtual
                environment, you gain valuable data and intelligence that can
                inform decision-making, predict performance, and identify areas
                for optimization. Whether you're in manufacturing, construction,
                or smart city planning, Digital Twins offer a transformative
                approach to problem-solving.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText=" Embrace the Future with Digital Twins" />
              <p className="mt-5">
                Step into the future of design, development, and operations with
                AllesXR's Digital Twins solutions. Our team of experts will work
                closely with you to understand your specific requirements and
                develop custom-tailored Digital Twins that revolutionize your
                industry. Harness the power of virtual replicas, gain actionable
                insights, and unlock new possibilities for your business.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
