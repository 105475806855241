import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";
import MixedRealityImg from "../../../assets/images/digital-twin.svg";

export default function MixedReality() {
  const Lottie1 = require("../../../assets/lottie/mr.json");

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-8 animate__animated animate__fadeInLeft">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="Mixed Reality"
                        className="text-white"
                      />
                      <span className="sub-text text-white">
                        Mixed Reality (MR) is a blend of both Virtual Reality
                        (VR) and Augmented Reality (AR) technologies. With mixed
                        reality, you can experience virtual and real-world
                        elements together in a seamless environment. It offers a
                        whole new level of interactivity and immersion that can
                        enhance various aspects of our lives. AllesXR can help
                        you leverage the power of mixed reality to create custom
                        solutions for your business or personal needs.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-3 animate__animated animate__fadeInRight mt-5 mt-sm-0 d-flex justify-content-center">
                <img src={MixedRealityImg} alt="" className="w-50 w-sm-100" />
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-white">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-3  pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <Lottie
                    animationData={Lottie1}
                    loop={true}
                    style={{
                      height: 350,
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-8  ps-0 ps-sm-5 ">
                <div className="sec-title">
                  <h1>Why Choose Us for Mixed Reality?</h1>
                  <p className="margin-0 pt-15">
                    AllesXR can help create interactive mixed reality training
                    experiences that simulate real-world scenarios, allowing
                    learners to practice and refine their skills in a safe
                    environment.
                  </p>
                  <p className="margin-0 pt-15">
                    AllesXR can create mixed reality campaigns that immerse
                    customers in your products and services, increasing
                    engagement and customer loyalty.
                  </p>
                  <p className="margin-0 pt-15">
                    AllesXR can provide mixed reality solutions for healthcare
                    that allow medical professionals to visualize patient data
                    and medical images in a 3D environment, helping them make
                    more informed decisions about patient care.
                  </p>
                  <p className="margin-0 pt-15">
                    AllesXR can create immersive and interactive mixed reality
                    entertainment experiences that engage your audience in new
                    and exciting ways.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        ={" "}
      </Section>

      <Section className="bg-black">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional Mixed reality. our team is ready to
                    assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Mixed Reality Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Custom Mixed Reality <br />
                          Solutions
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Our team of experts can work with you to create
                        customized mixed reality solutions that meet your
                        specific business or personal needs.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Mixed Reality Hardware <br /> and Software
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We offer a range of mixed reality hardware and software
                        solutions, including head-mounted displays, sensors, and
                        development tools.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Consulting and <br /> Support
                        </a>
                      </h5>
                      <p className="services-txt">
                        We provide consulting and support services to help you
                        navigate the complex world of mixed reality and ensure
                        that your projects are successful.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Step into the future with AllesXR" />
              <p className="mt-5">
                Embark on an unforgettable journey into the world of Mixed
                Reality with AllesXR. Our commitment to innovation and
                cutting-edge technology ensures that you experience the full
                potential of MR. Immerse yourself in interactive environments,
                interact with digital objects, and collaborate in ways never
                thought possible. Embrace the future of reality and explore the
                endless possibilities of Mixed Reality with AllesXR.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText=" Endless applications for various industries" />
              <p className="mt-5">
                Mixed Reality is transforming industries across the board. From
                architecture and design to manufacturing, healthcare, and
                beyond, the applications are limitless. AllesXR's experienced
                team of developers and designers leverages the potential of
                Mixed Reality to create immersive and practical solutions
                tailored to specific industries. Discover how MR can
                revolutionize your workflows, enhance training and simulations,
                and bring your creative visions to life.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
