import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";
import I18 from "../../../assets/images/18.svg";

export default function VirtualReality() {
  const AdventureImg = require("../../../assets/images/male-experiencing-free-fall-in-virtual-reality.png");
  const Lottie1 = require("../../../assets/lottie/2.json");

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="breadcrumbs-inner">
                  <PrimaryHeading
                    primaryText=" Virtual Reality"
                    className="text-white"
                  />
                  <span className="sub-text text-white">
                    Immerse yourself in a new realm of possibilities with
                    AllesXR's cutting-edge Virtual Reality (VR) experiences.
                    Harnessing the power of advanced technologies, we bring you
                    to the forefront of immersive digital experiences that blur
                    the line between the real and the virtual.{" "}
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight mt-5 mt-sm-0">
                {" "}
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{
                    height: 400,
                  }}
                />
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-white">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-lg-4 pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <img src={I18} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-lg-8 ps-0 ps-sm-5 md-pl-15">
                <div className="sec-title">
                  <h1>Why Choose Us for Virtual Reality?</h1>
                  <p className="margin-0 pt-15">
                    Experience a world beyond your imagination with AllesXR's
                    virtual reality services. Our cutting-edge technology allows
                    you to step into a fully immersive and interactive digital
                    environment, where you can explore, create, and learn like
                    never before.
                  </p>
                  <p className="margin-0 pt-15">
                    Whether you're looking to showcase your products or services
                    in a unique way, train employees in a realistic and safe
                    virtual environment, or create an unforgettable
                    entertainment experience, AllesXR has the virtual reality
                    solutions you need.
                  </p>
                  <p className="margin-0 pt-15">
                    Our team of experienced developers and engineers are
                    passionate about pushing the boundaries of what's possible
                    with virtual reality technology. We work closely with our
                    clients to create customized solutions that meet their
                    specific needs and goals.
                  </p>
                  <p className="margin-0 pt-15">
                    So why wait? Step into a world of endless possibilities with
                    AllesXR's virtual reality services today."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-black">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading
                primaryText=" Step into a new dimension"
                className="text-white"
              />
              <p className="mt-5 text-white">
                With our VR solutions, you can step into a virtual world where
                you become an active participant. Our state-of-the-art headsets
                and controllers transport you to breathtaking environments and
                allow you to interact with objects and characters like never
                before. Experience the thrill of exploring ancient ruins,
                traveling through space, or battling mythical creatures, all
                from the comfort of your own home.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-lg-8 ps-0 ps-sm-5 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Start your VR adventure today" />
                </div>
                <div className="sec-title">
                  <p className="mt-5">
                    <b>
                      Don't miss out on the transformative power of Virtual
                      Reality. Discover a new world of entertainment, education,
                      and exploration with AllesXR's exceptional VR experiences.
                    </b>
                  </p>
                  <p className="mt-5">
                    Step into the extraordinary and let your senses come alive
                    in the virtual reality. Begin your VR adventure today and
                    redefine your reality.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <img
                    src={AdventureImg}
                    alt="Images"
                    className="w-100 rotate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* 
      <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Virtual Reality Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Product <br />
                          Showcases
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Virtual reality technology is a powerful tool for
                        showcasing your products and services in a way that is
                        engaging and interactive. AllesXR can help you create
                        customized product showcases that allow your customers
                        to explore and interact with your products in a virtual
                        environment.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Educational <br /> Applications
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Virtual reality technology offers incredible
                        opportunities for educators looking to engage their
                        students in new and exciting ways. AllesXR can help you
                        create customized solutions that bring your curriculum
                        to life and make learning fun and interactive.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>

              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Healthcare <br /> Simulations
                        </a>
                      </h5>
                      <p className="services-txt">
                        Virtual reality technology can be used to create
                        realistic and safe simulations for healthcare training
                        and education. AllesXR can help you create customized
                        virtual reality healthcare simulations that allow
                        healthcare professionals to train in realistic scenarios
                        without any risks.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
