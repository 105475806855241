import { css } from "styled-components";

export const CustomStyles = css`
  .text-gradient-primary {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#146ad3),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #146ad3, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  :root {
    --si-blue: #0d6efd;
    --si-pink: #d63384;
    --si-red: #dc3545;
    --si-orange: #fd7e14;
    --si-yellow: #ffc107;
    --si-green: #198754;
    --si-teal: #20c997;
    --si-cyan: #0dcaf0;
    --si-black: #0b0f19;
    --si-white: #ffffff;
    --si-gray: #858c97;
    --si-gray-dark: #252a44;
    --si-gray-100: #f3f4f5;
    --si-gray-200: #edf1f5;
    --si-gray-300: #f5f5f7;
    --si-gray-400: #d7dde2;
    --si-gray-500: #b4bbc3;
    --si-gray-600: #858c97;
    --si-gray-700: #576071;
    --si-gray-800: #252a44;
    --si-gray-900: #000000;
    --si-primary: #146ad3;
    --si-secondary: #f5f5f7;
    --si-success: #17f1d2;
    --si-info: #a1a1a6;
    --si-warning: #ffd073;
    --si-danger: #ff4f00;
    --si-light: #f3f4f5;
    --si-dark: #000000;
    --si-primary-rgb: 105, 54, 245;
    --si-secondary-rgb: 245, 245, 247;
    --si-success-rgb: 23, 241, 210;
    --si-info-rgb: 161, 161, 166;
    --si-warning-rgb: 255, 208, 115;
    --si-danger-rgb: 255, 79, 0;
    --si-light-rgb: 243, 244, 245;
    --si-dark-rgb: 18, 21, 25;
    --si-white-rgb: 255, 255, 255;
    --si-black-rgb: 11, 15, 25;
    --si-body-color-rgb: 67, 74, 87;
    --si-body-bg-rgb: 243, 244, 245;
    --si-font-sans-serif: "Archivo", ui-sans-serif, system-ui, -apple-system,
      sans-serif;
    --si-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    --si-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    --si-body-font-size: 0.92rem;
    --si-body-font-weight: 400;
    --si-body-line-height: 1.5;
    --si-body-color: #252a44;
    --si-body-bg: #f3f4f5;
    --si-border-width: 1px;
    --si-border-style: solid;
    --si-border-color: #f5f5f7;
    --si-border-color-translucent: rgba(11, 15, 25, 0.175);
    --si-border-radius: 0.375rem;
    --si-border-radius-sm: 0.25rem;
    --si-border-radius-lg: 0.5rem;
    --si-border-radius-xl: 1rem;
    --si-border-radius-2xl: 2rem;
    --si-border-radius-pill: 50rem;
    --si-link-color: #146ad3;
    --si-link-hover-color: #3c0ac5;
    --si-code-color: #e3116c;
    --si-highlight-bg: #fff3cd;
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-size: var(--si-body-font-size);
    font-weight: var(--si-body-font-weight);
    line-height: var(--si-body-line-height);
    color: var(--si-body-color);
    text-align: var(--si-body-text-align);
    background-color: var(--si-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(11, 15, 25, 0);
  }

  hr {
    margin: 0 0;
    color: var(--si-border-color);
    border: 0;
    border-top: 1px solid;
    opacity: 1;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.25;
    color: var(--si-gray-900);
  }

  .h1,
  h1 {
    font-size: calc(1.355rem + 1.26vw);
  }

  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 2.3rem;
    }
  }

  .h2,
  h2 {
    font-size: calc(1.309rem + 0.708vw);
  }

  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 1.84rem;
    }
  }

  .h3,
  h3 {
    font-size: calc(1.286rem + 0.432vw);
  }

  @media (min-width: 1200px) {
    .h3,
    h3 {
      font-size: 1.61rem;
    }
  }

  .h4,
  h4 {
    font-size: calc(1.263rem + 0.156vw);
  }

  @media (min-width: 1200px) {
    .h4,
    h4 {
      font-size: 1.38rem;
    }
  }

  .h5,
  h5 {
    font-size: 1.15rem;
  }

  .h6,
  h6 {
    font-size: 0.92rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  dt {
    font-weight: 800;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  .small,
  small {
    font-size: 0.875em;
  }

  .mark,
  mark {
    padding: 0.1875em;
    background-color: var(--si-highlight-bg);
  }

  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    color: var(--si-link-color);
    text-decoration: underline;
  }

  a:hover {
    color: var(--si-link-hover-color);
    text-decoration: none;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
  }

  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
    color: #252a44;
  }

  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }

  code {
    font-size: 0.875em;
    color: var(--si-code-color);
    word-wrap: break-word;
  }

  a > code {
    color: inherit;
  }

  kbd {
    padding: 0.1875rem 0.375rem;
    font-size: 0.875em;
    color: #ffffff;
    background-color: #000000;
    border-radius: 0.25rem;
  }

  kbd kbd {
    padding: 0;
    font-size: 1em;
  }

  figure {
    margin: 0 0 1rem;
  }

  img,
  svg {
    vertical-align: middle;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: rgba(67, 74, 87, 0.5);
    text-align: left;
  }

  th {
    font-weight: 600;
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  label {
    display: inline-block;
  }

  button {
    border-radius: 0;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  select {
    word-wrap: normal;
  }

  select:disabled {
    opacity: 1;
  }

  [list]:not([type="date"]):not([type="datetime-local"]):not(
      [type="month"]
    ):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  textarea {
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }

  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }

  legend + * {
    clear: left;
  }

  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden] {
    display: none !important;
  }

  .lead {
    font-size: 1.012rem;
    font-weight: 400;
  }

  .display-1 {
    font-size: calc(1.585rem + 4.02vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-1 {
      font-size: 3.4rem;
    }
  }

  .display-2 {
    font-size: calc(1.5114rem + 3.1368vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-2 {
      font-size: 3.864rem;
    }
  }

  .display-3 {
    font-size: calc(1.4746rem + 2.6952vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-3 {
      font-size: 3.496rem;
    }
  }

  .display-4 {
    font-size: calc(1.447rem + 2.364vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.22rem;
    }
  }

  .display-5 {
    font-size: calc(1.401rem + 1.812vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-5 {
      font-size: 2.76rem;
    }
  }

  .display-6 {
    font-size: calc(1.3665rem + 1.398vw);
    font-weight: 600;
    line-height: 1.25;
  }

  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.415rem;
    }
  }

  .custom-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .list-inline {
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 1rem;
  }

  .initialism {
    font-size: 0.875em;
    text-transform: uppercase;
  }

  .blockquote {
    margin-bottom: 1rem;
    font-size: calc(1.263rem + 0.156vw);
  }

  @media (min-width: 1200px) {
    .blockquote {
      font-size: 1.38rem;
    }
  }

  .blockquote > :last-child {
    margin-bottom: 0;
  }

  .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.805rem;
    color: var(--si-gray-600);
  }

  .blockquote-footer:before {
    content: "— ";
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .img-thumbnail {
    padding: 0.5rem;
    background-color: var(--si-body-bg);
    border: 0 solid var(--si-border-color);
    border-radius: var(--si-border-radius-lg);
    -webkit-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    max-width: 100%;
    height: auto;
  }

  .figure {
    display: inline-block;
  }

  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  .figure-caption {
    font-size: 0.805rem;
    color: var(--si-gray-600);
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    --si-gutter-x: 1.5rem;
    --si-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--si-gutter-x) * 0.5);
    padding-left: calc(var(--si-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 500px) {
    .container,
    .container-sm {
      max-width: 95%;
    }
  }

  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 95%;
    }
  }

  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 95%;
    }
  }

  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 95%;
    }
  }

  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1320px;
    }
  }

  .row {
    --si-gutter-x: 1.5rem;
    --si-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--si-gutter-y));
    margin-right: calc(-0.5 * var(--si-gutter-x));
    margin-left: calc(-0.5 * var(--si-gutter-x));
  }

  .row > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--si-gutter-x) * 0.5);
    padding-left: calc(var(--si-gutter-x) * 0.5);
    margin-top: var(--si-gutter-y);
  }

  .col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  @media (min-width: 500px) {
    .col-sm {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }

    .row-cols-sm-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-sm-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-sm-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-sm-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-sm-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-sm-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-sm-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }

    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .offset-sm-0 {
      margin-left: 0;
    }

    .offset-sm-1 {
      margin-left: 8.33333333%;
    }

    .offset-sm-2 {
      margin-left: 16.66666667%;
    }

    .offset-sm-3 {
      margin-left: 25%;
    }

    .offset-sm-4 {
      margin-left: 33.33333333%;
    }

    .offset-sm-5 {
      margin-left: 41.66666667%;
    }

    .offset-sm-6 {
      margin-left: 50%;
    }

    .offset-sm-7 {
      margin-left: 58.33333333%;
    }

    .offset-sm-8 {
      margin-left: 66.66666667%;
    }

    .offset-sm-9 {
      margin-left: 75%;
    }

    .offset-sm-10 {
      margin-left: 83.33333333%;
    }

    .offset-sm-11 {
      margin-left: 91.66666667%;
    }

    .g-sm-0,
    .gx-sm-0 {
      --si-gutter-x: 0;
    }

    .g-sm-0,
    .gy-sm-0 {
      --si-gutter-y: 0;
    }

    .g-sm-1,
    .gx-sm-1 {
      --si-gutter-x: 0.25rem;
    }

    .g-sm-1,
    .gy-sm-1 {
      --si-gutter-y: 0.25rem;
    }

    .g-sm-2,
    .gx-sm-2 {
      --si-gutter-x: 0.5rem;
    }

    .g-sm-2,
    .gy-sm-2 {
      --si-gutter-y: 0.5rem;
    }

    .g-sm-3,
    .gx-sm-3 {
      --si-gutter-x: 1rem;
    }

    .g-sm-3,
    .gy-sm-3 {
      --si-gutter-y: 1rem;
    }

    .g-sm-4,
    .gx-sm-4 {
      --si-gutter-x: 1.5rem;
    }

    .g-sm-4,
    .gy-sm-4 {
      --si-gutter-y: 1.5rem;
    }

    .g-sm-5,
    .gx-sm-5 {
      --si-gutter-x: 3rem;
    }

    .g-sm-5,
    .gy-sm-5 {
      --si-gutter-y: 3rem;
    }
  }

  @media (min-width: 768px) {
    .col-md {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }

    .row-cols-md-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-md-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-md-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-md-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-md-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-md-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-md-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }

    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .offset-md-0 {
      margin-left: 0;
    }

    .offset-md-1 {
      margin-left: 8.33333333%;
    }

    .offset-md-2 {
      margin-left: 16.66666667%;
    }

    .offset-md-3 {
      margin-left: 25%;
    }

    .offset-md-4 {
      margin-left: 33.33333333%;
    }

    .offset-md-5 {
      margin-left: 41.66666667%;
    }

    .offset-md-6 {
      margin-left: 50%;
    }

    .offset-md-7 {
      margin-left: 58.33333333%;
    }

    .offset-md-8 {
      margin-left: 66.66666667%;
    }

    .offset-md-9 {
      margin-left: 75%;
    }

    .offset-md-10 {
      margin-left: 83.33333333%;
    }

    .offset-md-11 {
      margin-left: 91.66666667%;
    }

    .g-md-0,
    .gx-md-0 {
      --si-gutter-x: 0;
    }

    .g-md-0,
    .gy-md-0 {
      --si-gutter-y: 0;
    }

    .g-md-1,
    .gx-md-1 {
      --si-gutter-x: 0.25rem;
    }

    .g-md-1,
    .gy-md-1 {
      --si-gutter-y: 0.25rem;
    }

    .g-md-2,
    .gx-md-2 {
      --si-gutter-x: 0.5rem;
    }

    .g-md-2,
    .gy-md-2 {
      --si-gutter-y: 0.5rem;
    }

    .g-md-3,
    .gx-md-3 {
      --si-gutter-x: 1rem;
    }

    .g-md-3,
    .gy-md-3 {
      --si-gutter-y: 1rem;
    }

    .g-md-4,
    .gx-md-4 {
      --si-gutter-x: 1.5rem;
    }

    .g-md-4,
    .gy-md-4 {
      --si-gutter-y: 1.5rem;
    }

    .g-md-5,
    .gx-md-5 {
      --si-gutter-x: 3rem;
    }

    .g-md-5,
    .gy-md-5 {
      --si-gutter-y: 3rem;
    }
  }

  @media (min-width: 992px) {
    .col-lg {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }

    .row-cols-lg-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-lg-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-lg-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-lg-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-lg-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-lg-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-lg-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }

    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .offset-lg-0 {
      margin-left: 0;
    }

    .offset-lg-1 {
      margin-left: 8.33333333%;
    }

    .offset-lg-2 {
      margin-left: 16.66666667%;
    }

    .offset-lg-3 {
      margin-left: 25%;
    }

    .offset-lg-4 {
      margin-left: 33.33333333%;
    }

    .offset-lg-5 {
      margin-left: 41.66666667%;
    }

    .offset-lg-6 {
      margin-left: 50%;
    }

    .offset-lg-7 {
      margin-left: 58.33333333%;
    }

    .offset-lg-8 {
      margin-left: 66.66666667%;
    }

    .offset-lg-9 {
      margin-left: 75%;
    }

    .offset-lg-10 {
      margin-left: 83.33333333%;
    }

    .offset-lg-11 {
      margin-left: 91.66666667%;
    }

    .g-lg-0,
    .gx-lg-0 {
      --si-gutter-x: 0;
    }

    .g-lg-0,
    .gy-lg-0 {
      --si-gutter-y: 0;
    }

    .g-lg-1,
    .gx-lg-1 {
      --si-gutter-x: 0.25rem;
    }

    .g-lg-1,
    .gy-lg-1 {
      --si-gutter-y: 0.25rem;
    }

    .g-lg-2,
    .gx-lg-2 {
      --si-gutter-x: 0.5rem;
    }

    .g-lg-2,
    .gy-lg-2 {
      --si-gutter-y: 0.5rem;
    }

    .g-lg-3,
    .gx-lg-3 {
      --si-gutter-x: 1rem;
    }

    .g-lg-3,
    .gy-lg-3 {
      --si-gutter-y: 1rem;
    }

    .g-lg-4,
    .gx-lg-4 {
      --si-gutter-x: 1.5rem;
    }

    .g-lg-4,
    .gy-lg-4 {
      --si-gutter-y: 1.5rem;
    }

    .g-lg-5,
    .gx-lg-5 {
      --si-gutter-x: 3rem;
    }

    .g-lg-5,
    .gy-lg-5 {
      --si-gutter-y: 3rem;
    }
  }

  @media (min-width: 1200px) {
    .col-xl {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }

    .row-cols-xl-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-xl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-xl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-xl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-xl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-xl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-xl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }

    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }

    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }

    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }

    .offset-xl-0 {
      margin-left: 0;
    }

    .offset-xl-1 {
      margin-left: 8.33333333%;
    }

    .offset-xl-2 {
      margin-left: 16.66666667%;
    }

    .offset-xl-3 {
      margin-left: 25%;
    }

    .offset-xl-4 {
      margin-left: 33.33333333%;
    }

    .offset-xl-5 {
      margin-left: 41.66666667%;
    }

    .offset-xl-6 {
      margin-left: 50%;
    }

    .offset-xl-7 {
      margin-left: 58.33333333%;
    }

    .offset-xl-8 {
      margin-left: 66.66666667%;
    }

    .offset-xl-9 {
      margin-left: 75%;
    }

    .offset-xl-10 {
      margin-left: 83.33333333%;
    }

    .offset-xl-11 {
      margin-left: 91.66666667%;
    }

    .g-xl-0,
    .gx-xl-0 {
      --si-gutter-x: 0;
    }

    .g-xl-0,
    .gy-xl-0 {
      --si-gutter-y: 0;
    }

    .g-xl-1,
    .gx-xl-1 {
      --si-gutter-x: 0.25rem;
    }

    .g-xl-1,
    .gy-xl-1 {
      --si-gutter-y: 0.25rem;
    }

    .g-xl-2,
    .gx-xl-2 {
      --si-gutter-x: 0.5rem;
    }

    .g-xl-2,
    .gy-xl-2 {
      --si-gutter-y: 0.5rem;
    }

    .g-xl-3,
    .gx-xl-3 {
      --si-gutter-x: 1rem;
    }

    .g-xl-3,
    .gy-xl-3 {
      --si-gutter-y: 1rem;
    }

    .g-xl-4,
    .gx-xl-4 {
      --si-gutter-x: 1.5rem;
    }

    .g-xl-4,
    .gy-xl-4 {
      --si-gutter-y: 1.5rem;
    }

    .g-xl-5,
    .gx-xl-5 {
      --si-gutter-x: 3rem;
    }

    .g-xl-5,
    .gy-xl-5 {
      --si-gutter-y: 3rem;
    }
  }

  .table {
    --si-table-color: var(--si-body-color);
    --si-table-bg: transparent;
    --si-table-border-color: var(--si-border-color);
    --si-table-accent-bg: transparent;
    --si-table-striped-color: var(--si-body-color);
    --si-table-striped-bg: rgba(11, 15, 25, 0.0375);
    --si-table-active-color: var(--si-body-color);
    --si-table-active-bg: rgba(11, 15, 25, 0.05);
    --si-table-hover-color: var(--si-body-color);
    --si-table-hover-bg: rgba(11, 15, 25, 0.05);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--si-table-color);
    vertical-align: top;
    border-color: var(--si-table-border-color);
  }

  .table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    background-color: var(--si-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--si-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--si-table-accent-bg);
  }

  .table > tbody {
    vertical-align: inherit;
  }

  .table > thead {
    vertical-align: bottom;
  }

  .table-group-divider {
    border-top: 2px solid currentcolor;
  }

  .caption-top {
    caption-side: top;
  }

  .table-sm > :not(caption) > * > * {
    padding: 0.375rem 0.375rem;
  }

  .table-bordered > :not(caption) > * {
    border-width: 1px 0;
  }

  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
  }

  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  .table-borderless > :not(:first-child) {
    border-top-width: 0;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --si-table-accent-bg: var(--si-table-striped-bg);
    color: var(--si-table-striped-color);
  }

  .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
    --si-table-accent-bg: var(--si-table-striped-bg);
    color: var(--si-table-striped-color);
  }

  .table-active {
    --si-table-accent-bg: var(--si-table-active-bg);
    color: var(--si-table-active-color);
  }

  .table-hover > tbody > tr:hover > * {
    --si-table-accent-bg: var(--si-table-hover-bg);
    color: var(--si-table-hover-color);
  }

  .table-dark {
    --si-table-color: #ffffff;
    --si-table-bg: #000000;
    --si-table-border-color: #36383c;
    --si-table-striped-bg: #1b1e22;
    --si-table-striped-color: #ffffff;
    --si-table-active-bg: #1e2125;
    --si-table-active-color: #ffffff;
    --si-table-hover-bg: #1e2125;
    --si-table-hover-color: #ffffff;
    color: var(--si-table-color);
    border-color: var(--si-table-border-color);
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media (max-width: 499.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .form-label {
    margin-bottom: 0.3125rem;
    font-size: 0.805rem;
    font-weight: 500;
    color: #000000;
  }

  .col-form-label {
    padding-top: calc(0.625rem + 1px);
    padding-bottom: calc(0.625rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;
  }

  .col-form-label-lg {
    padding-top: calc(0.785rem + 1px);
    padding-bottom: calc(0.785rem + 1px);
    font-size: 0.92rem;
  }

  .col-form-label-sm {
    padding-top: calc(0.475rem + 1px);
    padding-bottom: calc(0.475rem + 1px);
    font-size: 0.69rem;
  }

  .form-text {
    margin-top: 0.25rem;
    font-size: 0.69rem;
    color: rgba(67, 74, 87, 0.5);
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.625rem 1rem;
    font-size: 0.805rem;
    font-weight: 400;
    line-height: 1.5;
    color: #252a44;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d7dde2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-control[type="file"] {
    overflow: hidden;
  }

  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  .form-control:focus {
    color: #252a44;
    background-color: #ffffff;
    border-color: rgba(105, 54, 245, 0.35);
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent,
      0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
    box-shadow: inset 0 0 0 transparent,
      0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
  }

  .form-control::-webkit-date-and-time-value {
    height: 1.5em;
  }

  .form-control::-webkit-input-placeholder {
    color: #b4bbc3;
    opacity: 1;
  }

  .form-control::-moz-placeholder {
    color: #b4bbc3;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder {
    color: #b4bbc3;
    opacity: 1;
  }

  .form-control::-ms-input-placeholder {
    color: #b4bbc3;
    opacity: 1;
  }

  .form-control::placeholder {
    color: #b4bbc3;
    opacity: 1;
  }

  .form-control:disabled {
    background-color: #f3f4f5;
    opacity: 1;
  }

  .form-control::-webkit-file-upload-button {
    padding: 0.625rem 1rem;
    margin: -0.625rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #252a44;
    background-color: #ffffff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
  }

  .form-control::file-selector-button {
    padding: 0.625rem 1rem;
    margin: -0.625rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #252a44;
    background-color: #ffffff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
    }

    .form-control::file-selector-button {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-control:hover:not(:disabled):not(
      [readonly]
    )::-webkit-file-upload-button {
    background-color: #f2f2f2;
  }

  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2;
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.625rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #252a44;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }

  .form-control-plaintext:focus {
    outline: 0;
  }

  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }

  .form-control-sm {
    min-height: calc(1.5em + 0.95rem + 2px);
    padding: 0.475rem 0.875rem;
    font-size: 0.69rem;
    border-radius: 0.25rem;
  }

  .form-control-sm::-webkit-file-upload-button {
    padding: 0.475rem 0.875rem;
    margin: -0.475rem -0.875rem;
    -webkit-margin-end: 0.875rem;
    margin-inline-end: 0.875rem;
  }

  .form-control-sm::file-selector-button {
    padding: 0.475rem 0.875rem;
    margin: -0.475rem -0.875rem;
    -webkit-margin-end: 0.875rem;
    margin-inline-end: 0.875rem;
  }

  .form-control-lg {
    min-height: calc(1.5em + 1.57rem + 2px);
    padding: 0.785rem 1.125rem;
    font-size: 0.92rem;
    border-radius: 0.5rem;
  }

  .form-control-lg::-webkit-file-upload-button {
    padding: 0.785rem 1.125rem;
    margin: -0.785rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
  }

  .form-control-lg::file-selector-button {
    padding: 0.785rem 1.125rem;
    margin: -0.785rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
  }

  textarea.form-control {
    min-height: calc(1.5em + 1.25rem + 2px);
  }

  textarea.form-control-sm {
    min-height: calc(1.5em + 0.95rem + 2px);
  }

  textarea.form-control-lg {
    min-height: calc(1.5em + 1.57rem + 2px);
  }

  .form-control-color {
    width: 3rem;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem;
  }

  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.375rem;
  }

  .form-control-color::-webkit-color-swatch {
    border-radius: 0.375rem;
  }

  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.95rem + 2px);
  }

  .form-control-color.form-control-lg {
    height: calc(1.5em + 1.57rem + 2px);
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.625rem 3rem 0.625rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 0.805rem;
    font-weight: 400;
    line-height: 1.5;
    color: #252a44;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23434a57' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 12px;
    border: 1px solid #d7dde2;
    border-radius: 0.375rem;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-select {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-select:focus {
    border-color: rgba(105, 54, 245, 0.35);
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent,
      0 0 0 0 rgba(105, 54, 245, 0.25);
    box-shadow: inset 0 0 0 transparent, 0 0 0 0 rgba(105, 54, 245, 0.25);
  }

  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none;
  }

  .form-select:disabled {
    background-color: #f3f4f5;
  }

  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #252a44;
  }

  .form-select-sm {
    padding-top: 0.475rem;
    padding-bottom: 0.475rem;
    padding-left: 0.875rem;
    font-size: 0.69rem;
    border-radius: 0.25rem;
  }

  .form-select-lg {
    padding-top: 0.785rem;
    padding-bottom: 0.785rem;
    padding-left: 1.125rem;
    font-size: 0.92rem;
    border-radius: 0.5rem;
  }

  .form-check {
    display: block;
    min-height: 1.38rem;
    padding-left: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .form-check .form-check-input {
    float: left;
    margin-left: -1.5rem;
  }

  .form-check-reverse {
    padding-right: 1.5rem;
    padding-left: 0;
    text-align: right;
  }

  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5rem;
    margin-left: 0;
  }

  .form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    vertical-align: top;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid #b4bbc3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 0.1875em;
  }

  .form-check-input[type="radio"] {
    border-radius: 50%;
  }

  .form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
  }

  .form-check-input:focus {
    border-color: rgba(105, 54, 245, 0.35);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(105, 54, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(105, 54, 245, 0.25);
  }

  .form-check-input:checked {
    background-color: #146ad3;
    border-color: #146ad3;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  }

  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
  }

  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #146ad3;
    border-color: #146ad3;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }

  .form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.55;
  }

  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    cursor: default;
    opacity: 0.55;
  }

  .form-switch {
    padding-left: 3.375em;
  }

  .form-switch .form-check-input {
    width: 2.875em;
    margin-left: -3.375em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2.875em;
    -webkit-transition: background-position 0.15s ease-in-out;
    transition: background-position 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  }

  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  }

  .form-switch.form-check-reverse {
    padding-right: 3.375em;
    padding-left: 0;
  }

  .form-switch.form-check-reverse .form-check-input {
    margin-right: -3.375em;
    margin-left: 0;
  }

  .form-check-inline {
    display: inline-block;
    margin-right: 1rem;
  }

  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  .btn-check:disabled + .btn,
  .btn-check[disabled] + .btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.65;
  }

  .form-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-range:focus {
    outline: 0;
  }

  .form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #f3f4f5,
      0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
    box-shadow: 0 0 0 1px #f3f4f5,
      0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
  }

  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f3f4f5,
      0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
  }

  .form-range::-moz-focus-outer {
    border: 0;
  }

  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #146ad3;
    border: 0;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0.1rem 0.25rem rgba(11, 15, 25, 0.1);
    box-shadow: 0 0.1rem 0.25rem rgba(11, 15, 25, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-range::-webkit-slider-thumb:active {
    background-color: #490cf1;
  }

  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #fbfbfc;
    border-color: transparent;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
  }

  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #146ad3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(11, 15, 25, 0.1);
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }

  .form-range::-moz-range-thumb:active {
    background-color: #490cf1;
  }

  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #fbfbfc;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0 0 transparent;
  }

  .form-range:disabled {
    pointer-events: none;
  }

  .form-range:disabled::-webkit-slider-thumb {
    background-color: #b4bbc3;
  }

  .form-range:disabled::-moz-range-thumb {
    background-color: #b4bbc3;
  }

  .form-floating {
    position: relative;
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
  }

  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity 0.1s ease-in-out,
      -webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
      -webkit-transform 0.1s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      -webkit-transition: none;
      transition: none;
    }
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 1rem;
  }

  .form-floating > .form-control-plaintext::-webkit-input-placeholder,
  .form-floating > .form-control::-webkit-input-placeholder {
    color: transparent;
  }

  .form-floating > .form-control-plaintext::-moz-placeholder,
  .form-floating > .form-control::-moz-placeholder {
    color: transparent;
  }

  .form-floating > .form-control-plaintext:-ms-input-placeholder,
  .form-floating > .form-control:-ms-input-placeholder {
    color: transparent;
  }

  .form-floating > .form-control-plaintext::-ms-input-placeholder,
  .form-floating > .form-control::-ms-input-placeholder {
    color: transparent;
  }

  .form-floating > .form-control-plaintext::placeholder,
  .form-floating > .form-control::placeholder {
    color: transparent;
  }

  .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown),
  .form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-control-plaintext:not(:-ms-input-placeholder),
  .form-floating > .form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown),
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-control-plaintext:-webkit-autofill,
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.7;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
    opacity: 0.7;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.7;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.7;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0;
  }

  .btn {
    --si-btn-padding-x: 1.75rem;
    --si-btn-padding-y: 0.625rem;
    --si-btn-font-size: 0.805rem;
    --si-btn-font-weight: 600;
    --si-btn-line-height: 1.5;
    --si-btn-color: #252a44;
    --si-btn-bg: transparent;
    --si-btn-border-width: 1px;
    --si-btn-border-color: transparent;
    --si-btn-border-radius: 0.375rem;
    --si-btn-hover-border-color: transparent;
    --si-btn-box-shadow: unset;
    --si-btn-disabled-opacity: 0.65;
    --si-btn-focus-box-shadow: 0 0 0 0 rgba(var(--si-btn-focus-shadow-rgb), 0.5);
    display: inline-block;
    padding: var(--si-btn-padding-y) var(--si-btn-padding-x);
    font-size: var(--si-btn-font-size);
    font-weight: var(--si-btn-font-weight);
    line-height: var(--si-btn-line-height);
    color: var(--si-btn-color);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--si-btn-border-width) solid var(--si-btn-border-color);
    border-radius: var(--si-btn-border-radius);
    background-color: var(--si-btn-bg);
    -webkit-box-shadow: var(--si-btn-box-shadow);
    box-shadow: var(--si-btn-box-shadow);
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none;
    }
  }

  .btn:first-child:hover,
  :not(.btn-check) + .btn:hover {
    color: var(--si-btn-hover-color);
    background-color: var(--si-btn-hover-bg);
    border-color: var(--si-btn-hover-border-color);
  }

  .btn:focus-visible {
    color: var(--si-btn-hover-color);
    background-color: var(--si-btn-hover-bg);
    border-color: var(--si-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--si-btn-box-shadow), var(--si-btn-focus-box-shadow);
    box-shadow: var(--si-btn-box-shadow), var(--si-btn-focus-box-shadow);
  }

  .btn-check:focus-visible + .btn {
    border-color: var(--si-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--si-btn-box-shadow), var(--si-btn-focus-box-shadow);
    box-shadow: var(--si-btn-box-shadow), var(--si-btn-focus-box-shadow);
  }

  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    color: var(--si-btn-active-color);
    background-color: var(--si-btn-active-bg);
    border-color: var(--si-btn-active-border-color);
    -webkit-box-shadow: var(--si-btn-active-shadow);
    box-shadow: var(--si-btn-active-shadow);
  }

  .btn-check:checked + .btn:focus-visible,
  .btn.active:focus-visible,
  .btn.show:focus-visible,
  .btn:first-child:active:focus-visible,
  :not(.btn-check) + .btn:active:focus-visible {
    -webkit-box-shadow: var(--si-btn-active-shadow),
      var(--si-btn-focus-box-shadow);
    box-shadow: var(--si-btn-active-shadow), var(--si-btn-focus-box-shadow);
  }

  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    color: var(--si-btn-disabled-color);
    pointer-events: none;
    background-color: var(--si-btn-disabled-bg);
    border-color: var(--si-btn-disabled-border-color);
    opacity: var(--si-btn-disabled-opacity);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-primary {
    --si-btn-color: #ffffff;
    --si-btn-bg: #146ad3;
    --si-btn-border-color: #146ad3;
    --si-btn-hover-color: #ffffff;
    --si-btn-hover-bg: #592ed0;
    --si-btn-hover-border-color: #542bc4;
    --si-btn-focus-shadow-rgb: 128, 84, 247;
    --si-btn-active-color: #ffffff;
    --si-btn-active-bg: #542bc4;
    --si-btn-active-border-color: #4f29b8;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #ffffff;
    --si-btn-disabled-bg: #146ad3;
    --si-btn-disabled-border-color: #146ad3;
  }

  .btn-secondary {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #f5f5f7;
    --si-btn-border-color: #f5f5f7;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #f7f7f8;
    --si-btn-hover-border-color: #f6f6f8;
    --si-btn-focus-shadow-rgb: 210, 211, 214;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #f7f7f9;
    --si-btn-active-border-color: #f6f6f8;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #f5f5f7;
    --si-btn-disabled-border-color: #f5f5f7;
  }

  .btn-success {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #17f1d2;
    --si-btn-border-color: #17f1d2;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #3af3d9;
    --si-btn-hover-border-color: #2ef2d7;
    --si-btn-focus-shadow-rgb: 21, 207, 182;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #45f4db;
    --si-btn-active-border-color: #2ef2d7;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #17f1d2;
    --si-btn-disabled-border-color: #17f1d2;
  }

  .btn-info {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #a1a1a6;
    --si-btn-border-color: #a1a1a6;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #afafb3;
    --si-btn-hover-border-color: #aaaaaf;
    --si-btn-focus-shadow-rgb: 139, 139, 145;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #b4b4b8;
    --si-btn-active-border-color: #aaaaaf;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #a1a1a6;
    --si-btn-disabled-border-color: #a1a1a6;
  }

  .btn-warning {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #ffd073;
    --si-btn-border-color: #ffd073;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #ffd788;
    --si-btn-hover-border-color: #ffd581;
    --si-btn-focus-shadow-rgb: 218, 179, 102;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #ffd98f;
    --si-btn-active-border-color: #ffd581;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #ffd073;
    --si-btn-disabled-border-color: #ffd073;
  }

  .btn-danger {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #ff4f00;
    --si-btn-border-color: #ff4f00;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #ff6926;
    --si-btn-hover-border-color: #ff611a;
    --si-btn-focus-shadow-rgb: 218, 69, 4;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #ff7233;
    --si-btn-active-border-color: #ff611a;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #ff4f00;
    --si-btn-disabled-border-color: #ff4f00;
  }

  .btn-light {
    --si-btn-color: #0b0f19;
    --si-btn-bg: #f3f4f5;
    --si-btn-border-color: #f3f4f5;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #cfcfd0;
    --si-btn-hover-border-color: #c2c3c4;
    --si-btn-focus-shadow-rgb: 208, 210, 212;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #c2c3c4;
    --si-btn-active-border-color: #b6b7b8;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #0b0f19;
    --si-btn-disabled-bg: #f3f4f5;
    --si-btn-disabled-border-color: #f3f4f5;
  }

  .btn-dark {
    --si-btn-color: #ffffff;
    --si-btn-bg: #000000;
    --si-btn-border-color: #000000;
    --si-btn-hover-color: #ffffff;
    --si-btn-hover-bg: #36383c;
    --si-btn-hover-border-color: #2a2c30;
    --si-btn-focus-shadow-rgb: 54, 56, 60;
    --si-btn-active-color: #ffffff;
    --si-btn-active-bg: #414447;
    --si-btn-active-border-color: #2a2c30;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #ffffff;
    --si-btn-disabled-bg: #000000;
    --si-btn-disabled-border-color: #000000;
  }

  .btn-outline-primary {
    --si-btn-color: #146ad3;
    --si-btn-border-color: #146ad3;
    --si-btn-hover-color: #ffffff;
    --si-btn-hover-bg: #146ad3;
    --si-btn-hover-border-color: #146ad3;
    --si-btn-focus-shadow-rgb: 105, 54, 245;
    --si-btn-active-color: #ffffff;
    --si-btn-active-bg: #146ad3;
    --si-btn-active-border-color: #146ad3;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #146ad3;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #146ad3;
    --si-gradient: none;
  }

  .btn-outline-secondary {
    --si-btn-color: #f5f5f7;
    --si-btn-border-color: #f5f5f7;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #f5f5f7;
    --si-btn-hover-border-color: #f5f5f7;
    --si-btn-focus-shadow-rgb: 245, 245, 247;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #f5f5f7;
    --si-btn-active-border-color: #f5f5f7;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #f5f5f7;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #f5f5f7;
    --si-gradient: none;
  }

  .btn-outline-success {
    --si-btn-color: #17f1d2;
    --si-btn-border-color: #17f1d2;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #17f1d2;
    --si-btn-hover-border-color: #17f1d2;
    --si-btn-focus-shadow-rgb: 23, 241, 210;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #17f1d2;
    --si-btn-active-border-color: #17f1d2;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #17f1d2;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #17f1d2;
    --si-gradient: none;
  }

  .btn-outline-info {
    --si-btn-color: #a1a1a6;
    --si-btn-border-color: #a1a1a6;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #a1a1a6;
    --si-btn-hover-border-color: #a1a1a6;
    --si-btn-focus-shadow-rgb: 161, 161, 166;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #a1a1a6;
    --si-btn-active-border-color: #a1a1a6;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #a1a1a6;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #a1a1a6;
    --si-gradient: none;
  }

  .btn-outline-warning {
    --si-btn-color: #ffd073;
    --si-btn-border-color: #ffd073;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #ffd073;
    --si-btn-hover-border-color: #ffd073;
    --si-btn-focus-shadow-rgb: 255, 208, 115;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #ffd073;
    --si-btn-active-border-color: #ffd073;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #ffd073;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #ffd073;
    --si-gradient: none;
  }

  .btn-outline-danger {
    --si-btn-color: #ff4f00;
    --si-btn-border-color: #ff4f00;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #ff4f00;
    --si-btn-hover-border-color: #ff4f00;
    --si-btn-focus-shadow-rgb: 255, 79, 0;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #ff4f00;
    --si-btn-active-border-color: #ff4f00;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #ff4f00;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #ff4f00;
    --si-gradient: none;
  }

  .btn-outline-light {
    --si-btn-color: #f3f4f5;
    --si-btn-border-color: #f3f4f5;
    --si-btn-hover-color: #0b0f19;
    --si-btn-hover-bg: #f3f4f5;
    --si-btn-hover-border-color: #f3f4f5;
    --si-btn-focus-shadow-rgb: 243, 244, 245;
    --si-btn-active-color: #0b0f19;
    --si-btn-active-bg: #f3f4f5;
    --si-btn-active-border-color: #f3f4f5;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #f3f4f5;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #f3f4f5;
    --si-gradient: none;
  }

  .btn-outline-dark {
    --si-btn-color: #000000;
    --si-btn-border-color: #000000;
    --si-btn-hover-color: #ffffff;
    --si-btn-hover-bg: #000000;
    --si-btn-hover-border-color: #000000;
    --si-btn-focus-shadow-rgb: 18, 21, 25;
    --si-btn-active-color: #ffffff;
    --si-btn-active-bg: #000000;
    --si-btn-active-border-color: #000000;
    --si-btn-active-shadow: unset;
    --si-btn-disabled-color: #000000;
    --si-btn-disabled-bg: transparent;
    --si-btn-disabled-border-color: #000000;
    --si-gradient: none;
  }

  .btn-link {
    --si-btn-font-weight: 400;
    --si-btn-color: var(--si-link-color);
    --si-btn-bg: transparent;
    --si-btn-border-color: transparent;
    --si-btn-hover-color: var(--si-link-hover-color);
    --si-btn-hover-border-color: transparent;
    --si-btn-active-color: var(--si-link-hover-color);
    --si-btn-active-border-color: transparent;
    --si-btn-disabled-color: #858c97;
    --si-btn-disabled-border-color: transparent;
    --si-btn-box-shadow: none;
    --si-btn-focus-shadow-rgb: 128, 84, 247;
    text-decoration: underline;
  }

  .btn-link:focus-visible,
  .btn-link:hover {
    text-decoration: none;
  }

  .btn-link:focus-visible {
    color: var(--si-btn-color);
  }

  .btn-link:hover {
    color: var(--si-btn-hover-color);
  }

  .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }

  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none;
    }
  }

  .fade:not(.show) {
    opacity: 0;
  }

  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none;
    }
  }

  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    -webkit-transition: width 0.35s ease;
    transition: width 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      -webkit-transition: none;
      transition: none;
    }
  }

  .dropdown,
  .dropdown-center,
  .dropend,
  .dropstart,
  .dropup,
  .dropup-center {
    position: relative;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }

  .dropdown-menu {
    --si-dropdown-zindex: 1000;
    --si-dropdown-min-width: 12rem;
    --si-dropdown-padding-x: 0;
    --si-dropdown-padding-y: 0.5rem;
    --si-dropdown-spacer: 0.25rem;
    --si-dropdown-font-size: 0.805rem;
    --si-dropdown-color: #252a44;
    --si-dropdown-bg: #ffffff;
    --si-dropdown-border-color: #ffffff;
    --si-dropdown-border-radius: 0.5rem;
    --si-dropdown-border-width: 1px;
    --si-dropdown-inner-border-radius: calc(0.5rem - 1px);
    --si-dropdown-divider-bg: #f5f5f7;
    --si-dropdown-divider-margin-y: 0.375rem;
    --si-dropdown-box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03);
    --si-dropdown-link-color: #252a44;
    --si-dropdown-link-hover-color: #146ad3;
    --si-dropdown-link-hover-bg: transparent;
    --si-dropdown-link-active-color: #146ad3;
    --si-dropdown-link-active-bg: transparent;
    --si-dropdown-link-disabled-color: #858c97;
    --si-dropdown-item-padding-x: 1rem;
    --si-dropdown-item-padding-y: 0.375rem;
    --si-dropdown-header-color: var(--si-gray-900);
    --si-dropdown-header-padding-x: 1rem;
    --si-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--si-dropdown-zindex);
    display: none;
    min-width: var(--si-dropdown-min-width);
    padding: var(--si-dropdown-padding-y) var(--si-dropdown-padding-x);
    margin: 0;
    font-size: var(--si-dropdown-font-size);
    color: var(--si-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--si-dropdown-bg);
    background-clip: padding-box;
    border: var(--si-dropdown-border-width) solid
      var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    -webkit-box-shadow: var(--si-dropdown-box-shadow);
    box-shadow: var(--si-dropdown-box-shadow);
  }

  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--si-dropdown-spacer);
  }

  .dropdown-menu-start {
    --bs-position: start;
  }

  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-end {
    --bs-position: end;
  }

  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
  }

  @media (min-width: 500px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }

    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
    }

    .dropdown-menu-sm-end {
      --bs-position: end;
    }

    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }

    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
    }

    .dropdown-menu-md-end {
      --bs-position: end;
    }

    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }

    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
    }

    .dropdown-menu-lg-end {
      --bs-position: end;
    }

    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }

    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }

    .dropdown-menu-xl-end {
      --bs-position: end;
    }

    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }

  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--si-dropdown-spacer);
  }

  .dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }

  .dropup .dropdown-toggle:empty:after {
    margin-left: 0;
  }

  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--si-dropdown-spacer);
  }

  .dropend .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .dropend .dropdown-toggle:empty:after {
    margin-left: 0;
  }

  .dropend .dropdown-toggle:after {
    vertical-align: 0;
  }

  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--si-dropdown-spacer);
  }

  .dropstart .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }

  .dropstart .dropdown-toggle:after {
    display: none;
  }

  .dropstart .dropdown-toggle:before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }

  .dropstart .dropdown-toggle:empty:after {
    margin-left: 0;
  }

  .dropstart .dropdown-toggle:before {
    vertical-align: 0;
  }

  .dropdown-divider {
    height: 0;
    margin: var(--si-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--si-dropdown-divider-bg);
    opacity: 1;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: var(--si-dropdown-item-padding-y) var(--si-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--si-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: var(--si-dropdown-link-hover-color);
    background-color: var(--si-dropdown-link-hover-bg);
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: var(--si-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--si-dropdown-link-active-bg);
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: var(--si-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-header {
    display: block;
    padding: var(--si-dropdown-header-padding-y)
      var(--si-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: 0.805rem;
    color: var(--si-dropdown-header-color);
    white-space: nowrap;
  }

  .dropdown-item-text {
    display: block;
    padding: var(--si-dropdown-item-padding-y) var(--si-dropdown-item-padding-x);
    color: var(--si-dropdown-link-color);
  }

  .dark-mode .dropdown-menu,
  .dropdown-menu-dark {
    --si-dropdown-color: rgba(255, 255, 255, 0.7);
    --si-dropdown-bg: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.03),
        rgba(255, 255, 255, 0.03)
      ),
      #000000;
    --si-dropdown-border-color: rgba(255, 255, 255, 0.14);
    --si-dropdown-box-shadow: ;
    --si-dropdown-link-color: rgba(255, 255, 255, 0.85);
    --si-dropdown-link-hover-color: #146ad3;
    --si-dropdown-divider-bg: rgba(255, 255, 255, 0.14);
    --si-dropdown-link-hover-bg: transparent;
    --si-dropdown-link-active-color: #146ad3;
    --si-dropdown-link-active-bg: transparent;
    --si-dropdown-link-disabled-color: rgba(255, 255, 255, 0.4);
    --si-dropdown-header-color: #ffffff;
  }

  .dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
  }

  .dropdown-toggle-split:after,
  .dropend .dropdown-toggle-split:after,
  .dropup .dropdown-toggle-split:after {
    margin-left: 0;
  }

  .dropstart .dropdown-toggle-split:before {
    margin-right: 0;
  }

  .nav {
    --si-nav-link-padding-x: 1rem;
    --si-nav-link-padding-y: 0.535rem;
    --si-nav-link-font-weight: 500;
    --si-nav-link-color: #252a44;
    --si-nav-link-hover-color: #146ad3;
    --si-nav-link-disabled-color: #858c97;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: var(--si-nav-link-padding-y) var(--si-nav-link-padding-x);
    font-size: var(--si-nav-link-font-size);
    font-weight: var(--si-nav-link-font-weight);
    color: var(--si-nav-link-color);
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      -webkit-transition: none;
      transition: none;
    }
  }

  .nav-link:focus,
  .nav-link:hover {
    color: var(--si-nav-link-hover-color);
  }

  .nav-link.disabled {
    color: var(--si-nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    --si-nav-tabs-border-width: 0;
    --si-nav-tabs-border-color: transparent;
    --si-nav-tabs-border-radius: 0.375rem;
    --si-nav-tabs-link-hover-border-color: transparent;
    --si-nav-tabs-link-active-color: #ffffff;
    --si-nav-tabs-link-active-bg: #146ad3;
    --si-nav-tabs-link-active-border-color: transparent;
    border-bottom: var(--si-nav-tabs-border-width) solid
      var(--si-nav-tabs-border-color);
  }

  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--si-nav-tabs-border-width));
    background: none;
    border: var(--si-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--si-nav-tabs-border-radius);
    border-top-right-radius: var(--si-nav-tabs-border-radius);
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--si-nav-tabs-link-hover-border-color);
  }

  .nav-tabs .nav-link.disabled,
  .nav-tabs .nav-link:disabled {
    color: var(--si-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: var(--si-nav-tabs-link-active-color);
    background-color: var(--si-nav-tabs-link-active-bg);
    border-color: var(--si-nav-tabs-link-active-border-color);
  }

  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--si-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-pills {
    --si-nav-pills-border-radius: 50rem;
    --si-nav-pills-link-active-color: #ffffff;
    --si-nav-pills-link-active-bg: #146ad3;
  }

  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--si-nav-pills-border-radius);
  }

  .nav-pills .nav-link:disabled {
    color: var(--si-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--si-nav-pills-link-active-color);
    background-color: var(--si-nav-pills-link-active-bg);
  }

  .nav-fill .nav-item,
  .nav-fill > .nav-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }

  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%;
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block;
  }

  .navbar {
    --si-navbar-padding-x: 0;
    --si-navbar-padding-y: 0.5rem;
    --si-navbar-color: #252a44;
    --si-navbar-hover-color: #146ad3;
    --si-navbar-disabled-color: #858c97;
    --si-navbar-active-color: #146ad3;
    --si-navbar-brand-padding-y: 0.5rem;
    --si-navbar-brand-margin-end: 1rem;
    --si-navbar-brand-font-size: 1.375rem;
    --si-navbar-brand-color: #000000;
    --si-navbar-brand-hover-color: #000000;
    --si-navbar-nav-link-padding-x: 0.875rem;
    --si-navbar-toggler-padding-y: 0.625rem;
    --si-navbar-toggler-padding-x: 0.25rem;
    --si-navbar-toggler-font-size: 1.035rem;
    --si-navbar-toggler-icon-bg: initial;
    --si-navbar-toggler-border-color: transparent;
    --si-navbar-toggler-border-radius: 0;
    --si-navbar-toggler-focus-width: 0;
    --si-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--si-navbar-padding-y) var(--si-navbar-padding-x);
  }

  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navbar-brand {
    padding-top: var(--si-navbar-brand-padding-y);
    padding-bottom: var(--si-navbar-brand-padding-y);
    margin-right: var(--si-navbar-brand-margin-end);
    font-size: var(--si-navbar-brand-font-size);
    color: var(--si-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
  }

  .navbar-brand:focus,
  .navbar-brand:hover {
    color: var(--si-navbar-brand-hover-color);
  }

  .navbar-nav {
    --si-nav-link-padding-x: 0;
    --si-nav-link-padding-y: 0.535rem;
    --si-nav-link-font-weight: 500;
    --si-nav-link-color: var(--si-navbar-color);
    --si-nav-link-hover-color: var(--si-navbar-hover-color);
    --si-nav-link-disabled-color: var(--si-navbar-disabled-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: var(--si-navbar-active-color);
  }

  .navbar-nav .dropdown-menu {
    position: static;
  }

  .navbar-text {
    padding-top: 0.535rem;
    padding-bottom: 0.535rem;
    color: var(--si-navbar-color);
  }

  .navbar-text a,
  .navbar-text a:focus,
  .navbar-text a:hover {
    color: var(--si-navbar-active-color);
  }

  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggler {
    padding: var(--si-navbar-toggler-padding-y)
      var(--si-navbar-toggler-padding-x);
    font-size: var(--si-navbar-toggler-font-size);
    line-height: 1;
    color: var(--si-navbar-color);
    background-color: transparent;
    border: var(--si-border-width) solid var(--si-navbar-toggler-border-color);
    border-radius: var(--si-navbar-toggler-border-radius);
    -webkit-transition: var(--si-navbar-toggler-transition);
    transition: var(--si-navbar-toggler-transition);
  }

  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      -webkit-transition: none;
      transition: none;
    }
  }

  .navbar-toggler:hover {
    text-decoration: none;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 var(--si-navbar-toggler-focus-width);
    box-shadow: 0 0 0 var(--si-navbar-toggler-focus-width);
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--si-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  .navbar-nav-scroll {
    max-height: var(--si-scroll-height, 75vh);
    overflow-y: auto;
  }

  @media (min-width: 500px) {
    .navbar-expand-sm {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: var(--si-navbar-nav-link-padding-x);
      padding-left: var(--si-navbar-nav-link-padding-x);
    }

    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
      display: none;
    }

    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      -webkit-transform: none !important;
      transform: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
      display: none;
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }

  @media (min-width: 768px) {
    .navbar-expand-md {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: var(--si-navbar-nav-link-padding-x);
      padding-left: var(--si-navbar-nav-link-padding-x);
    }

    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
      display: none;
    }

    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      -webkit-transform: none !important;
      transform: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
      display: none;
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: var(--si-navbar-nav-link-padding-x);
      padding-left: var(--si-navbar-nav-link-padding-x);
    }

    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
      display: none;
    }

    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      -webkit-transform: none !important;
      transform: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
      display: none;
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }

  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: var(--si-navbar-nav-link-padding-x);
      padding-left: var(--si-navbar-nav-link-padding-x);
    }

    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
      display: none;
    }

    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      -webkit-transform: none !important;
      transform: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
      display: none;
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }

  .navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }

  .navbar-expand .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand .navbar-toggler {
    display: none;
  }

  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
  }

  .navbar-expand .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }

  .navbar-dark {
    --si-navbar-color: rgba(255, 255, 255, 0.85);
    --si-navbar-hover-color: #146ad3;
    --si-navbar-disabled-color: rgba(255, 255, 255, 0.4);
    --si-navbar-active-color: #146ad3;
    --si-navbar-brand-color: #ffffff;
    --si-navbar-brand-hover-color: #ffffff;
    --si-navbar-toggler-border-color: transparent;
    --si-navbar-toggler-icon-bg: initial;
  }

  .card {
    --si-card-spacer-y: 2rem;
    --si-card-spacer-x: 2rem;
    --si-card-title-spacer-y: 0.75rem;
    --si-card-border-width: 1px;
    --si-card-border-color: #f5f5f7;
    --si-card-border-radius: 0.5rem;
    --si-card-box-shadow: ;
    --si-card-inner-border-radius: calc(0.5rem - 1px);
    --si-card-cap-padding-y: 1.5rem;
    --si-card-cap-padding-x: 2rem;
    --si-card-cap-bg: transparent;
    --si-card-cap-color: ;
    --si-card-height: ;
    --si-card-color: ;
    --si-card-bg: #ffffff;
    --si-card-img-overlay-padding: 1.5rem;
    --si-card-group-margin: 0.75rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--si-card-height);
    word-wrap: break-word;
    background-color: var(--si-card-bg);
    background-clip: border-box;
    border: var(--si-card-border-width) solid var(--si-card-border-color);
    border-radius: var(--si-card-border-radius);
    -webkit-box-shadow: var(--si-card-box-shadow);
    box-shadow: var(--si-card-box-shadow);
  }

  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }

  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--si-card-inner-border-radius);
    border-top-right-radius: var(--si-card-inner-border-radius);
  }

  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--si-card-inner-border-radius);
    border-bottom-left-radius: var(--si-card-inner-border-radius);
  }

  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--si-card-spacer-y) var(--si-card-spacer-x);
    color: var(--si-card-color);
  }

  .card-title {
    margin-bottom: var(--si-card-title-spacer-y);
  }

  .card-subtitle {
    margin-top: calc(-0.5 * var(--si-card-title-spacer-y));
    margin-bottom: 0;
  }

  .card-text:last-child {
    margin-bottom: 0;
  }

  .card-link + .card-link {
    margin-left: var(--si-card-spacer-x);
  }

  .card-header {
    padding: var(--si-card-cap-padding-y) var(--si-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--si-card-cap-color);
    background-color: var(--si-card-cap-bg);
    border-bottom: var(--si-card-border-width) solid var(--si-card-border-color);
  }

  .card-header:first-child {
    border-radius: var(--si-card-inner-border-radius)
      var(--si-card-inner-border-radius) 0 0;
  }

  .card-footer {
    padding: var(--si-card-cap-padding-y) var(--si-card-cap-padding-x);
    color: var(--si-card-cap-color);
    background-color: var(--si-card-cap-bg);
    border-top: var(--si-card-border-width) solid var(--si-card-border-color);
  }

  .card-footer:last-child {
    border-radius: 0 0 var(--si-card-inner-border-radius)
      var(--si-card-inner-border-radius);
  }

  .card-header-tabs {
    margin-right: calc(-0.5 * var(--si-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--si-card-cap-padding-y));
    margin-left: calc(-0.5 * var(--si-card-cap-padding-x));
    border-bottom: 0;
  }

  .card-header-tabs .nav-link.active {
    background-color: var(--si-card-bg);
    border-bottom-color: var(--si-card-bg);
  }

  .card-header-pills {
    margin-right: calc(-0.5 * var(--si-card-cap-padding-x));
    margin-left: calc(-0.5 * var(--si-card-cap-padding-x));
  }

  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--si-card-img-overlay-padding);
    border-radius: var(--si-card-inner-border-radius);
  }

  .card-img,
  .card-img-bottom,
  .card-img-top {
    width: 100%;
  }

  .card-img,
  .card-img-top {
    border-top-left-radius: var(--si-card-inner-border-radius);
    border-top-right-radius: var(--si-card-inner-border-radius);
  }

  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: var(--si-card-inner-border-radius);
    border-bottom-left-radius: var(--si-card-inner-border-radius);
  }

  .card-group > .card {
    margin-bottom: var(--si-card-group-margin);
  }

  @media (min-width: 500px) {
    .card-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }

    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }

    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
    }
  }

  .breadcrumb {
    --si-breadcrumb-padding-x: 0;
    --si-breadcrumb-padding-y: 0;
    --si-breadcrumb-margin-bottom: 1rem;
    --si-breadcrumb-font-size: 0.805rem;
    --si-breadcrumb-bg: ;
    --si-breadcrumb-border-radius: ;
    --si-breadcrumb-divider-color: var(--si-gray-600);
    --si-breadcrumb-item-padding-x: 0.625rem;
    --si-breadcrumb-item-active-color: var(--si-primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: var(--si-breadcrumb-padding-y) var(--si-breadcrumb-padding-x);
    margin-bottom: var(--si-breadcrumb-margin-bottom);
    font-size: var(--si-breadcrumb-font-size);
    list-style: none;
    background-color: var(--si-breadcrumb-bg);
    border-radius: var(--si-breadcrumb-border-radius);
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: var(--si-breadcrumb-item-padding-x);
  }

  .breadcrumb-item + .breadcrumb-item:before {
    float: left;
    padding-right: var(--si-breadcrumb-item-padding-x);
    color: var(--si-breadcrumb-divider-color);
    content: var(--si-breadcrumb-divider, "\ea55");
  }

  .breadcrumb-item.active {
    color: var(--si-breadcrumb-item-active-color);
  }

  .badge {
    --si-badge-padding-x: 0.6em;
    --si-badge-padding-y: 0.35em;
    --si-badge-font-size: 0.8125em;
    --si-badge-font-weight: 500;
    --si-badge-color: #ffffff;
    --si-badge-border-radius: 0.25rem;
    display: inline-block;
    padding: var(--si-badge-padding-y) var(--si-badge-padding-x);
    font-size: var(--si-badge-font-size);
    font-weight: var(--si-badge-font-weight);
    line-height: 1;
    color: var(--si-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--si-badge-border-radius);
  }

  .badge:empty {
    display: none;
  }

  .btn .badge {
    position: relative;
    top: -1px;
  }

  .btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 0.75em;
    height: 0.75em;
    padding: 0.25em 0.25em;
    color: #0b0f19;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230b0f19'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
      center/0.75em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }

  .btn-close:hover {
    color: #0b0f19;
    text-decoration: none;
    opacity: 0.75;
  }

  .btn-close:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
  }

  .btn-close.disabled,
  .btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.25;
  }

  .btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .tooltip {
    --si-tooltip-zindex: 1080;
    --si-tooltip-max-width: 200px;
    --si-tooltip-padding-x: 0.5rem;
    --si-tooltip-padding-y: 0.25rem;
    --si-tooltip-margin: ;
    --si-tooltip-font-size: 0.7475rem;
    --si-tooltip-color: #ffffff;
    --si-tooltip-bg: #0b0f19;
    --si-tooltip-border-radius: 0.25rem;
    --si-tooltip-opacity: 0.9;
    --si-tooltip-arrow-width: 0.8rem;
    --si-tooltip-arrow-height: 0.4rem;
    z-index: var(--si-tooltip-zindex);
    display: block;
    padding: var(--si-tooltip-arrow-height);
    margin: var(--si-tooltip-margin);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--si-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0;
  }

  .tooltip.show {
    opacity: var(--si-tooltip-opacity);
  }

  .tooltip .tooltip-arrow {
    display: block;
    width: var(--si-tooltip-arrow-width);
    height: var(--si-tooltip-arrow-height);
  }

  .tooltip .tooltip-arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
  .bs-tooltip-top .tooltip-arrow {
    bottom: 0;
  }

  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
  .bs-tooltip-top .tooltip-arrow:before {
    top: -1px;
    border-width: var(--si-tooltip-arrow-height)
      calc(var(--si-tooltip-arrow-width) * 0.5) 0;
    border-top-color: var(--si-tooltip-bg);
  }

  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
  .bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: var(--si-tooltip-arrow-height);
    height: var(--si-tooltip-arrow-width);
  }

  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
  .bs-tooltip-end .tooltip-arrow:before {
    right: -1px;
    border-width: calc(var(--si-tooltip-arrow-width) * 0.5)
      var(--si-tooltip-arrow-height) calc(var(--si-tooltip-arrow-width) * 0.5) 0;
    border-right-color: var(--si-tooltip-bg);
  }

  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
  .bs-tooltip-bottom .tooltip-arrow {
    top: 0;
  }

  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
  .bs-tooltip-bottom .tooltip-arrow:before {
    bottom: -1px;
    border-width: 0 calc(var(--si-tooltip-arrow-width) * 0.5)
      var(--si-tooltip-arrow-height);
    border-bottom-color: var(--si-tooltip-bg);
  }

  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
  .bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: var(--si-tooltip-arrow-height);
    height: var(--si-tooltip-arrow-width);
  }

  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
  .bs-tooltip-start .tooltip-arrow:before {
    left: -1px;
    border-width: calc(var(--si-tooltip-arrow-width) * 0.5) 0
      calc(var(--si-tooltip-arrow-width) * 0.5) var(--si-tooltip-arrow-height);
    border-left-color: var(--si-tooltip-bg);
  }

  .tooltip-inner {
    max-width: var(--si-tooltip-max-width);
    padding: var(--si-tooltip-padding-y) var(--si-tooltip-padding-x);
    color: var(--si-tooltip-color);
    text-align: center;
    background-color: var(--si-tooltip-bg);
    border-radius: var(--si-tooltip-border-radius);
  }

  .popover {
    --si-popover-zindex: 1070;
    --si-popover-max-width: 276px;
    --si-popover-font-size: 0.805rem;
    --si-popover-bg: #ffffff;
    --si-popover-border-width: 1px;
    --si-popover-border-color: #f5f5f7;
    --si-popover-border-radius: 0.5rem;
    --si-popover-inner-border-radius: calc(0.5rem - 1px);
    --si-popover-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    --si-popover-header-padding-x: 1rem;
    --si-popover-header-padding-y: 0.75rem;
    --si-popover-header-font-size: 0.92rem;
    --si-popover-header-color: var(--si-gray-900);
    --si-popover-header-bg: transparent;
    --si-popover-body-padding-x: 1rem;
    --si-popover-body-padding-y: 1rem;
    --si-popover-body-color: #252a44;
    --si-popover-arrow-width: 1rem;
    --si-popover-arrow-height: 0.5rem;
    --si-popover-arrow-border: var(--si-popover-border-color);
    z-index: var(--si-popover-zindex);
    display: block;
    max-width: var(--si-popover-max-width);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--si-popover-font-size);
    word-wrap: break-word;
    background-color: var(--si-popover-bg);
    background-clip: padding-box;
    border: var(--si-popover-border-width) solid var(--si-popover-border-color);
    border-radius: var(--si-popover-border-radius);
    -webkit-box-shadow: var(--si-popover-box-shadow);
    box-shadow: var(--si-popover-box-shadow);
  }

  .popover .popover-arrow {
    display: block;
    width: var(--si-popover-arrow-width);
    height: var(--si-popover-arrow-height);
  }

  .popover .popover-arrow:after,
  .popover .popover-arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0;
  }

  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
  .bs-popover-top > .popover-arrow {
    bottom: calc(
      -1 * (var(--si-popover-arrow-height)) - var(--si-popover-border-width)
    );
  }

  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
  .bs-popover-top > .popover-arrow:after,
  .bs-popover-top > .popover-arrow:before {
    border-width: var(--si-popover-arrow-height)
      calc(var(--si-popover-arrow-width) * 0.5) 0;
  }

  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
  .bs-popover-top > .popover-arrow:before {
    bottom: 0;
    border-top-color: var(--si-popover-arrow-border);
  }

  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
  .bs-popover-top > .popover-arrow:after {
    bottom: var(--si-popover-border-width);
    border-top-color: var(--si-popover-bg);
  }

  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
  .bs-popover-end > .popover-arrow {
    left: calc(
      -1 * (var(--si-popover-arrow-height)) - var(--si-popover-border-width)
    );
    width: var(--si-popover-arrow-height);
    height: var(--si-popover-arrow-width);
  }

  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
  .bs-popover-end > .popover-arrow:after,
  .bs-popover-end > .popover-arrow:before {
    border-width: calc(var(--si-popover-arrow-width) * 0.5)
      var(--si-popover-arrow-height) calc(var(--si-popover-arrow-width) * 0.5) 0;
  }

  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
  .bs-popover-end > .popover-arrow:before {
    left: 0;
    border-right-color: var(--si-popover-arrow-border);
  }

  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
  .bs-popover-end > .popover-arrow:after {
    left: var(--si-popover-border-width);
    border-right-color: var(--si-popover-bg);
  }

  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
  .bs-popover-bottom > .popover-arrow {
    top: calc(
      -1 * (var(--si-popover-arrow-height)) - var(--si-popover-border-width)
    );
  }

  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
  .bs-popover-bottom > .popover-arrow:after,
  .bs-popover-bottom > .popover-arrow:before {
    border-width: 0 calc(var(--si-popover-arrow-width) * 0.5)
      var(--si-popover-arrow-height);
  }

  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
  .bs-popover-bottom > .popover-arrow:before {
    top: 0;
    border-bottom-color: var(--si-popover-arrow-border);
  }

  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
  .bs-popover-bottom > .popover-arrow:after {
    top: var(--si-popover-border-width);
    border-bottom-color: var(--si-popover-bg);
  }

  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
  .bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--si-popover-arrow-width);
    margin-left: calc(-0.5 * var(--si-popover-arrow-width));
    content: "";
    border-bottom: var(--si-popover-border-width) solid
      var(--si-popover-header-bg);
  }

  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
  .bs-popover-start > .popover-arrow {
    right: calc(
      -1 * (var(--si-popover-arrow-height)) - var(--si-popover-border-width)
    );
    width: var(--si-popover-arrow-height);
    height: var(--si-popover-arrow-width);
  }

  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
  .bs-popover-start > .popover-arrow:after,
  .bs-popover-start > .popover-arrow:before {
    border-width: calc(var(--si-popover-arrow-width) * 0.5) 0
      calc(var(--si-popover-arrow-width) * 0.5) var(--si-popover-arrow-height);
  }

  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
  .bs-popover-start > .popover-arrow:before {
    right: 0;
    border-left-color: var(--si-popover-arrow-border);
  }

  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
  .bs-popover-start > .popover-arrow:after {
    right: var(--si-popover-border-width);
    border-left-color: var(--si-popover-bg);
  }

  .popover-header {
    padding: var(--si-popover-header-padding-y)
      var(--si-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--si-popover-header-font-size);
    color: var(--si-popover-header-color);
    background-color: var(--si-popover-header-bg);
    border-bottom: var(--si-popover-border-width) solid
      var(--si-popover-border-color);
    border-top-left-radius: var(--si-popover-inner-border-radius);
    border-top-right-radius: var(--si-popover-inner-border-radius);
  }

  .popover-header:empty {
    display: none;
  }

  .popover-body {
    padding: var(--si-popover-body-padding-y) var(--si-popover-body-padding-x);
    color: var(--si-popover-body-color);
  }

  .spinner-border,
  .spinner-grow {
    display: inline-block;
    width: var(--si-spinner-width);
    height: var(--si-spinner-height);
    vertical-align: var(--si-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--si-spinner-animation-speed) linear infinite
      var(--si-spinner-animation-name);
    animation: var(--si-spinner-animation-speed) linear infinite
      var(--si-spinner-animation-name);
  }

  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-border {
    --si-spinner-width: 2rem;
    --si-spinner-height: 2rem;
    --si-spinner-vertical-align: -0.125em;
    --si-spinner-border-width: 0.15em;
    --si-spinner-animation-speed: 0.75s;
    --si-spinner-animation-name: spinner-border;
    border: var(--si-spinner-border-width) solid currentcolor;
    border-right-color: transparent;
  }

  .spinner-border-sm {
    --si-spinner-width: 1rem;
    --si-spinner-height: 1rem;
    --si-spinner-border-width: 0.1em;
  }

  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .spinner-grow {
    --si-spinner-width: 2rem;
    --si-spinner-height: 2rem;
    --si-spinner-vertical-align: -0.125em;
    --si-spinner-animation-speed: 0.75s;
    --si-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0;
  }

  .spinner-grow-sm {
    --si-spinner-width: 1rem;
    --si-spinner-height: 1rem;
  }

  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      --si-spinner-animation-speed: 1.5s;
    }
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl {
    --si-offcanvas-zindex: 1045;
    --si-offcanvas-width: 90%;
    --si-offcanvas-height: 30vh;
    --si-offcanvas-padding-x: 2rem;
    --si-offcanvas-padding-y: 2rem;
    --si-offcanvas-color: ;
    --si-offcanvas-bg: #ffffff;
    --si-offcanvas-border-width: 0;
    --si-offcanvas-border-color: var(--si-border-color-translucent);
    --si-offcanvas-box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03);
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm {
      position: fixed;
      bottom: 0;
      z-index: var(--si-offcanvas-zindex);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 100%;
      color: var(--si-offcanvas-color);
      visibility: hidden;
      background-color: var(--si-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      -webkit-box-shadow: var(--si-offcanvas-box-shadow);
      box-shadow: var(--si-offcanvas-box-shadow);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 499.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      -webkit-transition: none;
      transition: none;
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--si-offcanvas-width);
      border-right: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--si-offcanvas-width);
      border-left: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-top: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.show:not(.hiding),
    .offcanvas-sm.showing {
      -webkit-transform: none;
      transform: none;
    }
  }

  @media (max-width: 499.98px) {
    .offcanvas-sm.hiding,
    .offcanvas-sm.show,
    .offcanvas-sm.showing {
      visibility: visible;
    }
  }

  @media (min-width: 500px) {
    .offcanvas-sm {
      --si-offcanvas-height: auto;
      --si-offcanvas-border-width: 0;
      background-color: transparent !important;
    }

    .offcanvas-sm .offcanvas-header {
      display: none;
    }

    .offcanvas-sm .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md {
      position: fixed;
      bottom: 0;
      z-index: var(--si-offcanvas-zindex);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 100%;
      color: var(--si-offcanvas-color);
      visibility: hidden;
      background-color: var(--si-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      -webkit-box-shadow: var(--si-offcanvas-box-shadow);
      box-shadow: var(--si-offcanvas-box-shadow);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      -webkit-transition: none;
      transition: none;
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--si-offcanvas-width);
      border-right: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--si-offcanvas-width);
      border-left: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-top: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.show:not(.hiding),
    .offcanvas-md.showing {
      -webkit-transform: none;
      transform: none;
    }
  }

  @media (max-width: 767.98px) {
    .offcanvas-md.hiding,
    .offcanvas-md.show,
    .offcanvas-md.showing {
      visibility: visible;
    }
  }

  @media (min-width: 768px) {
    .offcanvas-md {
      --si-offcanvas-height: auto;
      --si-offcanvas-border-width: 0;
      background-color: transparent !important;
    }

    .offcanvas-md .offcanvas-header {
      display: none;
    }

    .offcanvas-md .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg {
      position: fixed;
      bottom: 0;
      z-index: var(--si-offcanvas-zindex);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 100%;
      color: var(--si-offcanvas-color);
      visibility: hidden;
      background-color: var(--si-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      -webkit-box-shadow: var(--si-offcanvas-box-shadow);
      box-shadow: var(--si-offcanvas-box-shadow);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      -webkit-transition: none;
      transition: none;
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--si-offcanvas-width);
      border-right: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--si-offcanvas-width);
      border-left: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-top: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.show:not(.hiding),
    .offcanvas-lg.showing {
      -webkit-transform: none;
      transform: none;
    }
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg.hiding,
    .offcanvas-lg.show,
    .offcanvas-lg.showing {
      visibility: visible;
    }
  }

  @media (min-width: 992px) {
    .offcanvas-lg {
      --si-offcanvas-height: auto;
      --si-offcanvas-border-width: 0;
      background-color: transparent !important;
    }

    .offcanvas-lg .offcanvas-header {
      display: none;
    }

    .offcanvas-lg .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl {
      position: fixed;
      bottom: 0;
      z-index: var(--si-offcanvas-zindex);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 100%;
      color: var(--si-offcanvas-color);
      visibility: hidden;
      background-color: var(--si-offcanvas-bg);
      background-clip: padding-box;
      outline: 0;
      -webkit-box-shadow: var(--si-offcanvas-box-shadow);
      box-shadow: var(--si-offcanvas-box-shadow);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      -webkit-transition: none;
      transition: none;
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--si-offcanvas-width);
      border-right: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--si-offcanvas-width);
      border-left: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--si-offcanvas-height);
      max-height: 100%;
      border-top: var(--si-offcanvas-border-width) solid
        var(--si-offcanvas-border-color);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.show:not(.hiding),
    .offcanvas-xl.showing {
      -webkit-transform: none;
      transform: none;
    }
  }

  @media (max-width: 1199.98px) {
    .offcanvas-xl.hiding,
    .offcanvas-xl.show,
    .offcanvas-xl.showing {
      visibility: visible;
    }
  }

  @media (min-width: 1200px) {
    .offcanvas-xl {
      --si-offcanvas-height: auto;
      --si-offcanvas-border-width: 0;
      background-color: transparent !important;
    }

    .offcanvas-xl .offcanvas-header {
      display: none;
    }

    .offcanvas-xl .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important;
    }
  }

  .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--si-offcanvas-zindex);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    -webkit-box-shadow: var(--si-offcanvas-box-shadow);
    box-shadow: var(--si-offcanvas-box-shadow);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      -webkit-transition: none;
      transition: none;
    }
  }

  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid
      var(--si-offcanvas-border-color);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid
      var(--si-offcanvas-border-color);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid
      var(--si-offcanvas-border-color);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid
      var(--si-offcanvas-border-color);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    -webkit-transform: none;
    transform: none;
  }

  .offcanvas.hiding,
  .offcanvas.show,
  .offcanvas.showing {
    visibility: visible;
  }

  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #0b0f19;
  }

  .offcanvas-backdrop.fade {
    opacity: 0;
  }

  .offcanvas-backdrop.show {
    opacity: 0.65;
  }

  .offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--si-offcanvas-padding-y) var(--si-offcanvas-padding-x);
  }

  .offcanvas-header .btn-close {
    padding: calc(var(--si-offcanvas-padding-y) * 0.5)
      calc(var(--si-offcanvas-padding-x) * 0.5);
    margin-top: calc(-0.5 * var(--si-offcanvas-padding-y));
    margin-right: calc(-0.5 * var(--si-offcanvas-padding-x));
    margin-bottom: calc(-0.5 * var(--si-offcanvas-padding-y));
  }

  .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .offcanvas-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: var(--si-offcanvas-padding-y) var(--si-offcanvas-padding-x);
    overflow-y: auto;
  }

  .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.35;
  }

  .placeholder.btn:before {
    display: inline-block;
    content: "";
  }

  .placeholder-xs {
    min-height: 0.6em;
  }

  .placeholder-sm {
    min-height: 0.8em;
  }

  .placeholder-lg {
    min-height: 1.2em;
  }

  .placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
  }

  @-webkit-keyframes placeholder-glow {
    50% {
      opacity: 0.12;
    }
  }

  @keyframes placeholder-glow {
    50% {
      opacity: 0.12;
    }
  }

  .placeholder-wave {
    -webkit-mask-image: linear-gradient(
      130deg,
      #0b0f19 55%,
      rgba(0, 0, 0, 0.88) 75%,
      #0b0f19 95%
    );
    mask-image: linear-gradient(
      130deg,
      #0b0f19 55%,
      rgba(0, 0, 0, 0.88) 75%,
      #0b0f19 95%
    );
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite;
  }

  @-webkit-keyframes placeholder-wave {
    to {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
    }
  }

  @keyframes placeholder-wave {
    to {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
    }
  }

  .clearfix:after {
    display: block;
    clear: both;
    content: "";
  }

  .text-bg-primary {
    color: #ffffff !important;
    background-color: RGBA(105, 54, 245, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-secondary {
    color: #0b0f19 !important;
    background-color: RGBA(245, 245, 247, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-success {
    color: #0b0f19 !important;
    background-color: RGBA(23, 241, 210, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-info {
    color: #0b0f19 !important;
    background-color: RGBA(161, 161, 166, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-warning {
    color: #0b0f19 !important;
    background-color: RGBA(255, 208, 115, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-danger {
    color: #0b0f19 !important;
    background-color: RGBA(255, 79, 0, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-light {
    color: #0b0f19 !important;
    background-color: RGBA(243, 244, 245, var(--si-bg-opacity, 1)) !important;
  }

  .text-bg-dark {
    color: #ffffff !important;
    background-color: RGBA(18, 21, 25, var(--si-bg-opacity, 1)) !important;
  }

  .link-primary {
    color: #146ad3 !important;
  }

  .link-primary:focus,
  .link-primary:hover {
    color: #542bc4 !important;
  }

  .link-secondary {
    color: #f5f5f7 !important;
  }

  .link-secondary:focus,
  .link-secondary:hover {
    color: #f7f7f9 !important;
  }

  .link-success {
    color: #17f1d2 !important;
  }

  .link-success:focus,
  .link-success:hover {
    color: #45f4db !important;
  }

  .link-info {
    color: #a1a1a6 !important;
  }

  .link-info:focus,
  .link-info:hover {
    color: #b4b4b8 !important;
  }

  .link-warning {
    color: #ffd073 !important;
  }

  .link-warning:focus,
  .link-warning:hover {
    color: #ffd98f !important;
  }

  .link-danger {
    color: #ff4f00 !important;
  }

  .link-danger:focus,
  .link-danger:hover {
    color: #ff7233 !important;
  }

  .link-light {
    color: #f3f4f5 !important;
  }

  .link-light:focus,
  .link-light:hover {
    color: #f5f6f7 !important;
  }

  .link-dark {
    color: #000000 !important;
  }

  .link-dark:focus,
  .link-dark:hover {
    color: #0e1114 !important;
  }

  .ratio {
    position: relative;
    width: 100%;
  }

  .ratio:before {
    display: block;
    padding-top: var(--si-aspect-ratio);
    content: "";
  }

  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ratio-1x1 {
    --si-aspect-ratio: 100%;
  }

  .ratio-4x3 {
    --si-aspect-ratio: 75%;
  }

  .ratio-16x9 {
    --si-aspect-ratio: 56.25%;
  }

  .ratio-21x9 {
    --si-aspect-ratio: 42.8571428571%;
  }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }

  @media (min-width: 500px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }

    .sticky-sm-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }

  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }

    .sticky-md-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }

  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }

    .sticky-lg-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }

  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }

    .sticky-xl-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }

  .hstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .vstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .visually-hidden,
  .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vr {
    display: inline-block;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: 1;
  }

  .align-baseline {
    vertical-align: baseline !important;
  }

  .align-top {
    vertical-align: top !important;
  }

  .align-middle {
    vertical-align: middle !important;
  }

  .align-bottom {
    vertical-align: bottom !important;
  }

  .align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .align-text-top {
    vertical-align: text-top !important;
  }

  .float-start {
    float: left !important;
  }

  .float-end {
    float: right !important;
  }

  .float-none {
    float: none !important;
  }

  .opacity-8 {
    opacity: 0.08 !important;
  }

  .opacity-10 {
    opacity: 0.1 !important;
  }

  .opacity-15 {
    opacity: 0.15 !important;
  }

  .opacity-25 {
    opacity: 0.25 !important;
  }

  .opacity-35 {
    opacity: 0.35 !important;
  }

  .opacity-40 {
    opacity: 0.4 !important;
  }

  .opacity-50 {
    opacity: 0.5 !important;
  }

  .opacity-60 {
    opacity: 0.6 !important;
  }

  .opacity-70 {
    opacity: 0.7 !important;
  }

  .opacity-75 {
    opacity: 0.75 !important;
  }

  .opacity-80 {
    opacity: 0.8 !important;
  }

  .opacity-90 {
    opacity: 0.9 !important;
  }

  .opacity-100 {
    opacity: 1 !important;
  }

  .overflow-auto {
    overflow: auto !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .overflow-scroll {
    overflow: scroll !important;
  }

  .d-inline {
    display: inline !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .d-block {
    display: block !important;
  }

  .d-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-table {
    display: table !important;
  }

  .d-table-row {
    display: table-row !important;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-none {
    display: none !important;
  }

  .shadow {
    -webkit-box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03) !important;
    box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03) !important;
  }

  .shadow-sm {
    -webkit-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03) !important;
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03) !important;
  }

  .shadow-lg {
    -webkit-box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(18, 21, 25, 0.1),
      0 0.25rem 0.8125rem -0.125rem rgba(18, 21, 25, 0.06) !important;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(18, 21, 25, 0.1),
      0 0.25rem 0.8125rem -0.125rem rgba(18, 21, 25, 0.06) !important;
  }

  .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .shadow-primary {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.9) !important;
  }

  .shadow-secondary {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(18, 21, 25, 0.15) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(18, 21, 25, 0.15) !important;
  }

  .shadow-info {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(161, 161, 166, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(161, 161, 166, 0.9) !important;
  }

  .shadow-success {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(23, 241, 210, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(23, 241, 210, 0.9) !important;
  }

  .shadow-warning {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(255, 208, 115, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(255, 208, 115, 0.9) !important;
  }

  .shadow-danger {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(255, 79, 0, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(255, 79, 0, 0.9) !important;
  }

  .shadow-dark {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(18, 21, 25, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(18, 21, 25, 0.9) !important;
  }

  .position-static {
    position: static !important;
  }

  .position-relative {
    position: relative !important;
  }

  .position-absolute {
    position: absolute !important;
  }

  .position-fixed {
    position: fixed !important;
  }

  .position-sticky {
    position: sticky !important;
  }

  .top-0 {
    top: 0 !important;
  }

  .top-50 {
    top: 50% !important;
  }

  .top-100 {
    top: 100% !important;
  }

  .bottom-0 {
    bottom: 0 !important;
  }

  .bottom-50 {
    bottom: 50% !important;
  }

  .bottom-100 {
    bottom: 100% !important;
  }

  .start-0 {
    left: 0 !important;
  }

  .start-50 {
    left: 50% !important;
  }

  .start-100 {
    left: 100% !important;
  }

  .end-0 {
    right: 0 !important;
  }

  .end-50 {
    right: 50% !important;
  }

  .end-100 {
    right: 100% !important;
  }

  .translate-middle {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .border {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top {
    border-top: var(--si-border-width) var(--si-border-style)
      var(--si-border-color) !important;
  }

  .border-top-0 {
    border-top: 0 !important;
  }

  .border-end {
    border-right: var(--si-border-width) var(--si-border-style)
      var(--si-border-color) !important;
  }

  .border-end-0 {
    border-right: 0 !important;
  }

  .border-bottom {
    border-bottom: var(--si-border-width) var(--si-border-style)
      var(--si-border-color) !important;
  }

  .border-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-start {
    border-left: var(--si-border-width) var(--si-border-style)
      var(--si-border-color) !important;
  }

  .border-start-0 {
    border-left: 0 !important;
  }

  .border-primary {
    border-color: rgba(105, 54, 245, 0.35) !important;
  }

  .border-success {
    border-color: rgba(23, 241, 210, 0.35) !important;
  }

  .border-info {
    border-color: rgba(161, 161, 166, 0.35) !important;
  }

  .border-warning {
    border-color: rgba(255, 208, 115, 0.35) !important;
  }

  .border-danger {
    border-color: rgba(255, 79, 0, 0.35) !important;
  }

  .border-light {
    border-color: rgba(255, 255, 255, 0.14) !important;
  }

  .border-dark {
    border-color: rgba(18, 21, 25, 0.35) !important;
  }

  .border-white {
    border-color: rgba(255, 255, 255, 0.35) !important;
  }

  .border-1 {
    --si-border-width: 1px;
  }

  .border-2 {
    --si-border-width: 2px;
  }

  .border-3 {
    --si-border-width: 3px;
  }

  .border-4 {
    --si-border-width: 4px;
  }

  .border-5 {
    --si-border-width: 5px;
  }

  .border-opacity-10 {
    --si-border-opacity: 0.1;
  }

  .border-opacity-25 {
    --si-border-opacity: 0.25;
  }

  .border-opacity-50 {
    --si-border-opacity: 0.5;
  }

  .border-opacity-75 {
    --si-border-opacity: 0.75;
  }

  .border-opacity-100 {
    --si-border-opacity: 1;
  }

  .w-25 {
    width: 25% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-auto {
    width: auto !important;
  }

  .w-1 {
    width: 1px !important;
  }

  .w-2 {
    width: 2px !important;
  }

  .w-3 {
    width: 3px !important;
  }

  .w-33 {
    width: 33.33% !important;
  }

  .mw-100 {
    max-width: 100% !important;
  }

  .vw-100 {
    width: 100vw !important;
  }

  .min-vw-100 {
    min-width: 100vw !important;
  }

  .h-25 {
    height: 25% !important;
  }

  .h-50 {
    height: 50% !important;
  }

  .h-75 {
    height: 75% !important;
  }

  .h-100 {
    height: 100% !important;
  }

  .h-auto {
    height: auto !important;
  }

  .h-1 {
    height: 1px !important;
  }

  .mh-100 {
    max-height: 100% !important;
  }

  .vh-100 {
    height: 100vh !important;
  }

  .min-vh-100 {
    min-height: 100vh !important;
  }

  .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: 0.25rem !important;
  }

  .m-2 {
    margin: 0.5rem !important;
  }

  .m-3 {
    margin: 1rem !important;
  }

  .m-4 {
    margin: 1.5rem !important;
  }

  .m-5 {
    margin: 3rem !important;
  }

  .m-auto {
    margin: auto !important;
  }

  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 0.25rem !important;
  }

  .mt-2 {
    margin-top: 0.5rem !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
  }

  .mt-5 {
    margin-top: 3rem !important;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .me-0 {
    margin-right: 0 !important;
  }

  .me-1 {
    margin-right: 0.25rem !important;
  }

  .me-2 {
    margin-right: 0.5rem !important;
  }

  .me-3 {
    margin-right: 1rem !important;
  }

  .me-4 {
    margin-right: 1.5rem !important;
  }

  .me-5 {
    margin-right: 3rem !important;
  }

  .me-auto {
    margin-right: auto !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-3 {
    margin-bottom: 1rem !important;
  }

  .mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .mb-auto {
    margin-bottom: auto !important;
  }

  .ms-0 {
    margin-left: 0 !important;
  }

  .ms-1 {
    margin-left: 0.25rem !important;
  }

  .ms-2 {
    margin-left: 0.5rem !important;
  }

  .ms-3 {
    margin-left: 1rem !important;
  }

  .ms-4 {
    margin-left: 1.5rem !important;
  }

  .ms-5 {
    margin-left: 3rem !important;
  }

  .ms-auto {
    margin-left: auto !important;
  }

  .m-n1 {
    margin: -0.25rem !important;
  }

  .m-n2 {
    margin: -0.5rem !important;
  }

  .m-n3 {
    margin: -1rem !important;
  }

  .m-n4 {
    margin: -1.5rem !important;
  }

  .m-n5 {
    margin: -3rem !important;
  }

  .mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-n3 {
    margin-top: -1rem !important;
  }

  .mt-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-n5 {
    margin-top: -3rem !important;
  }

  .me-n1 {
    margin-right: -0.25rem !important;
  }

  .me-n2 {
    margin-right: -0.5rem !important;
  }

  .me-n3 {
    margin-right: -1rem !important;
  }

  .me-n4 {
    margin-right: -1.5rem !important;
  }

  .me-n5 {
    margin-right: -3rem !important;
  }

  .mb-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-n3 {
    margin-left: -1rem !important;
  }

  .ms-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-n5 {
    margin-left: -3rem !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 0.25rem !important;
  }

  .p-2 {
    padding: 0.5rem !important;
  }

  .p-3 {
    padding: 1rem !important;
  }

  .p-4 {
    padding: 1.5rem !important;
  }

  .p-5 {
    padding: 3rem !important;
  }

  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-5rem {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .pt-1 {
    padding-top: 0.25rem !important;
  }

  .pt-2 {
    padding-top: 0.5rem !important;
  }

  .pt-3 {
    padding-top: 1rem !important;
  }

  .pt-4 {
    padding-top: 1.5rem !important;
  }

  .pt-5 {
    padding-top: 3rem !important;
  }

  .pe-0 {
    padding-right: 0 !important;
  }

  .pe-1 {
    padding-right: 0.25rem !important;
  }

  .pe-2 {
    padding-right: 0.5rem !important;
  }

  .pe-3 {
    padding-right: 1rem !important;
  }

  .pe-4 {
    padding-right: 1.5rem !important;
  }

  .pe-5 {
    padding-right: 3rem !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-3 {
    padding-bottom: 1rem !important;
  }

  .pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-5 {
    padding-bottom: 3rem !important;
  }

  .ps-0 {
    padding-left: 0 !important;
  }

  .ps-1 {
    padding-left: 0.25rem !important;
  }

  .ps-2 {
    padding-left: 0.5rem !important;
  }

  .ps-3 {
    padding-left: 1rem !important;
  }

  .ps-4 {
    padding-left: 1.5rem !important;
  }

  .ps-5 {
    padding-left: 3rem !important;
  }

  .gap-0 {
    gap: 0 !important;
  }

  .gap-1 {
    gap: 0.25rem !important;
  }

  .gap-2 {
    gap: 0.5rem !important;
  }

  .gap-3 {
    gap: 1rem !important;
  }

  .gap-4 {
    gap: 1.5rem !important;
  }

  .gap-5 {
    gap: 3rem !important;
  }

  .fs-1 {
    font-size: calc(1.355rem + 1.26vw) !important;
  }

  .fs-2 {
    font-size: calc(1.309rem + 0.708vw) !important;
  }

  .fs-3 {
    font-size: calc(1.286rem + 0.432vw) !important;
  }

  .fs-4 {
    font-size: calc(1.263rem + 0.156vw) !important;
  }

  .fs-5 {
    font-size: 1.15rem !important;
  }

  .fs-6 {
    font-size: 0.92rem !important;
  }

  .fs-xl {
    font-size: 1.15rem !important;
  }

  .fs-lg {
    font-size: 1.035rem !important;
  }

  .fs-base {
    font-size: 0.92rem !important;
  }

  .fs-sm {
    font-size: 0.805rem !important;
  }

  .fs-xs {
    font-size: 0.69rem !important;
  }

  .fst-italic {
    font-style: italic !important;
  }

  .fst-normal {
    font-style: normal !important;
  }

  .fw-light {
    font-weight: 300 !important;
  }

  .fw-lighter {
    font-weight: lighter !important;
  }

  .fw-normal {
    font-weight: 400 !important;
  }

  .fw-bold {
    font-weight: 800 !important;
  }

  .fw-semibold {
    font-weight: 600 !important;
  }

  .fw-bolder {
    font-weight: bolder !important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fw-medium {
    font-weight: 500 !important;
  }

  .lh-1 {
    line-height: 1 !important;
  }

  .lh-sm {
    line-height: 1.4 !important;
  }

  .lh-base {
    line-height: 1.5 !important;
  }

  .lh-lg {
    line-height: 1.6 !important;
  }

  .text-start {
    text-align: left !important;
  }

  .text-end {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .text-decoration-line-through {
    text-decoration: line-through !important;
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-capitalize {
    text-transform: capitalize !important;
  }

  .text-wrap {
    white-space: normal !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  .text-primary {
    color: #146ad3 !important;
  }

  .text-secondary {
    color: #f5f5f7 !important;
  }

  .text-success {
    color: #17f1d2 !important;
  }

  .text-info {
    color: #a1a1a6 !important;
  }

  .text-warning {
    color: #ff8400 !important;
  }

  .text-danger {
    color: #ff4f00 !important;
  }

  .text-light {
    color: #f3f4f5 !important;
  }

  .text-dark {
    color: #000000 !important;
  }

  .text-white {
    color: #ffffff !important;
  }

  .text-body {
    color: #252a44 !important;
  }

  .text-muted {
    color: rgba(67, 74, 87, 0.5) !important;
  }

  .text-nav {
    color: #252a44 !important;
  }

  .text-border {
    color: #f5f5f7 !important;
  }

  .text-reset {
    color: inherit !important;
  }

  .text-opacity-25 {
    --si-text-opacity: 0.25;
  }

  .text-opacity-50 {
    --si-text-opacity: 0.5;
  }

  .text-opacity-75 {
    --si-text-opacity: 0.75;
  }

  .text-opacity-100 {
    --si-text-opacity: 1;
  }

  .bg-primary {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-primary-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-secondary {
    --si-bg-opacity: 1;
    background-color: #f3f4f5 !important;
  }

  .bg-success {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-success-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-info {
    --si-bg-opacity: 1;
    background-color: rgba(var(--si-info-rgb), var(--si-bg-opacity)) !important;
  }

  .bg-warning {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-warning-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-danger {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-danger-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-light {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-light-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-dark {
    --si-bg-opacity: 1;
    background-color: #000000 !important;
  }

  .bg-black {
    --si-bg-opacity: 1;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgb(9 9 121 / 84%) 35%,
      rgb(0 212 255 / 10%) 100%
    );
  }

  .bg-gradiant1 {
    --si-bg-opacity: 1;
    background: linear-gradient(
      90deg,
      rgb(0 0 0 / 79%) 0%,
      #4f4f85 35%,
      #67a6a1 100%
    );
  }

  .bg-white {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-white-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-body {
    --si-bg-opacity: 1;
    background-color: rgba(
      var(--si-body-bg-rgb),
      var(--si-bg-opacity)
    ) !important;
  }

  .bg-transparent {
    --si-bg-opacity: 1;
    background-color: transparent !important;
  }

  .bg-none {
    --si-bg-opacity: 1;
    background-color: transparent !important;
  }

  .bg-opacity-10 {
    --si-bg-opacity: 0.1;
  }

  .bg-opacity-25 {
    --si-bg-opacity: 0.25;
  }

  .bg-opacity-50 {
    --si-bg-opacity: 0.5;
  }

  .bg-opacity-75 {
    --si-bg-opacity: 0.75;
  }

  .bg-opacity-100 {
    --si-bg-opacity: 1;
  }

  .bg-gradient {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0.18)),
      to(rgba(255, 255, 255, 0))
    ) !important;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.18),
      rgba(255, 255, 255, 0)
    ) !important;
  }

  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }

  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }

  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .pe-none {
    pointer-events: none !important;
  }

  .pe-auto {
    pointer-events: auto !important;
  }

  .rounded {
    border-radius: var(--si-border-radius) !important;
  }

  .rounded-0 {
    border-radius: 0 !important;
  }

  .rounded-1 {
    border-radius: var(--si-border-radius-sm) !important;
  }

  .rounded-2 {
    border-radius: var(--si-border-radius) !important;
  }

  .rounded-3 {
    border-radius: var(--si-border-radius-lg) !important;
  }

  .rounded-4 {
    border-radius: var(--si-border-radius-xl) !important;
  }

  .rounded-5 {
    border-radius: var(--si-border-radius-2xl) !important;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .rounded-pill {
    border-radius: var(--si-border-radius-pill) !important;
  }

  .rounded-top {
    border-top-left-radius: var(--si-border-radius) !important;
    border-top-right-radius: var(--si-border-radius) !important;
  }

  .rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-end {
    border-top-right-radius: var(--si-border-radius) !important;
    border-bottom-right-radius: var(--si-border-radius) !important;
  }

  .rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom {
    border-bottom-right-radius: var(--si-border-radius) !important;
    border-bottom-left-radius: var(--si-border-radius) !important;
  }

  .rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start {
    border-bottom-left-radius: var(--si-border-radius) !important;
    border-top-left-radius: var(--si-border-radius) !important;
  }

  .rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .visible {
    visibility: visible !important;
  }

  .invisible {
    visibility: hidden !important;
  }

  .bg-faded-primary {
    background-color: rgba(105, 54, 245, 0.12) !important;
  }

  .bg-faded-success {
    background-color: rgba(23, 241, 210, 0.12) !important;
  }

  .bg-faded-info {
    background-color: rgba(161, 161, 166, 0.12) !important;
  }

  .bg-faded-warning {
    background-color: rgba(255, 208, 115, 0.12) !important;
  }

  .bg-faded-danger {
    background-color: rgba(255, 79, 0, 0.12) !important;
  }

  .bg-faded-light {
    background-color: rgba(243, 244, 245, 0.05) !important;
  }

  .bg-faded-dark {
    background-color: rgba(18, 21, 25, 0.12) !important;
  }

  .bg-size-cover {
    background-size: cover !important;
  }

  .bg-size-contain {
    background-size: contain !important;
  }

  .bg-position-center {
    background-position: center !important;
  }

  .bg-position-center-y {
    background-position: left center !important;
  }

  .bg-position-center-x {
    background-position: center top !important;
  }

  .bg-position-top-end {
    background-position: top right !important;
  }

  .bg-position-top-start {
    background-position: top left !important;
  }

  .bg-position-top-center {
    background-position: top center !important;
  }

  .bg-position-center-end {
    background-position: center right !important;
  }

  .bg-position-center-start {
    background-position: center left !important;
  }

  .bg-position-center-bottom {
    background-position: center bottom !important;
  }

  .bg-position-bottom-end {
    background-position: bottom right !important;
  }

  .bg-position-bottom-start {
    background-position: bottom left !important;
  }

  .bg-position-bottom-center {
    background-position: bottom center !important;
  }

  .bg-repeat-0 {
    background-repeat: no-repeat !important;
  }

  .bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .bg-attachment-fixed {
    background-attachment: fixed !important;
  }

  .bg-gradient-primary {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#146ad3),
      color-stop(50%, #8b5cf6),
      to(#d946ef)
    ) !important;
    background: linear-gradient(
      90deg,
      #146ad3 0%,
      #8b5cf6 50%,
      #d946ef 100%
    ) !important;
  }

  .bg-gradient-primary-translucent {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(105, 54, 245, 0)),
      color-stop(120%, rgba(105, 54, 245, 0.6))
    ) !important;
    background: linear-gradient(
      180deg,
      rgba(105, 54, 245, 0) 0%,
      rgba(105, 54, 245, 0.6) 120%
    ) !important;
  }

  .bg-gradient-dark-translucent {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(18, 21, 25, 0.58)),
      to(rgba(18, 21, 25, 0.78))
    ) !important;
    background: linear-gradient(
      180deg,
      rgba(18, 21, 25, 0.58) 0%,
      rgba(18, 21, 25, 0.78) 100%
    ) !important;
  }

  .bg-image-none {
    background-image: none !important;
  }

  .text-shadow {
    text-shadow: 0 0.0625rem #000000 !important;
  }

  .table-layout-fixed {
    table-layout: fixed !important;
  }

  .zindex-1 {
    z-index: 1 !important;
  }

  .zindex-2 {
    z-index: 2 !important;
  }

  .zindex-3 {
    z-index: 3 !important;
  }

  .zindex-4 {
    z-index: 4 !important;
  }

  .zindex-5 {
    z-index: 5 !important;
  }

  @media (min-width: 500px) {
    .float-sm-start {
      float: left !important;
    }

    .float-sm-end {
      float: right !important;
    }

    .float-sm-none {
      float: none !important;
    }

    .d-sm-inline {
      display: inline !important;
    }

    .d-sm-inline-block {
      display: inline-block !important;
    }

    .d-sm-block {
      display: block !important;
    }

    .d-sm-grid {
      display: -ms-grid !important;
      display: grid !important;
    }

    .d-sm-table {
      display: table !important;
    }

    .d-sm-table-row {
      display: table-row !important;
    }

    .d-sm-table-cell {
      display: table-cell !important;
    }

    .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .d-sm-none {
      display: none !important;
    }

    .position-sm-static {
      position: static !important;
    }

    .position-sm-relative {
      position: relative !important;
    }

    .position-sm-absolute {
      position: absolute !important;
    }

    .position-sm-fixed {
      position: fixed !important;
    }

    .position-sm-sticky {
      position: sticky !important;
    }

    .translate-middle-sm {
      -webkit-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
    }

    .translate-middle-sm-x {
      -webkit-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }

    .translate-middle-sm-y {
      -webkit-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }

    .border-sm {
      border: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-sm-0 {
      border: 0 !important;
    }

    .border-top-sm {
      border-top: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-top-sm-0 {
      border-top: 0 !important;
    }

    .border-end-sm {
      border-right: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-end-sm-0 {
      border-right: 0 !important;
    }

    .border-bottom-sm {
      border-bottom: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-bottom-sm-0 {
      border-bottom: 0 !important;
    }

    .border-start-sm {
      border-left: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-start-sm-0 {
      border-left: 0 !important;
    }

    .w-sm-25 {
      width: 25% !important;
    }

    .w-sm-50 {
      width: 50% !important;
    }

    .w-sm-75 {
      width: 75% !important;
    }

    .w-sm-100 {
      width: 100% !important;
    }

    .w-sm-auto {
      width: auto !important;
    }

    .w-sm-1 {
      width: 1px !important;
    }

    .w-sm-2 {
      width: 2px !important;
    }

    .w-sm-3 {
      width: 3px !important;
    }

    .w-sm-33 {
      width: 33.33% !important;
    }

    .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }

    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }

    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }

    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }

    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }

    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }

    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }

    .justify-content-sm-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }

    .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }

    .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }

    .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }

    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }

    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }

    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }

    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }

    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }

    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }

    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }

    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }

    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }

    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }

    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }

    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }

    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }

    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }

    .order-sm-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }

    .order-sm-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }

    .order-sm-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }

    .order-sm-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }

    .order-sm-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }

    .order-sm-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }

    .order-sm-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }

    .order-sm-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }

    .m-sm-0 {
      margin: 0 !important;
    }

    .m-sm-1 {
      margin: 0.25rem !important;
    }

    .m-sm-2 {
      margin: 0.5rem !important;
    }

    .m-sm-3 {
      margin: 1rem !important;
    }

    .m-sm-4 {
      margin: 1.5rem !important;
    }

    .m-sm-5 {
      margin: 3rem !important;
    }

    .m-sm-auto {
      margin: auto !important;
    }

    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }

    .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }

    .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .mx-sm-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }

    .mx-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }

    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }

    .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .my-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }

    .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }

    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mt-sm-0 {
      margin-top: 0 !important;
    }

    .mt-sm-1 {
      margin-top: 0.25rem !important;
    }

    .mt-sm-2 {
      margin-top: 0.5rem !important;
    }

    .mt-sm-3 {
      margin-top: 1rem !important;
    }

    .mt-sm-4 {
      margin-top: 1.5rem !important;
    }

    .mt-sm-5 {
      margin-top: 3rem !important;
    }

    .mt-sm-auto {
      margin-top: auto !important;
    }

    .me-sm-0 {
      margin-right: 0 !important;
    }

    .me-sm-1 {
      margin-right: 0.25rem !important;
    }

    .me-sm-2 {
      margin-right: 0.5rem !important;
    }

    .me-sm-3 {
      margin-right: 1rem !important;
    }

    .me-sm-4 {
      margin-right: 1.5rem !important;
    }

    .me-sm-5 {
      margin-right: 3rem !important;
    }

    .me-sm-auto {
      margin-right: auto !important;
    }

    .mb-sm-0 {
      margin-bottom: 0 !important;
    }

    .mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }

    .mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }

    .mb-sm-3 {
      margin-bottom: 1rem !important;
    }

    .mb-sm-4 {
      margin-bottom: 1.5rem !important;
    }

    .mb-sm-5 {
      margin-bottom: 3rem !important;
    }

    .mb-sm-auto {
      margin-bottom: auto !important;
    }

    .ms-sm-0 {
      margin-left: 0 !important;
    }

    .ms-sm-1 {
      margin-left: 0.25rem !important;
    }

    .ms-sm-2 {
      margin-left: 0.5rem !important;
    }

    .ms-sm-3 {
      margin-left: 1rem !important;
    }

    .ms-sm-4 {
      margin-left: 1.5rem !important;
    }

    .ms-sm-5 {
      margin-left: 3rem !important;
    }

    .ms-sm-auto {
      margin-left: auto !important;
    }

    .m-sm-n1 {
      margin: -0.25rem !important;
    }

    .m-sm-n2 {
      margin: -0.5rem !important;
    }

    .m-sm-n3 {
      margin: -1rem !important;
    }

    .m-sm-n4 {
      margin: -1.5rem !important;
    }

    .m-sm-n5 {
      margin: -3rem !important;
    }

    .mx-sm-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }

    .mx-sm-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }

    .mx-sm-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }

    .mx-sm-n4 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }

    .mx-sm-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }

    .my-sm-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }

    .my-sm-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }

    .my-sm-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }

    .my-sm-n4 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }

    .my-sm-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }

    .mt-sm-n1 {
      margin-top: -0.25rem !important;
    }

    .mt-sm-n2 {
      margin-top: -0.5rem !important;
    }

    .mt-sm-n3 {
      margin-top: -1rem !important;
    }

    .mt-sm-n4 {
      margin-top: -1.5rem !important;
    }

    .mt-sm-n5 {
      margin-top: -3rem !important;
    }

    .me-sm-n1 {
      margin-right: -0.25rem !important;
    }

    .me-sm-n2 {
      margin-right: -0.5rem !important;
    }

    .me-sm-n3 {
      margin-right: -1rem !important;
    }

    .me-sm-n4 {
      margin-right: -1.5rem !important;
    }

    .me-sm-n5 {
      margin-right: -3rem !important;
    }

    .mb-sm-n1 {
      margin-bottom: -0.25rem !important;
    }

    .mb-sm-n2 {
      margin-bottom: -0.5rem !important;
    }

    .mb-sm-n3 {
      margin-bottom: -1rem !important;
    }

    .mb-sm-n4 {
      margin-bottom: -1.5rem !important;
    }

    .mb-sm-n5 {
      margin-bottom: -3rem !important;
    }

    .ms-sm-n1 {
      margin-left: -0.25rem !important;
    }

    .ms-sm-n2 {
      margin-left: -0.5rem !important;
    }

    .ms-sm-n3 {
      margin-left: -1rem !important;
    }

    .ms-sm-n4 {
      margin-left: -1.5rem !important;
    }

    .ms-sm-n5 {
      margin-left: -3rem !important;
    }

    .p-sm-0 {
      padding: 0 !important;
    }

    .p-sm-1 {
      padding: 0.25rem !important;
    }

    .p-sm-2 {
      padding: 0.5rem !important;
    }

    .p-sm-3 {
      padding: 1rem !important;
    }

    .p-sm-4 {
      padding: 1.5rem !important;
    }

    .p-sm-5 {
      padding: 3rem !important;
    }

    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }

    .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .px-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }

    .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }

    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .py-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }

    .pt-sm-0 {
      padding-top: 0 !important;
    }

    .pt-sm-1 {
      padding-top: 0.25rem !important;
    }

    .pt-sm-2 {
      padding-top: 0.5rem !important;
    }

    .pt-sm-3 {
      padding-top: 1rem !important;
    }

    .pt-sm-4 {
      padding-top: 1.5rem !important;
    }

    .pt-sm-5 {
      padding-top: 3rem !important;
    }

    .pe-sm-0 {
      padding-right: 0 !important;
    }

    .pe-sm-1 {
      padding-right: 0.25rem !important;
    }

    .pe-sm-2 {
      padding-right: 0.5rem !important;
    }

    .pe-sm-3 {
      padding-right: 1rem !important;
    }

    .pe-sm-4 {
      padding-right: 1.5rem !important;
    }

    .pe-sm-5 {
      padding-right: 3rem !important;
    }

    .pb-sm-0 {
      padding-bottom: 0 !important;
    }

    .pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }

    .pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }

    .pb-sm-3 {
      padding-bottom: 1rem !important;
    }

    .pb-sm-4 {
      padding-bottom: 1.5rem !important;
    }

    .pb-sm-5 {
      padding-bottom: 3rem !important;
    }

    .ps-sm-0 {
      padding-left: 0 !important;
    }

    .ps-sm-1 {
      padding-left: 0.25rem !important;
    }

    .ps-sm-2 {
      padding-left: 0.5rem !important;
    }

    .ps-sm-3 {
      padding-left: 1rem !important;
    }

    .ps-sm-4 {
      padding-left: 1.5rem !important;
    }

    .ps-sm-5 {
      padding-left: 3rem !important;
    }

    .gap-sm-0 {
      gap: 0 !important;
    }

    .gap-sm-1 {
      gap: 0.25rem !important;
    }

    .gap-sm-2 {
      gap: 0.5rem !important;
    }

    .gap-sm-3 {
      gap: 1rem !important;
    }

    .gap-sm-4 {
      gap: 1.5rem !important;
    }

    .gap-sm-5 {
      gap: 3rem !important;
    }

    .text-sm-start {
      text-align: left !important;
    }

    .text-sm-end {
      text-align: right !important;
    }

    .text-sm-center {
      text-align: center !important;
    }
  }

  @media (min-width: 768px) {
    .float-md-start {
      float: left !important;
    }

    .float-md-end {
      float: right !important;
    }

    .float-md-none {
      float: none !important;
    }

    .d-md-inline {
      display: inline !important;
    }

    .d-md-inline-block {
      display: inline-block !important;
    }

    .d-md-block {
      display: block !important;
    }

    .d-md-grid {
      display: -ms-grid !important;
      display: grid !important;
    }

    .d-md-table {
      display: table !important;
    }

    .d-md-table-row {
      display: table-row !important;
    }

    .d-md-table-cell {
      display: table-cell !important;
    }

    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .d-md-none {
      display: none !important;
    }

    .position-md-static {
      position: static !important;
    }

    .position-md-relative {
      position: relative !important;
    }

    .position-md-absolute {
      position: absolute !important;
    }

    .position-md-fixed {
      position: fixed !important;
    }

    .position-md-sticky {
      position: sticky !important;
    }

    .translate-middle-md {
      -webkit-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
    }

    .translate-middle-md-x {
      -webkit-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }

    .translate-middle-md-y {
      -webkit-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }

    .border-md {
      border: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-md-0 {
      border: 0 !important;
    }

    .border-top-md {
      border-top: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-top-md-0 {
      border-top: 0 !important;
    }

    .border-end-md {
      border-right: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-end-md-0 {
      border-right: 0 !important;
    }

    .border-bottom-md {
      border-bottom: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-bottom-md-0 {
      border-bottom: 0 !important;
    }

    .border-start-md {
      border-left: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-start-md-0 {
      border-left: 0 !important;
    }

    .w-md-25 {
      width: 25% !important;
    }

    .w-md-50 {
      width: 50% !important;
    }

    .w-md-75 {
      width: 75% !important;
    }

    .w-md-100 {
      width: 100% !important;
    }

    .w-md-auto {
      width: auto !important;
    }

    .w-md-1 {
      width: 1px !important;
    }

    .w-md-2 {
      width: 2px !important;
    }

    .w-md-3 {
      width: 3px !important;
    }

    .w-md-33 {
      width: 33.33% !important;
    }

    .flex-md-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }

    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }

    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }

    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }

    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }

    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }

    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }

    .justify-content-md-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }

    .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }

    .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }

    .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }

    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }

    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }

    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }

    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }

    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }

    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }

    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }

    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }

    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }

    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }

    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }

    .align-self-md-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }

    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }

    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }

    .order-md-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }

    .order-md-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }

    .order-md-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }

    .order-md-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }

    .order-md-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }

    .order-md-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }

    .order-md-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }

    .order-md-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }

    .m-md-0 {
      margin: 0 !important;
    }

    .m-md-1 {
      margin: 0.25rem !important;
    }

    .m-md-2 {
      margin: 0.5rem !important;
    }

    .m-md-3 {
      margin: 1rem !important;
    }

    .m-md-4 {
      margin: 1.5rem !important;
    }

    .m-md-5 {
      margin: 3rem !important;
    }

    .m-md-auto {
      margin: auto !important;
    }

    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }

    .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }

    .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .mx-md-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }

    .mx-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }

    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }

    .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .my-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }

    .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }

    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mt-md-0 {
      margin-top: 0 !important;
    }

    .mt-md-1 {
      margin-top: 0.25rem !important;
    }

    .mt-md-2 {
      margin-top: 0.5rem !important;
    }

    .mt-md-3 {
      margin-top: 1rem !important;
    }

    .mt-md-4 {
      margin-top: 1.5rem !important;
    }

    .mt-md-5 {
      margin-top: 3rem !important;
    }

    .mt-md-auto {
      margin-top: auto !important;
    }

    .me-md-0 {
      margin-right: 0 !important;
    }

    .me-md-1 {
      margin-right: 0.25rem !important;
    }

    .me-md-2 {
      margin-right: 0.5rem !important;
    }

    .me-md-3 {
      margin-right: 1rem !important;
    }

    .me-md-4 {
      margin-right: 1.5rem !important;
    }

    .me-md-5 {
      margin-right: 3rem !important;
    }

    .me-md-auto {
      margin-right: auto !important;
    }

    .mb-md-0 {
      margin-bottom: 0 !important;
    }

    .mb-md-1 {
      margin-bottom: 0.25rem !important;
    }

    .mb-md-2 {
      margin-bottom: 0.5rem !important;
    }

    .mb-md-3 {
      margin-bottom: 1rem !important;
    }

    .mb-md-4 {
      margin-bottom: 1.5rem !important;
    }

    .mb-md-5 {
      margin-bottom: 3rem !important;
    }

    .mb-md-auto {
      margin-bottom: auto !important;
    }

    .ms-md-0 {
      margin-left: 0 !important;
    }

    .ms-md-1 {
      margin-left: 0.25rem !important;
    }

    .ms-md-2 {
      margin-left: 0.5rem !important;
    }

    .ms-md-3 {
      margin-left: 1rem !important;
    }

    .ms-md-4 {
      margin-left: 1.5rem !important;
    }

    .ms-md-5 {
      margin-left: 3rem !important;
    }

    .ms-md-auto {
      margin-left: auto !important;
    }

    .m-md-n1 {
      margin: -0.25rem !important;
    }

    .m-md-n2 {
      margin: -0.5rem !important;
    }

    .m-md-n3 {
      margin: -1rem !important;
    }

    .m-md-n4 {
      margin: -1.5rem !important;
    }

    .m-md-n5 {
      margin: -3rem !important;
    }

    .mx-md-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }

    .mx-md-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }

    .mx-md-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }

    .mx-md-n4 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }

    .mx-md-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }

    .my-md-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }

    .my-md-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }

    .my-md-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }

    .my-md-n4 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }

    .my-md-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }

    .mt-md-n1 {
      margin-top: -0.25rem !important;
    }

    .mt-md-n2 {
      margin-top: -0.5rem !important;
    }

    .mt-md-n3 {
      margin-top: -1rem !important;
    }

    .mt-md-n4 {
      margin-top: -1.5rem !important;
    }

    .mt-md-n5 {
      margin-top: -3rem !important;
    }

    .me-md-n1 {
      margin-right: -0.25rem !important;
    }

    .me-md-n2 {
      margin-right: -0.5rem !important;
    }

    .me-md-n3 {
      margin-right: -1rem !important;
    }

    .me-md-n4 {
      margin-right: -1.5rem !important;
    }

    .me-md-n5 {
      margin-right: -3rem !important;
    }

    .mb-md-n1 {
      margin-bottom: -0.25rem !important;
    }

    .mb-md-n2 {
      margin-bottom: -0.5rem !important;
    }

    .mb-md-n3 {
      margin-bottom: -1rem !important;
    }

    .mb-md-n4 {
      margin-bottom: -1.5rem !important;
    }

    .mb-md-n5 {
      margin-bottom: -3rem !important;
    }

    .ms-md-n1 {
      margin-left: -0.25rem !important;
    }

    .ms-md-n2 {
      margin-left: -0.5rem !important;
    }

    .ms-md-n3 {
      margin-left: -1rem !important;
    }

    .ms-md-n4 {
      margin-left: -1.5rem !important;
    }

    .ms-md-n5 {
      margin-left: -3rem !important;
    }

    .p-md-0 {
      padding: 0 !important;
    }

    .p-md-1 {
      padding: 0.25rem !important;
    }

    .p-md-2 {
      padding: 0.5rem !important;
    }

    .p-md-3 {
      padding: 1rem !important;
    }

    .p-md-4 {
      padding: 1.5rem !important;
    }

    .p-md-5 {
      padding: 3rem !important;
    }

    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }

    .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .px-md-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }

    .px-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }

    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .py-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }

    .pt-md-0 {
      padding-top: 0 !important;
    }

    .pt-md-1 {
      padding-top: 0.25rem !important;
    }

    .pt-md-2 {
      padding-top: 0.5rem !important;
    }

    .pt-md-3 {
      padding-top: 1rem !important;
    }

    .pt-md-4 {
      padding-top: 1.5rem !important;
    }

    .pt-md-5 {
      padding-top: 3rem !important;
    }

    .pe-md-0 {
      padding-right: 0 !important;
    }

    .pe-md-1 {
      padding-right: 0.25rem !important;
    }

    .pe-md-2 {
      padding-right: 0.5rem !important;
    }

    .pe-md-3 {
      padding-right: 1rem !important;
    }

    .pe-md-4 {
      padding-right: 1.5rem !important;
    }

    .pe-md-5 {
      padding-right: 3rem !important;
    }

    .pb-md-0 {
      padding-bottom: 0 !important;
    }

    .pb-md-1 {
      padding-bottom: 0.25rem !important;
    }

    .pb-md-2 {
      padding-bottom: 0.5rem !important;
    }

    .pb-md-3 {
      padding-bottom: 1rem !important;
    }

    .pb-md-4 {
      padding-bottom: 1.5rem !important;
    }

    .pb-md-5 {
      padding-bottom: 3rem !important;
    }

    .ps-md-0 {
      padding-left: 0 !important;
    }

    .ps-md-1 {
      padding-left: 0.25rem !important;
    }

    .ps-md-2 {
      padding-left: 0.5rem !important;
    }

    .ps-md-3 {
      padding-left: 1rem !important;
    }

    .ps-md-4 {
      padding-left: 1.5rem !important;
    }

    .ps-md-5 {
      padding-left: 3rem !important;
    }

    .gap-md-0 {
      gap: 0 !important;
    }

    .gap-md-1 {
      gap: 0.25rem !important;
    }

    .gap-md-2 {
      gap: 0.5rem !important;
    }

    .gap-md-3 {
      gap: 1rem !important;
    }

    .gap-md-4 {
      gap: 1.5rem !important;
    }

    .gap-md-5 {
      gap: 3rem !important;
    }

    .text-md-start {
      text-align: left !important;
    }

    .text-md-end {
      text-align: right !important;
    }

    .text-md-center {
      text-align: center !important;
    }
  }

  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important;
    }

    .float-lg-end {
      float: right !important;
    }

    .float-lg-none {
      float: none !important;
    }

    .d-lg-inline {
      display: inline !important;
    }

    .d-lg-inline-block {
      display: inline-block !important;
    }

    .d-lg-block {
      display: block !important;
    }

    .d-lg-grid {
      display: -ms-grid !important;
      display: grid !important;
    }

    .d-lg-table {
      display: table !important;
    }

    .d-lg-table-row {
      display: table-row !important;
    }

    .d-lg-table-cell {
      display: table-cell !important;
    }

    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .d-lg-none {
      display: none !important;
    }

    .position-lg-static {
      position: static !important;
    }

    .position-lg-relative {
      position: relative !important;
    }

    .position-lg-absolute {
      position: absolute !important;
    }

    .position-lg-fixed {
      position: fixed !important;
    }

    .position-lg-sticky {
      position: sticky !important;
    }

    .translate-middle-lg {
      -webkit-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
    }

    .translate-middle-lg-x {
      -webkit-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }

    .translate-middle-lg-y {
      -webkit-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }

    .border-lg {
      border: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-lg-0 {
      border: 0 !important;
    }

    .border-top-lg {
      border-top: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-top-lg-0 {
      border-top: 0 !important;
    }

    .border-end-lg {
      border-right: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-end-lg-0 {
      border-right: 0 !important;
    }

    .border-bottom-lg {
      border-bottom: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-bottom-lg-0 {
      border-bottom: 0 !important;
    }

    .border-start-lg {
      border-left: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-start-lg-0 {
      border-left: 0 !important;
    }

    .w-lg-25 {
      width: 25% !important;
    }

    .w-lg-50 {
      width: 50% !important;
    }

    .w-lg-75 {
      width: 75% !important;
    }

    .w-lg-100 {
      width: 100% !important;
    }

    .w-lg-auto {
      width: auto !important;
    }

    .w-lg-1 {
      width: 1px !important;
    }

    .w-lg-2 {
      width: 2px !important;
    }

    .w-lg-3 {
      width: 3px !important;
    }

    .w-lg-33 {
      width: 33.33% !important;
    }

    .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }

    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }

    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }

    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }

    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }

    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }

    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }

    .justify-content-lg-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }

    .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }

    .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }

    .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }

    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }

    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }

    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }

    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }

    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }

    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }

    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }

    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }

    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }

    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }

    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }

    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }

    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }

    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }

    .order-lg-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }

    .order-lg-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }

    .order-lg-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }

    .order-lg-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }

    .order-lg-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }

    .order-lg-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }

    .order-lg-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }

    .order-lg-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }

    .m-lg-0 {
      margin: 0 !important;
    }

    .m-lg-1 {
      margin: 0.25rem !important;
    }

    .m-lg-2 {
      margin: 0.5rem !important;
    }

    .m-lg-3 {
      margin: 1rem !important;
    }

    .m-lg-4 {
      margin: 1.5rem !important;
    }

    .m-lg-5 {
      margin: 3rem !important;
    }

    .m-lg-auto {
      margin: auto !important;
    }

    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }

    .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }

    .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .mx-lg-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }

    .mx-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }

    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }

    .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .my-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }

    .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }

    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mt-lg-0 {
      margin-top: 0 !important;
    }

    .mt-lg-1 {
      margin-top: 0.25rem !important;
    }

    .mt-lg-2 {
      margin-top: 0.5rem !important;
    }

    .mt-lg-3 {
      margin-top: 1rem !important;
    }

    .mt-lg-4 {
      margin-top: 1.5rem !important;
    }

    .mt-lg-5 {
      margin-top: 3rem !important;
    }

    .mt-lg-auto {
      margin-top: auto !important;
    }

    .me-lg-0 {
      margin-right: 0 !important;
    }

    .me-lg-1 {
      margin-right: 0.25rem !important;
    }

    .me-lg-2 {
      margin-right: 0.5rem !important;
    }

    .me-lg-3 {
      margin-right: 1rem !important;
    }

    .me-lg-4 {
      margin-right: 1.5rem !important;
    }

    .me-lg-5 {
      margin-right: 3rem !important;
    }

    .me-lg-auto {
      margin-right: auto !important;
    }

    .mb-lg-0 {
      margin-bottom: 0 !important;
    }

    .mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }

    .mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }

    .mb-lg-3 {
      margin-bottom: 1rem !important;
    }

    .mb-lg-4 {
      margin-bottom: 1.5rem !important;
    }

    .mb-lg-5 {
      margin-bottom: 3rem !important;
    }

    .mb-lg-auto {
      margin-bottom: auto !important;
    }

    .ms-lg-0 {
      margin-left: 0 !important;
    }

    .ms-lg-1 {
      margin-left: 0.25rem !important;
    }

    .ms-lg-2 {
      margin-left: 0.5rem !important;
    }

    .ms-lg-3 {
      margin-left: 1rem !important;
    }

    .ms-lg-4 {
      margin-left: 1.5rem !important;
    }

    .ms-lg-5 {
      margin-left: 3rem !important;
    }

    .ms-lg-auto {
      margin-left: auto !important;
    }

    .m-lg-n1 {
      margin: -0.25rem !important;
    }

    .m-lg-n2 {
      margin: -0.5rem !important;
    }

    .m-lg-n3 {
      margin: -1rem !important;
    }

    .m-lg-n4 {
      margin: -1.5rem !important;
    }

    .m-lg-n5 {
      margin: -3rem !important;
    }

    .mx-lg-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }

    .mx-lg-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }

    .mx-lg-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }

    .mx-lg-n4 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }

    .mx-lg-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }

    .my-lg-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }

    .my-lg-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }

    .my-lg-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }

    .my-lg-n4 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }

    .my-lg-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }

    .mt-lg-n1 {
      margin-top: -0.25rem !important;
    }

    .mt-lg-n2 {
      margin-top: -0.5rem !important;
    }

    .mt-lg-n3 {
      margin-top: -1rem !important;
    }

    .mt-lg-n4 {
      margin-top: -1.5rem !important;
    }

    .mt-lg-n5 {
      margin-top: -3rem !important;
    }

    .me-lg-n1 {
      margin-right: -0.25rem !important;
    }

    .me-lg-n2 {
      margin-right: -0.5rem !important;
    }

    .me-lg-n3 {
      margin-right: -1rem !important;
    }

    .me-lg-n4 {
      margin-right: -1.5rem !important;
    }

    .me-lg-n5 {
      margin-right: -3rem !important;
    }

    .mb-lg-n1 {
      margin-bottom: -0.25rem !important;
    }

    .mb-lg-n2 {
      margin-bottom: -0.5rem !important;
    }

    .mb-lg-n3 {
      margin-bottom: -1rem !important;
    }

    .mb-lg-n4 {
      margin-bottom: -1.5rem !important;
    }

    .mb-lg-n5 {
      margin-bottom: -3rem !important;
    }

    .ms-lg-n1 {
      margin-left: -0.25rem !important;
    }

    .ms-lg-n2 {
      margin-left: -0.5rem !important;
    }

    .ms-lg-n3 {
      margin-left: -1rem !important;
    }

    .ms-lg-n4 {
      margin-left: -1.5rem !important;
    }

    .ms-lg-n5 {
      margin-left: -3rem !important;
    }

    .p-lg-0 {
      padding: 0 !important;
    }

    .p-lg-1 {
      padding: 0.25rem !important;
    }

    .p-lg-2 {
      padding: 0.5rem !important;
    }

    .p-lg-3 {
      padding: 1rem !important;
    }

    .p-lg-4 {
      padding: 1.5rem !important;
    }

    .p-lg-5 {
      padding: 3rem !important;
    }

    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }

    .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .px-lg-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }

    .px-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }

    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .py-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }

    .pt-lg-0 {
      padding-top: 0 !important;
    }

    .pt-lg-1 {
      padding-top: 0.25rem !important;
    }

    .pt-lg-2 {
      padding-top: 0.5rem !important;
    }

    .pt-lg-3 {
      padding-top: 1rem !important;
    }

    .pt-lg-4 {
      padding-top: 1.5rem !important;
    }

    .pt-lg-5 {
      padding-top: 3rem !important;
    }

    .pe-lg-0 {
      padding-right: 0 !important;
    }

    .pe-lg-1 {
      padding-right: 0.25rem !important;
    }

    .pe-lg-2 {
      padding-right: 0.5rem !important;
    }

    .pe-lg-3 {
      padding-right: 1rem !important;
    }

    .pe-lg-4 {
      padding-right: 1.5rem !important;
    }

    .pe-lg-5 {
      padding-right: 3rem !important;
    }

    .pb-lg-0 {
      padding-bottom: 0 !important;
    }

    .pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }

    .pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }

    .pb-lg-3 {
      padding-bottom: 1rem !important;
    }

    .pb-lg-4 {
      padding-bottom: 1.5rem !important;
    }

    .pb-lg-5 {
      padding-bottom: 3rem !important;
    }

    .ps-lg-0 {
      padding-left: 0 !important;
    }

    .ps-lg-1 {
      padding-left: 0.25rem !important;
    }

    .ps-lg-2 {
      padding-left: 0.5rem !important;
    }

    .ps-lg-3 {
      padding-left: 1rem !important;
    }

    .ps-lg-4 {
      padding-left: 1.5rem !important;
    }

    .ps-lg-5 {
      padding-left: 3rem !important;
    }

    .gap-lg-0 {
      gap: 0 !important;
    }

    .gap-lg-1 {
      gap: 0.25rem !important;
    }

    .gap-lg-2 {
      gap: 0.5rem !important;
    }

    .gap-lg-3 {
      gap: 1rem !important;
    }

    .gap-lg-4 {
      gap: 1.5rem !important;
    }

    .gap-lg-5 {
      gap: 3rem !important;
    }

    .text-lg-start {
      text-align: left !important;
    }

    .text-lg-end {
      text-align: right !important;
    }

    .text-lg-center {
      text-align: center !important;
    }
  }

  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important;
    }

    .float-xl-end {
      float: right !important;
    }

    .float-xl-none {
      float: none !important;
    }

    .d-xl-inline {
      display: inline !important;
    }

    .d-xl-inline-block {
      display: inline-block !important;
    }

    .d-xl-block {
      display: block !important;
    }

    .d-xl-grid {
      display: -ms-grid !important;
      display: grid !important;
    }

    .d-xl-table {
      display: table !important;
    }

    .d-xl-table-row {
      display: table-row !important;
    }

    .d-xl-table-cell {
      display: table-cell !important;
    }

    .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .d-xl-none {
      display: none !important;
    }

    .position-xl-static {
      position: static !important;
    }

    .position-xl-relative {
      position: relative !important;
    }

    .position-xl-absolute {
      position: absolute !important;
    }

    .position-xl-fixed {
      position: fixed !important;
    }

    .position-xl-sticky {
      position: sticky !important;
    }

    .translate-middle-xl {
      -webkit-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
    }

    .translate-middle-xl-x {
      -webkit-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }

    .translate-middle-xl-y {
      -webkit-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }

    .border-xl {
      border: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-xl-0 {
      border: 0 !important;
    }

    .border-top-xl {
      border-top: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-top-xl-0 {
      border-top: 0 !important;
    }

    .border-end-xl {
      border-right: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-end-xl-0 {
      border-right: 0 !important;
    }

    .border-bottom-xl {
      border-bottom: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-bottom-xl-0 {
      border-bottom: 0 !important;
    }

    .border-start-xl {
      border-left: var(--si-border-width) var(--si-border-style)
        var(--si-border-color) !important;
    }

    .border-start-xl-0 {
      border-left: 0 !important;
    }

    .w-xl-25 {
      width: 25% !important;
    }

    .w-xl-50 {
      width: 50% !important;
    }

    .w-xl-75 {
      width: 75% !important;
    }

    .w-xl-100 {
      width: 100% !important;
    }

    .w-xl-auto {
      width: auto !important;
    }

    .w-xl-1 {
      width: 1px !important;
    }

    .w-xl-2 {
      width: 2px !important;
    }

    .w-xl-3 {
      width: 3px !important;
    }

    .w-xl-33 {
      width: 33.33% !important;
    }

    .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }

    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }

    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }

    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }

    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }

    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }

    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }

    .justify-content-xl-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }

    .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }

    .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }

    .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }

    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }

    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }

    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }

    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }

    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }

    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }

    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }

    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }

    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
    }

    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }

    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }

    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
    }

    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }

    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
    }

    .order-xl-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }

    .order-xl-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }

    .order-xl-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }

    .order-xl-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }

    .order-xl-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }

    .order-xl-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }

    .order-xl-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }

    .order-xl-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }

    .m-xl-0 {
      margin: 0 !important;
    }

    .m-xl-1 {
      margin: 0.25rem !important;
    }

    .m-xl-2 {
      margin: 0.5rem !important;
    }

    .m-xl-3 {
      margin: 1rem !important;
    }

    .m-xl-4 {
      margin: 1.5rem !important;
    }

    .m-xl-5 {
      margin: 3rem !important;
    }

    .m-xl-auto {
      margin: auto !important;
    }

    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }

    .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }

    .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .mx-xl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }

    .mx-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }

    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }

    .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .my-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }

    .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }

    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mt-xl-0 {
      margin-top: 0 !important;
    }

    .mt-xl-1 {
      margin-top: 0.25rem !important;
    }

    .mt-xl-2 {
      margin-top: 0.5rem !important;
    }

    .mt-xl-3 {
      margin-top: 1rem !important;
    }

    .mt-xl-4 {
      margin-top: 1.5rem !important;
    }

    .mt-xl-5 {
      margin-top: 3rem !important;
    }

    .mt-xl-auto {
      margin-top: auto !important;
    }

    .me-xl-0 {
      margin-right: 0 !important;
    }

    .me-xl-1 {
      margin-right: 0.25rem !important;
    }

    .me-xl-2 {
      margin-right: 0.5rem !important;
    }

    .me-xl-3 {
      margin-right: 1rem !important;
    }

    .me-xl-4 {
      margin-right: 1.5rem !important;
    }

    .me-xl-5 {
      margin-right: 3rem !important;
    }

    .me-xl-auto {
      margin-right: auto !important;
    }

    .mb-xl-0 {
      margin-bottom: 0 !important;
    }

    .mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }

    .mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }

    .mb-xl-3 {
      margin-bottom: 1rem !important;
    }

    .mb-xl-4 {
      margin-bottom: 1.5rem !important;
    }

    .mb-xl-5 {
      margin-bottom: 3rem !important;
    }

    .mb-xl-auto {
      margin-bottom: auto !important;
    }

    .ms-xl-0 {
      margin-left: 0 !important;
    }

    .ms-xl-1 {
      margin-left: 0.25rem !important;
    }

    .ms-xl-2 {
      margin-left: 0.5rem !important;
    }

    .ms-xl-3 {
      margin-left: 1rem !important;
    }

    .ms-xl-4 {
      margin-left: 1.5rem !important;
    }

    .ms-xl-5 {
      margin-left: 3rem !important;
    }

    .ms-xl-auto {
      margin-left: auto !important;
    }

    .m-xl-n1 {
      margin: -0.25rem !important;
    }

    .m-xl-n2 {
      margin: -0.5rem !important;
    }

    .m-xl-n3 {
      margin: -1rem !important;
    }

    .m-xl-n4 {
      margin: -1.5rem !important;
    }

    .m-xl-n5 {
      margin: -3rem !important;
    }

    .mx-xl-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }

    .mx-xl-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }

    .mx-xl-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }

    .mx-xl-n4 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }

    .mx-xl-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }

    .my-xl-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }

    .my-xl-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }

    .my-xl-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }

    .my-xl-n4 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }

    .my-xl-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }

    .mt-xl-n1 {
      margin-top: -0.25rem !important;
    }

    .mt-xl-n2 {
      margin-top: -0.5rem !important;
    }

    .mt-xl-n3 {
      margin-top: -1rem !important;
    }

    .mt-xl-n4 {
      margin-top: -1.5rem !important;
    }

    .mt-xl-n5 {
      margin-top: -3rem !important;
    }

    .me-xl-n1 {
      margin-right: -0.25rem !important;
    }

    .me-xl-n2 {
      margin-right: -0.5rem !important;
    }

    .me-xl-n3 {
      margin-right: -1rem !important;
    }

    .me-xl-n4 {
      margin-right: -1.5rem !important;
    }

    .me-xl-n5 {
      margin-right: -3rem !important;
    }

    .mb-xl-n1 {
      margin-bottom: -0.25rem !important;
    }

    .mb-xl-n2 {
      margin-bottom: -0.5rem !important;
    }

    .mb-xl-n3 {
      margin-bottom: -1rem !important;
    }

    .mb-xl-n4 {
      margin-bottom: -1.5rem !important;
    }

    .mb-xl-n5 {
      margin-bottom: -3rem !important;
    }

    .ms-xl-n1 {
      margin-left: -0.25rem !important;
    }

    .ms-xl-n2 {
      margin-left: -0.5rem !important;
    }

    .ms-xl-n3 {
      margin-left: -1rem !important;
    }

    .ms-xl-n4 {
      margin-left: -1.5rem !important;
    }

    .ms-xl-n5 {
      margin-left: -3rem !important;
    }

    .p-xl-0 {
      padding: 0 !important;
    }

    .p-xl-1 {
      padding: 0.25rem !important;
    }

    .p-xl-2 {
      padding: 0.5rem !important;
    }

    .p-xl-3 {
      padding: 1rem !important;
    }

    .p-xl-4 {
      padding: 1.5rem !important;
    }

    .p-xl-5 {
      padding: 3rem !important;
    }

    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }

    .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .px-xl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }

    .px-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }

    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .py-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }

    .pt-xl-0 {
      padding-top: 0 !important;
    }

    .pt-xl-1 {
      padding-top: 0.25rem !important;
    }

    .pt-xl-2 {
      padding-top: 0.5rem !important;
    }

    .pt-xl-3 {
      padding-top: 1rem !important;
    }

    .pt-xl-4 {
      padding-top: 1.5rem !important;
    }

    .pt-xl-5 {
      padding-top: 3rem !important;
    }

    .pe-xl-0 {
      padding-right: 0 !important;
    }

    .pe-xl-1 {
      padding-right: 0.25rem !important;
    }

    .pe-xl-2 {
      padding-right: 0.5rem !important;
    }

    .pe-xl-3 {
      padding-right: 1rem !important;
    }

    .pe-xl-4 {
      padding-right: 1.5rem !important;
    }

    .pe-xl-5 {
      padding-right: 3rem !important;
    }

    .pb-xl-0 {
      padding-bottom: 0 !important;
    }

    .pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }

    .pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }

    .pb-xl-3 {
      padding-bottom: 1rem !important;
    }

    .pb-xl-4 {
      padding-bottom: 1.5rem !important;
    }

    .pb-xl-5 {
      padding-bottom: 3rem !important;
    }

    .ps-xl-0 {
      padding-left: 0 !important;
    }

    .ps-xl-1 {
      padding-left: 0.25rem !important;
    }

    .ps-xl-2 {
      padding-left: 0.5rem !important;
    }

    .ps-xl-3 {
      padding-left: 1rem !important;
    }

    .ps-xl-4 {
      padding-left: 1.5rem !important;
    }

    .ps-xl-5 {
      padding-left: 3rem !important;
    }

    .gap-xl-0 {
      gap: 0 !important;
    }

    .gap-xl-1 {
      gap: 0.25rem !important;
    }

    .gap-xl-2 {
      gap: 0.5rem !important;
    }

    .gap-xl-3 {
      gap: 1rem !important;
    }

    .gap-xl-4 {
      gap: 1.5rem !important;
    }

    .gap-xl-5 {
      gap: 3rem !important;
    }

    .text-xl-start {
      text-align: left !important;
    }

    .text-xl-end {
      text-align: right !important;
    }

    .text-xl-center {
      text-align: center !important;
    }
  }

  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 2.3rem !important;
    }

    .fs-2 {
      font-size: 1.84rem !important;
    }

    .fs-3 {
      font-size: 1.61rem !important;
    }

    .fs-4 {
      font-size: 1.38rem !important;
    }
  }

  @media print {
    .d-print-inline {
      display: inline !important;
    }

    .d-print-inline-block {
      display: inline-block !important;
    }

    .d-print-block {
      display: block !important;
    }

    .d-print-grid {
      display: -ms-grid !important;
      display: grid !important;
    }

    .d-print-table {
      display: table !important;
    }

    .d-print-table-row {
      display: table-row !important;
    }

    .d-print-table-cell {
      display: table-cell !important;
    }

    .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .d-print-none {
      display: none !important;
    }
  }

  html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  html {
    height: 100%;
  }

  body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .page-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }

  .footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  :root {
    --si-user-selection-color: rgba(var(--si-primary-rgb), 0.22);
    --si-heading-link-color: #252a44;
    --si-heading-link-hover-color: #146ad3;
  }

  a {
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }

  a:focus {
    outline: none;
  }

  a[class*="bg-"]:not(.btn),
  a[class^="bg-"]:not(.btn) {
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
  }

  figure,
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  svg {
    max-width: 100%;
  }

  .dark-mode-img {
    display: none;
  }

  ::-moz-selection {
    background: var(--si-user-selection-color);
  }

  ::selection {
    background: var(--si-user-selection-color);
  }

  ::-moz-selection {
    background: var(--si-user-selection-color);
  }

  b,
  strong {
    font-weight: 800;
  }

  .small,
  small {
    font-weight: inherit;
  }

  button:focus {
    outline: none;
  }

  @media (min-width: 992px) {
    .docs-container {
      padding-left: 21rem;
    }
  }

  @media (min-width: 1400px) {
    .docs-container {
      padding-right: 20rem;
    }
  }

  .fs-4,
  .fs-5,
  .fs-6,
  .h4,
  .h5,
  .h6,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }

  .h1 > a,
  .h2 > a,
  .h3 > a,
  .h4 > a,
  .h5 > a,
  .h6 > a,
  h1 > a,
  h2 > a,
  h3 > a,
  h4 > a,
  h5 > a,
  h6 > a {
    color: var(--si-heading-link-color);
    text-decoration: none;
  }

  .h1 > a:hover,
  .h2 > a:hover,
  .h3 > a:hover,
  .h4 > a:hover,
  .h5 > a:hover,
  .h6 > a:hover,
  h1 > a:hover,
  h2 > a:hover,
  h3 > a:hover,
  h4 > a:hover,
  h5 > a:hover,
  h6 > a:hover {
    color: var(--si-heading-link-hover-color);
  }

  dt {
    color: var(--si-heading-color);
  }

  body .dark-mode dd,
  body .dark-mode ol,
  body .dark-mode p,
  body .dark-mode span:not(.badge),
  body .dark-mode ul {
    color: var(--si-body-color);
  }

  :not(pre) > code[class*="language-"],
  pre,
  pre[class*="language-"] {
    --si-pre-border-width: var(--si-border-width);
    --si-pre-border-color: var(--si-border-color);
    --si-pre-bg: #ffffff;
    --si-pre-font-size: 0.875em;
    --si-pre-line-numbers-border-width: 1px;
    --si-pre-line-numbers-border-color: #f5f5f7;
    --si-pre-line-numbers-color: #a3acb5;
    --si-pre-tag-color: #4e54c8;
    --si-pre-comment-color: #a3acb5;
    --si-pre-attr-name-color: #4091e3;
    --si-pre-attr-value-color: #e3116c;
    --si-pre-class-name-color: #36acaa;
    max-width: 100%;
    margin: 0;
    padding: 1.5rem 1.5rem;
    background-color: var(--si-pre-bg);
    border: var(--si-pre-border-width) solid var(--si-pre-border-color);
    border-radius: 0.5rem;
    font-size: var(--si-pre-font-size);
    -webkit-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
  }

  .code-toolbar :not(pre) > code[class*="language-"],
  .code-toolbar pre[class*="language-"] {
    padding-top: 2.25rem;
  }

  code[class*="language-"],
  pre,
  pre[class*="language-"] {
    --si-pre-color: #252a44;
    color: var(--si-pre-color);
    text-shadow: none;
  }

  .line-numbers .line-numbers-rows {
    border-right: var(--si-pre-line-numbers-border-width) solid
      var(--si-pre-line-numbers-border-color);
  }

  .line-numbers .line-numbers-rows > span:before {
    color: var(--si-pre-line-numbers-color);
  }

  div.code-toolbar > .toolbar {
    opacity: 1;
  }

  div.code-toolbar > .toolbar .toolbar-item > span,
  div.code-toolbar > .toolbar > .toolbar-item > a,
  div.code-toolbar > .toolbar > .toolbar-item > button {
    display: inline-block;
    margin: 0.375rem 0.5rem;
    padding: 0.25rem 0.75rem;
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    border-radius: 0.25rem;
    border: 1px solid rgba(105, 54, 245, 0.35);
    background-color: transparent;
    color: #146ad3 !important;
    font-size: 0.69rem;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  div.code-toolbar > .toolbar .toolbar-item > span:focus,
  div.code-toolbar > .toolbar > .toolbar-item > a:focus,
  div.code-toolbar > .toolbar > .toolbar-item > button:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  div.code-toolbar > .toolbar .toolbar-item > span:hover,
  div.code-toolbar > .toolbar > .toolbar-item > a:hover,
  div.code-toolbar > .toolbar > .toolbar-item > button:hover {
    background-color: #146ad3;
    color: #ffffff !important;
  }

  .token.boolean,
  .token.constant,
  .token.deleted,
  .token.number,
  .token.property,
  .token.symbol,
  .token.tag {
    color: var(--si-pre-tag-color);
  }

  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog {
    color: var(--si-pre-comment-color);
  }

  .token.attr-name,
  .token.builtin,
  .token.char,
  .token.inserted,
  .token.selector {
    color: var(--si-pre-attr-name-color);
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword,
  .token.string {
    color: var(--si-pre-attr-value-color);
  }

  .language-css .token.string,
  .style .token.string,
  .token.class-name,
  .token.entity,
  .token.function,
  .token.operator,
  .token.url {
    color: var(--si-pre-class-name-color);
    background: none;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }

  .form-select:focus {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.2);
  }

  .form-control:disabled,
  .form-select:disabled {
    cursor: not-allowed;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .form-control:disabled,
  .form-control[readonly] {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .form-control:disabled::-webkit-input-placeholder,
  .form-control[readonly]::-webkit-input-placeholder {
    color: #858c97;
  }

  .form-control:disabled::-moz-placeholder,
  .form-control[readonly]::-moz-placeholder {
    color: #858c97;
  }

  .form-control:disabled:-ms-input-placeholder,
  .form-control[readonly]:-ms-input-placeholder {
    color: #858c97;
  }

  .form-control:disabled::-ms-input-placeholder,
  .form-control[readonly]::-ms-input-placeholder {
    color: #858c97;
  }

  .form-control:disabled::placeholder,
  .form-control[readonly]::placeholder {
    color: #858c97;
  }

  label {
    font-size: 0.805rem;
  }

  .form-floating > label {
    font-weight: 500;
  }

  .valid-tooltip {
    position: static;
    background-color: rgba(23, 241, 210, 0.12);
    color: #17f1d2;
  }

  .invalid-tooltip {
    position: static;
    background-color: rgba(255, 79, 0, 0.12);
    color: #ff4f00;
  }

  .btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .btn:not([class^="btn-outline-"]):not([class*=" btn-outline-"]):not(
      .btn-secondary
    ):not(.btn-light):not(.btn-link) {
    --si-btn-color: #ffffff;
  }

  .btn.disabled,
  .btn:disabled,
  .btn:hover {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .btn-primary {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #4e12f3;
    --si-btn-active-bg: #4e12f3;
    --si-btn-hover-border-color: #4e12f3;
    --si-btn-active-border-color: #4e12f3;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-secondary {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #e0e0e6;
    --si-btn-active-bg: #e0e0e6;
    --si-btn-hover-border-color: #e0e0e6;
    --si-btn-active-border-color: #e0e0e6;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-success {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #0dd5b8;
    --si-btn-active-bg: #0dd5b8;
    --si-btn-hover-border-color: #0dd5b8;
    --si-btn-active-border-color: #0dd5b8;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-info {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #8d8d93;
    --si-btn-active-bg: #8d8d93;
    --si-btn-hover-border-color: #8d8d93;
    --si-btn-active-border-color: #8d8d93;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-warning {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #ffc34d;
    --si-btn-active-bg: #ffc34d;
    --si-btn-hover-border-color: #ffc34d;
    --si-btn-active-border-color: #ffc34d;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-danger {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #d94300;
    --si-btn-active-bg: #d94300;
    --si-btn-hover-border-color: #d94300;
    --si-btn-active-border-color: #d94300;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-light {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #dee1e4;
    --si-btn-active-bg: #dee1e4;
    --si-btn-hover-border-color: #dee1e4;
    --si-btn-active-border-color: #dee1e4;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-dark {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
    --si-btn-hover-bg: #020203;
    --si-btn-active-bg: #020203;
    --si-btn-hover-border-color: #020203;
    --si-btn-active-border-color: #020203;
    --si-btn-disabled-color: #ffffff;
  }

  .btn-light,
  .btn-secondary {
    --si-btn-color: #252a44;
    --si-btn-hover-color: #000000;
    --si-btn-active-color: #000000;
    --si-btn-disabled-color: #576071;
  }

  [class*=" btn-outline-"],
  [class^="btn-outline-"] {
    --si-btn-hover-color: #ffffff;
    --si-btn-active-color: #ffffff;
  }

  .btn-outline-primary {
    --si-btn-border-color: rgba(105, 54, 245, 0.35);
    --si-btn-disabled-border-color: rgba(105, 54, 245, 0.35);
  }

  .btn-outline-success {
    --si-btn-border-color: rgba(23, 241, 210, 0.35);
    --si-btn-disabled-border-color: rgba(23, 241, 210, 0.35);
  }

  .btn-outline-info {
    --si-btn-border-color: rgba(161, 161, 166, 0.35);
    --si-btn-disabled-border-color: rgba(161, 161, 166, 0.35);
  }

  .btn-outline-warning {
    --si-btn-border-color: rgba(255, 208, 115, 0.35);
    --si-btn-disabled-border-color: rgba(255, 208, 115, 0.35);
  }

  .btn-outline-danger {
    --si-btn-border-color: rgba(255, 79, 0, 0.35);
    --si-btn-disabled-border-color: rgba(255, 79, 0, 0.35);
  }

  .btn-outline-light {
    --si-btn-border-color: rgba(255, 255, 255, 0.14);
    --si-btn-disabled-border-color: rgba(255, 255, 255, 0.14);
  }

  .btn-outline-dark {
    --si-btn-border-color: rgba(18, 21, 25, 0.35);
    --si-btn-disabled-border-color: rgba(18, 21, 25, 0.35);
  }

  .btn-outline-white {
    --si-btn-border-color: rgba(255, 255, 255, 0.35);
    --si-btn-disabled-border-color: rgba(255, 255, 255, 0.35);
  }

  .btn-outline-secondary {
    --si-btn-color: #252a44;
    --si-btn-hover-color: #000000;
    --si-btn-active-color: #000000;
    --si-btn-hover-bg: #f5f5f7;
    --si-btn-active-bg: #f5f5f7;
    --si-btn-border-color: #d7dde2;
    --si-btn-hover-border-color: #f5f5f7;
    --si-btn-active-border-color: #f5f5f7;
    --si-btn-disabled-color: #576071;
    --si-btn-disabled-border-color: #f5f5f7;
  }

  .btn-outline-light {
    --si-btn-border-color: rgba(243, 244, 245, 0.25);
    --si-btn-hover-color: #252a44;
    --si-btn-active-color: #252a44;
    --si-btn-hover-bg: #f3f4f5;
    --si-btn-active-bg: #f3f4f5;
  }

  .btn-link {
    --si-btn-font-weight: 600;
    text-decoration: none;
  }

  .btn-icon {
    --si-btn-size: 2.75rem;
    --si-btn-icon-size: 1.15rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: var(--si-btn-size);
    height: var(--si-btn-size);
    padding: 0;
  }

  .btn-icon > i {
    font-size: var(--si-btn-icon-size);
  }

  .btn-icon.btn-xl {
    --si-btn-size: 4.5rem;
    --si-btn-icon-size: 1.61rem;
  }

  .btn-close {
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
  }

  .btn-scroll-top {
    --si-btn-scroll-top-size: 2.75rem;
    --si-btn-scroll-top-border-radius: 50%;
    --si-btn-scroll-top-color: #ffffff;
    --si-btn-scroll-top-hover-color: #ffffff;
    --si-btn-scroll-top-bg: rgba(18, 21, 25, 0.2);
    --si-btn-scroll-top-hover-bg: rgba(18, 21, 25, 0.4);
    --si-btn-scroll-top-icon-size: 1.38rem;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    right: 1.25rem;
    bottom: -4.125rem;
    width: var(--si-btn-scroll-top-size);
    height: var(--si-btn-scroll-top-size);
    -webkit-transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
      opacity 0.3s, background-color 0.25s ease-in-out;
    transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
      opacity 0.3s, background-color 0.25s ease-in-out;
    border-radius: var(--si-btn-scroll-top-border-radius);
    background-color: var(--si-btn-scroll-top-bg);
    color: var(--si-btn-scroll-top-color);
    text-decoration: none;
    opacity: 0;
    z-index: 1030;
  }

  .btn-scroll-top > .btn-scroll-top-icon {
    font-size: var(--si-btn-scroll-top-icon-size);
    font-weight: bold;
  }

  .btn-scroll-top:hover {
    background-color: var(--si-btn-scroll-top-hover-bg);
    color: var(--si-btn-scroll-top-hover-color);
  }

  .btn-scroll-top.show {
    bottom: 1.25rem;
    opacity: 1;
  }

  @media (max-width: 499.98px) {
    .btn-scroll-top {
      width: calc(var(--si-btn-scroll-top-size) * 0.8);
      height: calc(var(--si-btn-scroll-top-size) * 0.8);
      right: 1rem;
    }

    .btn-scroll-top.show {
      bottom: 1rem;
    }
  }

  .dropdown-toggle:after,
  .dropdown-toggle:before {
    font-size: 1.375em;
    font-weight: normal;
    vertical-align: middle !important;
    border: none !important;
    line-height: 1;
  }

  .dropdown-toggle.fs-sm:after {
    margin-top: -0.0625rem !important;
  }

  .dropdown-toggle.btn:not(.btn-lg):after,
  .dropdown-toggle.btn:not(.btn-lg):before {
    font-size: 1.375em;
  }

  .dropup .dropdown-toggle:after {
    content: "\ea57";
  }

  .dropstart .dropdown-toggle:before {
    margin-left: -0.3125rem;
    content: "\ea4d";
  }

  .dropend .dropdown-toggle:after {
    margin-right: -0.3125rem;
    content: "\ed3b";
    font-size: 1.15em !important;
  }

  .dropdown-toggle-split:after,
  .dropdown-toggle-split:before {
    margin: 0;
  }

  .dropdown-menu {
    --si-dropdown-link-font-weight: 500;
  }

  .dropdown-menu li:hover > .dropdown-item {
    color: var(--si-dropdown-link-hover-color);
  }

  .dropdown-menu .active > .dropdown-item {
    color: var(--si-dropdown-link-active-color);
  }

  .dropdown-menu .active > .dropdown-item,
  .dropdown-menu .dropdown-item.active {
    pointer-events: none;
  }

  .dropdown-menu.w-100 {
    min-width: 100%;
  }

  .dark-mode .dropdown-menu,
  .dropdown-menu-dark {
    background: var(--si-dropdown-bg);
  }

  .dark-mode .dropdown-menu li:hover > .dropdown-item,
  .dropdown-menu-dark li:hover > .dropdown-item {
    color: var(--si-dropdown-link-hover-color);
  }

  .dark-mode .dropdown-menu .active > .dropdown-item,
  .dropdown-menu-dark .active > .dropdown-item {
    color: var(--si-dropdown-link-active-color);
  }

  .dropdown-item {
    font-weight: var(--si-dropdown-link-font-weight);
  }

  .dropdown-divider {
    margin-right: var(--si-dropdown-item-padding-x);
    margin-left: var(--si-dropdown-item-padding-x);
  }

  hr.dropdown-divider {
    border: 0;
    height: var(--si-dropdown-border-width);
    background-color: var(--si-dropdown-divider-bg);
  }

  .dark-mode .dropdown-menu hr.dropdown-divider,
  .dropdown-menu-dark hr.dropdown-divider {
    border: 0;
    background-color: var(--si-dropdown-divider-bg);
  }

  .dropdown-menu.show {
    -webkit-animation: fade-in 0.2s ease-in-out;
    animation: fade-in 0.2s ease-in-out;
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .nav-tabs-alt > .nav-item > .dropdown-menu {
    margin-top: -0.125rem !important;
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-item {
    margin-bottom: 0;
  }

  .nav {
    --si-nav-link-active-color: #146ad3;
  }

  .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-link.active {
    color: var(--si-nav-link-active-color);
  }

  .nav-link.active:not([data-bs-toggle="dropdown"]) {
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    --si-nav-tabs-link-bg: #000000;
    --si-nav-tabs-link-hover-bg: rgba(105, 54, 245, 0.12);
    --si-nav-tabs-link-disabled-bg: #f3f4f5;
    --si-nav-tabs-link-color: #f3f4f5;
    --si-nav-tabs-link-hover-color: #146ad3;
    margin-bottom: 0.75rem;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0.75rem;
  }

  .nav-tabs .nav-link {
    border-radius: var(--si-nav-tabs-border-radius);
    background-color: var(--si-nav-tabs-link-bg);
    color: var(--si-nav-tabs-link-color);
  }

  .nav-tabs .nav-link.show:not(.active),
  .nav-tabs .nav-link:hover:not(.active) {
    background-color: var(--si-nav-tabs-link-hover-bg);
    color: var(--si-nav-tabs-link-hover-color);
  }

  .nav-tabs .nav-link.disabled,
  .nav-tabs .nav-link[disabled] {
    background-color: var(--si-nav-tabs-link-disabled-bg);
  }

  .nav-tabs .dropdown-menu {
    border-top-left-radius: var(--si-dropdown-border-radius);
    border-top-right-radius: var(--si-dropdown-border-radius);
  }

  .nav-tabs-alt {
    --si-nav-tabs-alt-border-width: 1px;
    --si-nav-tabs-alt-border-color: #f5f5f7;
    --si-nav-tabs-alt-link-padding-y: 0.8025rem;
    --si-nav-tabs-alt-link-padding-x: 1rem;
    --si-nav-tabs-alt-link-active-border-color: #146ad3;
    margin-bottom: 1.5rem;
    border-bottom: var(--si-nav-tabs-alt-border-width) solid
      var(--si-nav-tabs-alt-border-color);
  }

  .nav-tabs-alt .nav-item {
    margin-bottom: calc(var(--si-nav-tabs-alt-border-width) * -1);
  }

  .nav-tabs-alt .nav-link {
    padding: var(--si-nav-tabs-alt-link-padding-y)
      var(--si-nav-tabs-alt-link-padding-x);
    border-bottom: 1px solid transparent;
  }

  .nav-tabs-alt .nav-link.active {
    border-bottom-color: var(--si-nav-tabs-alt-link-active-border-color);
  }

  .nav-pills .nav-item,
  .nav-tabs .nav-item {
    margin-right: 0.75rem;
  }

  .nav-pills .nav-item:last-child,
  .nav-tabs .nav-item:last-child {
    margin-right: 0;
  }

  .nav-pills.justify-content-center .nav-item,
  .nav-tabs.justify-content-center .nav-item {
    margin-right: 0.375rem;
    margin-left: 0.375rem;
  }

  .nav-pills.justify-content-end .nav-item,
  .nav-tabs.justify-content-end .nav-item {
    margin-right: 0;
    margin-left: 0.75rem;
  }

  .nav-pills.flex-column .nav-item,
  .nav-tabs.flex-column .nav-item {
    margin-right: 0;
    margin-bottom: 0.75rem;
    margin-left: 0;
  }

  .nav-pills.flex-column .nav-item:last-child,
  .nav-tabs.flex-column .nav-item:last-child {
    margin-bottom: 0;
  }

  .side-nav {
    --si-side-nav-padding-y: 0;
    --si-side-nav-padding-x: 2rem;
    --si-side-nav-border-width: 1px;
    --si-side-nav-border-color: #f5f5f7;
    --si-side-nav-link-padding-y: 0.375rem;
    --si-side-nav-link-padding-x: 2rem;
    --si-side-nav-link-font-size: 0.805rem;
    --si-side-nav-link-active-border-color: #146ad3;
    padding: var(--si-side-nav-padding-y) var(--si-side-nav-padding-x);
  }

  .side-nav .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: calc(var(--si-side-nav-padding-x) * -1);
    margin-left: calc(var(--si-side-nav-padding-x) * -1);
  }

  .side-nav .nav-link {
    padding: var(--si-side-nav-link-padding-y) var(--si-side-nav-link-padding-x);
    -webkit-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    font-size: var(--si-side-nav-link-font-size);
  }

  .side-nav.side-nav-start {
    border-right: var(--si-side-nav-border-width) solid
      var(--si-side-nav-border-color);
  }

  .side-nav.side-nav-start .nav-link {
    margin-right: calc(var(--si-side-nav-border-width) * -1);
    border-right: var(--si-side-nav-border-width) solid transparent;
  }

  .side-nav.side-nav-end {
    border-left: var(--si-side-nav-border-width) solid
      var(--si-side-nav-border-color);
  }

  .side-nav.side-nav-end .nav-link {
    margin-left: calc(var(--si-side-nav-border-width) * -1);
    border-left: var(--si-side-nav-border-width) solid transparent;
  }

  .side-nav .active > .nav-link,
  .side-nav .nav-link.active {
    border-color: var(--si-side-nav-link-active-border-color);
  }

  .bg-faded-primary-hover {
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }

  .bg-faded-primary-hover:hover {
    background-color: rgba(105, 54, 245, 0.12) !important;
  }

  .navbar-brand {
    --si-navbar-brand-padding-y: 0.5rem;
    --si-navbar-brand-margin-end: 1rem;
    --si-navbar-brand-font-size: 1.375rem;
    --si-navbar-brand-font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: calc(var(--si-navbar-brand-padding-y) * 0.5) 0;
    font-weight: var(--si-navbar-brand-font-weight);
  }

  .navbar-brand > img {
    margin-right: 0.4375rem;
  }

  .navbar-toggler {
    position: relative;
    width: 1.875rem;
    height: 2.25rem;
  }

  .navbar-toggler-icon {
    display: block;
    top: 50%;
    margin-top: -0.0625rem;
    -webkit-transition-duration: 0.075s;
    transition-duration: 0.075s;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .navbar-toggler-icon,
  .navbar-toggler-icon:after,
  .navbar-toggler-icon:before {
    position: absolute;
    width: 1.375rem;
    height: 0.125rem;
    -webkit-transition-property: -webkit-transform 0.15s ease;
    transition-property: -webkit-transform 0.15s ease;
    transition-property: transform 0.15s ease;
    transition-property: transform 0.15s ease, -webkit-transform 0.15s ease;
    background-color: var(--si-navbar-toggler-color);
  }

  .navbar-toggler-icon:after,
  .navbar-toggler-icon:before {
    display: block;
    content: "";
  }

  .navbar-toggler-icon:before {
    top: -0.4375rem;
    -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }

  .navbar-toggler-icon:after {
    bottom: -0.4375rem;
    -webkit-transition: bottom 0.075s 0.12s ease,
      -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease,
      -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  [aria-expanded="true"] .navbar-toggler-icon {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: 0.12s;
    transition-delay: 0.12s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  [aria-expanded="true"] .navbar-toggler-icon:before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }

  [aria-expanded="true"] .navbar-toggler-icon:after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.075s ease,
      -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease,
      -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .navbar-nav {
    --si-nav-link-font-weight: 500;
    --si-mega-dropdown-column-width: 14rem;
  }

  .navbar .navbar-nav .dropdown-menu {
    border: 0;
    border-left: var(--si-dropdown-border-wdth, 1px) solid
      var(--si-border-color);
    border-radius: 0;
    background-color: transparent;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .dark-mode .navbar .navbar-nav .dropdown-menu,
  .navbar .navbar-nav .dark-mode .dropdown-menu,
  .navbar .navbar-nav .dropdown-menu.dropdown-menu-dark {
    border-color: rgba(255, 255, 255, 0.14);
  }

  .navbar .navbar-nav .dropdown-menu .dropdown-menu {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .navbar .navbar-nav .dropdown-menu .dropdown-toggle:after {
    margin-top: -0.0625rem;
    margin-right: 0;
    margin-left: 0.125rem;
    font-size: 1em;
  }

  .navbar .navbar-nav .dropdown-menu .dropdown-divider {
    display: none;
  }

  @-webkit-keyframes dropdown-show {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes dropdown-show {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (min-width: 500px) {
    .navbar-expand-sm .navbar-brand {
      padding: var(--si-navbar-brand-padding-y) 0;
    }

    .navbar-expand-sm .offcanvas {
      background-color: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .navbar-expand-sm .offcanvas-header,
    .navbar-expand-sm .offcanvas-header {
      display: none;
    }

    .navbar-expand-sm .dropdown-divider {
      display: block;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
      margin-top: var(--si-dropdown-spacer);
      border: var(--si-dropdown-border-width) solid
        var(--si-dropdown-border-color);
      border-radius: var(--si-dropdown-border-radius);
      background-color: var(--si-dropdown-bg);
      -webkit-box-shadow: var(--si-dropdown-box-shadow);
      box-shadow: var(--si-dropdown-box-shadow);
    }

    .dark-mode .navbar-expand-sm .navbar-nav .dropdown-menu,
    .navbar-expand-sm .navbar-nav .dark-mode .dropdown-menu,
    .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-dark {
      background: var(--si-dropdown-bg);
    }

    .navbar-expand-sm .dropdown-menu.show {
      display: none;
    }

    .navbar-expand-sm .dropdown:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.375rem;
      content: "";
    }

    .navbar-expand-sm .dropdown .dropdown .dropdown-toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .navbar-expand-sm .dropdown .dropdown .dropdown-toggle:after {
      margin-top: 0;
      margin-right: -0.3125rem;
      margin-left: auto;
      font-size: 1.25em;
      content: "\ea50";
    }

    .navbar-expand-sm .dropdown .dropdown .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      left: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-sm .dropdown .dropdown-menu-end {
      left: auto;
      right: 0;
    }

    .navbar-expand-sm .dropstart > .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      right: 94%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-sm .dropdown:hover > .dropdown-menu,
    .navbar-expand-sm .dropstart:hover > .dropdown-menu {
      display: block;
      -webkit-animation: dropdown-show 0.2s;
      animation: dropdown-show 0.2s;
    }

    .navbar-expand-sm .mega-dropdown-column {
      width: var(--si-mega-dropdown-column-width);
      padding: 0 0.75rem;
    }

    .navbar-expand-sm .mega-dropdown-column:last-child {
      border-right: 0;
    }
  }

  @media (min-width: 768px) {
    .navbar-expand-md .navbar-brand {
      padding: var(--si-navbar-brand-padding-y) 0;
    }

    .navbar-expand-md .offcanvas {
      background-color: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .navbar-expand-md .offcanvas-header,
    .navbar-expand-md .offcanvas-header {
      display: none;
    }

    .navbar-expand-md .dropdown-divider {
      display: block;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
      margin-top: var(--si-dropdown-spacer);
      border: var(--si-dropdown-border-width) solid
        var(--si-dropdown-border-color);
      border-radius: var(--si-dropdown-border-radius);
      background-color: var(--si-dropdown-bg);
      -webkit-box-shadow: var(--si-dropdown-box-shadow);
      box-shadow: var(--si-dropdown-box-shadow);
    }

    .dark-mode .navbar-expand-md .navbar-nav .dropdown-menu,
    .navbar-expand-md .navbar-nav .dark-mode .dropdown-menu,
    .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-dark {
      background: var(--si-dropdown-bg);
    }

    .navbar-expand-md .dropdown-menu.show {
      display: none;
    }

    .navbar-expand-md .dropdown:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.375rem;
      content: "";
    }

    .navbar-expand-md .dropdown .dropdown .dropdown-toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .navbar-expand-md .dropdown .dropdown .dropdown-toggle:after {
      margin-top: 0;
      margin-right: -0.3125rem;
      margin-left: auto;
      font-size: 1.25em;
      content: "\ea50";
    }

    .navbar-expand-md .dropdown .dropdown .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      left: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-md .dropdown .dropdown-menu-end {
      left: auto;
      right: 0;
    }

    .navbar-expand-md .dropstart > .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      right: 94%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-md .dropdown:hover > .dropdown-menu,
    .navbar-expand-md .dropstart:hover > .dropdown-menu {
      display: block;
      -webkit-animation: dropdown-show 0.2s;
      animation: dropdown-show 0.2s;
    }

    .navbar-expand-md .mega-dropdown-column {
      width: var(--si-mega-dropdown-column-width);
      padding: 0 0.75rem;
    }

    .navbar-expand-md .mega-dropdown-column:last-child {
      border-right: 0;
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand {
      padding: var(--si-navbar-brand-padding-y) 0;
    }

    .navbar-expand-lg .offcanvas {
      background-color: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .navbar-expand-lg .offcanvas-header,
    .navbar-expand-lg .offcanvas-header {
      display: none;
    }

    .navbar-expand-lg .dropdown-divider {
      display: block;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      margin-top: var(--si-dropdown-spacer);
      border: var(--si-dropdown-border-width) solid
        var(--si-dropdown-border-color);
      border-radius: var(--si-dropdown-border-radius);
      background-color: var(--si-dropdown-bg);
      -webkit-box-shadow: var(--si-dropdown-box-shadow);
      box-shadow: var(--si-dropdown-box-shadow);
    }

    .dark-mode .navbar-expand-lg .navbar-nav .dropdown-menu,
    .navbar-expand-lg .navbar-nav .dark-mode .dropdown-menu,
    .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-dark {
      background: var(--si-dropdown-bg);
    }

    .navbar-expand-lg .dropdown-menu.show {
      display: none;
    }

    .navbar-expand-lg .dropdown:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.375rem;
      content: "";
    }

    .navbar-expand-lg .dropdown .dropdown .dropdown-toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .navbar-expand-lg .dropdown .dropdown .dropdown-toggle:after {
      margin-top: 0;
      margin-right: -0.3125rem;
      margin-left: auto;
      font-size: 1.25em;
      content: "\ea50";
    }

    .navbar-expand-lg .dropdown .dropdown .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      left: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-lg .dropdown .dropdown-menu-end {
      left: auto;
      right: 0;
    }

    .navbar-expand-lg .dropstart > .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      right: 94%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-lg .dropdown:hover > .dropdown-menu,
    .navbar-expand-lg .dropstart:hover > .dropdown-menu {
      display: block;
      -webkit-animation: dropdown-show 0.2s;
      animation: dropdown-show 0.2s;
    }

    .navbar-expand-lg .mega-dropdown-column {
      width: var(--si-mega-dropdown-column-width);
      padding: 0 0.75rem;
    }

    .navbar-expand-lg .mega-dropdown-column:last-child {
      border-right: 0;
    }
  }

  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-brand {
      padding: var(--si-navbar-brand-padding-y) 0;
    }

    .navbar-expand-xl .offcanvas {
      background-color: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .navbar-expand-xl .offcanvas-header,
    .navbar-expand-xl .offcanvas-header {
      display: none;
    }

    .navbar-expand-xl .dropdown-divider {
      display: block;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
      margin-top: var(--si-dropdown-spacer);
      border: var(--si-dropdown-border-width) solid
        var(--si-dropdown-border-color);
      border-radius: var(--si-dropdown-border-radius);
      background-color: var(--si-dropdown-bg);
      -webkit-box-shadow: var(--si-dropdown-box-shadow);
      box-shadow: var(--si-dropdown-box-shadow);
    }

    .dark-mode .navbar-expand-xl .navbar-nav .dropdown-menu,
    .navbar-expand-xl .navbar-nav .dark-mode .dropdown-menu,
    .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-dark {
      background: var(--si-dropdown-bg);
    }

    .navbar-expand-xl .dropdown-menu.show {
      display: none;
    }

    .navbar-expand-xl .dropdown:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.375rem;
      content: "";
    }

    .navbar-expand-xl .dropdown .dropdown .dropdown-toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .navbar-expand-xl .dropdown .dropdown .dropdown-toggle:after {
      margin-top: 0;
      margin-right: -0.3125rem;
      margin-left: auto;
      font-size: 1.25em;
      content: "\ea50";
    }

    .navbar-expand-xl .dropdown .dropdown .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      left: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-xl .dropdown .dropdown-menu-end {
      left: auto;
      right: 0;
    }

    .navbar-expand-xl .dropstart > .dropdown-menu {
      top: calc(
        (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
      );
      right: 94%;
      margin-right: 0;
      margin-left: 0;
    }

    .navbar-expand-xl .dropdown:hover > .dropdown-menu,
    .navbar-expand-xl .dropstart:hover > .dropdown-menu {
      display: block;
      -webkit-animation: dropdown-show 0.2s;
      animation: dropdown-show 0.2s;
    }

    .navbar-expand-xl .mega-dropdown-column {
      width: var(--si-mega-dropdown-column-width);
      padding: 0 0.75rem;
    }

    .navbar-expand-xl .mega-dropdown-column:last-child {
      border-right: 0;
    }
  }

  .navbar-expand .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }

  .navbar-expand .offcanvas {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-expand .offcanvas-header,
  .navbar-expand .offcanvas-header {
    display: none;
  }

  .navbar-expand .dropdown-divider {
    display: block;
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid
      var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    -webkit-box-shadow: var(--si-dropdown-box-shadow);
    box-shadow: var(--si-dropdown-box-shadow);
  }

  .dark-mode .navbar-expand .navbar-nav .dropdown-menu,
  .navbar-expand .navbar-nav .dark-mode .dropdown-menu,
  .navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-dark {
    background: var(--si-dropdown-bg);
  }

  .navbar-expand .dropdown-menu.show {
    display: none;
  }

  .navbar-expand .dropdown:before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }

  .navbar-expand .dropdown .dropdown .dropdown-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-expand .dropdown .dropdown .dropdown-toggle:after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }

  .navbar-expand .dropdown .dropdown .dropdown-menu {
    top: calc(
      (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
    );
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .navbar-expand .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navbar-expand .dropstart > .dropdown-menu {
    top: calc(
      (var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1
    );
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }

  .navbar-expand .dropdown:hover > .dropdown-menu,
  .navbar-expand .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }

  .navbar-expand .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }

  .navbar-expand .mega-dropdown-column:last-child {
    border-right: 0;
  }

  .navbar-stuck {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-animation: navbar-show 0.25s ease-in-out;
    animation: navbar-show 0.25s ease-in-out;
    -webkit-box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03);
    box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03);
    z-index: 1031;
  }

  .navbar.position-absolute {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
  }

  @-webkit-keyframes navbar-show {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes navbar-show {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .navbar {
    --si-navbar-stuck-bg: #f3f4f5;
    --si-navbar-toggler-color: #252a44;
  }

  .navbar.navbar-stuck {
    background-color: var(--si-navbar-stuck-bg);
  }

  .navbar .nav-item:hover > .nav-link:not(.disabled) {
    color: var(--si-navbar-hover-color);
  }

  .navbar .nav-item.active > .nav-link:not(.disabled) {
    color: var(--si-navbar-active-color);
  }

  .navbar .dropdown:hover > .nav-link {
    color: var(--si-navbar-hover-color);
  }

  .navbar.fixed-top {
    z-index: 1031;
  }

  .navbar-dark {
    --si-navbar-stuck-bg: #000000;
    --si-navbar-toggler-color: rgba(255, 255, 255, 0.85);
  }

  .card-footer,
  .card-header {
    width: calc(100% - var(--si-card-spacer-x) * 2);
    margin: auto;
    padding-right: 0;
    padding-left: 0;
  }

  .card-header-pills,
  .card-header-tabs {
    margin: 0;
  }

  .card-header-pills .nav-item,
  .card-header-tabs .nav-item {
    margin-bottom: 0;
  }

  .card-header-pills .nav-link.active,
  .card-header-tabs .nav-link.active {
    border-color: var(--si-nav-tabs-link-active-border-color);
    background-color: var(--si-nav-tabs-link-active-bg);
  }

  .card-header-pills.nav-tabs-alt,
  .card-header-tabs.nav-tabs-alt {
    margin-top: calc(var(--si-card-cap-padding-y) * -0.625);
    margin-bottom: calc((var(--si-card-cap-padding-y) + 0.0625rem) * -1);
    border-bottom: 0;
  }

  .card-header-pills .nav-link.active {
    background-color: var(--si-nav-pills-link-active-bg);
  }

  .card-hover:not(.bg-transparent) {
    -webkit-transition: border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  }

  .card-hover:not(.bg-transparent):hover {
    -webkit-transform: translateY(-0.25rem);
    transform: translateY(-0.25rem);
    -webkit-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
  }

  .card-hover:not(.bg-transparent).shadow-sm:hover {
    -webkit-box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03) !important;
    box-shadow: 0 0.275rem 1.25rem rgba(18, 21, 25, 0.05),
      0 0.25rem 0.5625rem rgba(18, 21, 25, 0.03) !important;
  }

  .card-hover:not(.bg-transparent).shadow:hover {
    -webkit-box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(18, 21, 25, 0.1),
      0 0.25rem 0.8125rem -0.125rem rgba(18, 21, 25, 0.06) !important;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(18, 21, 25, 0.1),
      0 0.25rem 0.8125rem -0.125rem rgba(18, 21, 25, 0.06) !important;
  }

  .card-hover:not(.bg-transparent).border-primary:hover {
    -webkit-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.9) !important;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.9) !important;
  }

  .card-hover {
    --si-card-icon-hover-color: #146ad3;
  }

  .card-hover .card-img-overlay {
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 5;
  }

  .card-hover .card-icon {
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }

  .card-hover:hover .card-icon {
    color: var(--si-card-icon-hover-color) !important;
  }

  .card-hover:hover .card-img-overlay {
    opacity: 1;
  }

  .card-portfolio {
    border: 0;
    background-color: transparent;
  }

  .card-portfolio .card-img {
    border-radius: var(--si-card-border-radius);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
  }

  .card-portfolio .card-img > img {
    display: block;
    -webkit-transition: -webkit-transform 0.35s ease-in-out;
    transition: -webkit-transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
  }

  .card-portfolio .card-body {
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 768px) {
    .card-portfolio .card-portfolio-meta {
      -webkit-transition: opacity 0.35s ease-in-out;
      transition: opacity 0.35s ease-in-out;
      opacity: 0;
    }

    .card-portfolio:hover .card-img > img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    .card-portfolio:hover .card-portfolio-meta {
      opacity: 1;
    }
  }

  .breadcrumb {
    --si-breadcrumb-font-weight: 500;
    --si-breadcrumb-color: var(--si-gray-600);
    --si-breadcrumb-hover-color: var(--si-gray-800);
    font-weight: var(--si-breadcrumb-font-weight);
  }

  .breadcrumb-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .breadcrumb-item + .breadcrumb-item:before {
    margin-top: 0.15em;

    font-size: 1.15em;
    font-weight: normal;
    line-height: 1;
  }

  .breadcrumb-item > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--si-breadcrumb-color);
    text-decoration: none;
  }

  .breadcrumb-item:hover > a {
    color: var(--si-breadcrumb-hover-color);
  }

  .page-item {
    margin: 0 0.125rem;
  }

  .page-item:first-child {
    margin-left: 0;
  }

  .page-item:last-child {
    margin-right: 0;
  }

  a.badge {
    text-decoration: none;
  }

  .btn .badge {
    top: 1px;
  }

  .offcanvas-header {
    padding: calc(var(--si-offcanvas-padding-y) * 0.75)
      var(--si-offcanvas-padding-x);
  }

  .offcanvas-body {
    display: block !important;
  }

  .btn-next,
  .btn-prev {
    --si-carousel-nav-btn-border-radius: 50%;
    --si-carousel-nav-btn-bg: #ffffff;
    --si-carousel-nav-btn-color: #252a44;
    --si-carousel-nav-btn-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(18, 21, 25, 0.06),
      0 0.125rem 0.4rem -0.0625rem rgba(18, 21, 25, 0.03);
    --si-carousel-nav-btn-hover-bg: #146ad3;
    --si-carousel-nav-btn-hover-color: #ffffff;
    --si-carousel-nav-btn-hover-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 54, 245, 0.9);
    --si-carousel-nav-btn-disabled-color: rgba(67, 74, 87, 0.5);
    --si-carousel-nav-btn-font-size: 0.92rem;
    -webkit-transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      opacity 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, opacity 0.2s ease-in-out,
      -webkit-box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      opacity 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    border: 0;
    border-radius: var(--si-carousel-nav-btn-border-radius);
    background-color: var(--si-carousel-nav-btn-bg);
    color: var(--si-carousel-nav-btn-color) !important;
    font-size: var(--si-carousel-nav-btn-font-size);
    -webkit-box-shadow: var(--si-carousel-nav-btn-box-shadow);
    box-shadow: var(--si-carousel-nav-btn-box-shadow);
  }

  .btn-next:focus,
  .btn-prev:focus {
    background-color: var(--si-carousel-nav-btn-bg);
    -webkit-box-shadow: var(--si-carousel-nav-btn-box-shadow);
    box-shadow: var(--si-carousel-nav-btn-box-shadow);
  }

  .btn-next.bg-white,
  .btn-prev.bg-white {
    color: #252a44 !important;
  }

  .btn-next:not(.bg-transparent):hover,
  .btn-prev:not(.bg-transparent):hover {
    background-color: var(--si-carousel-nav-btn-hover-bg) !important;
    color: var(--si-carousel-nav-btn-hover-color) !important;
    -webkit-box-shadow: var(--si-carousel-nav-btn-hover-box-shadow) !important;
    box-shadow: var(--si-carousel-nav-btn-hover-box-shadow) !important;
  }

  .btn-next > i,
  .btn-prev > i {
    font-size: 1.5em;
  }

  .btn-next.btn-xl > i,
  .btn-prev.btn-xl > i {
    font-size: 2em;
  }

  .fade-in,
  .from-bottom,
  .from-end,
  .from-start,
  .from-top,
  .scale-down,
  .scale-up {
    -webkit-transition: all 0.4s 0.4s ease-in-out;
    transition: all 0.4s 0.4s ease-in-out;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .from-top {
    -webkit-transform: translateY(-2.25rem);
    transform: translateY(-2.25rem);
  }

  .from-bottom {
    -webkit-transform: translateY(2.25rem);
    transform: translateY(2.25rem);
  }

  .from-start {
    -webkit-transform: translateX(-2.25rem);
    transform: translateX(-2.25rem);
  }

  .from-end {
    -webkit-transform: translateX(2.25rem);
    transform: translateX(2.25rem);
  }

  .scale-up {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }

  .scale-down {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  .delay-1 {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }

  .delay-2 {
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
  }

  .delay-3 {
    -webkit-transition-delay: 0.9s;
    transition-delay: 0.9s;
  }

  .delay-4 {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
  }

  .lg-backdrop {
    z-index: 1055;
  }

  .lg-outer {
    z-index: 1060;
  }

  .lg-outer .lg-thumb-item.active,
  .lg-outer .lg-thumb-item:hover {
    border-color: var(--si-primary);
  }

  .lg-on {
    overflow: hidden;
  }

  .lg-inner:focus,
  .lg-item:focus,
  .lg-outer:focus,
  .lg:focus {
    outline: none;
  }

  .dark-mode {
    --si-gray-600: rgba(255, 255, 255, 0.5);
    --si-gray-700: rgba(255, 255, 255, 0.7);
    --si-gray-800: rgba(255, 255, 255, 0.85);
    --si-gray-900: #ffffff;
    --si-body-bg: #000000;
    --si-body-color: rgba(255, 255, 255, 0.7);
    --si-secondary-bg: rgba(255, 255, 255, 0.04);
    --si-heading-link-color: rgba(255, 255, 255, 0.85);
    --si-heading-link-hover-color: #146ad3;
    --si-nav-link-color: rgba(255, 255, 255, 0.85);
    --si-border-color: rgba(255, 255, 255, 0.14);
    --si-code-color: #f33185;
  }

  .dark-mode .d-dark-mode-none {
    display: none !important;
  }

  .dark-mode .d-dark-mode-block {
    display: block !important;
  }

  .dark-mode .d-dark-mode-inline {
    display: inline !important;
  }

  .dark-mode .d-dark-mode-inline-block {
    display: inline-block !important;
  }

  .dark-mode .d-dark-mode-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .dark-mode .d-dark-mode-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .dark-mode .text-border {
    color: var(--si-border-color) !important;
  }

  .dark-mode .text-dark:not(.badge) {
    color: #ffffff !important;
  }

  .dark-mode .text-nav:not(.badge.bg-white) {
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .dark-mode .text-body {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .dark-mode .border-dark {
    border-color: var(--si-border-color) !important;
  }

  .dark-mode .bg-light:not(.badge),
  .dark-mode .card.card-hover.bg-light {
    background-color: #000000 !important;
  }

  .dark-mode .bg-secondary,
  .dark-mode .card.bg-light {
    background-color: var(--si-secondary-bg) !important;
  }

  .dark-mode .bg-secondary.badge {
    color: var(--si-body-color);
  }

  .dark-mode .shadow-dark-mode-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .dark-mode .bg-white {
    --si-body-color: #252a44;
    --si-heading-color: var(--si-gray-900);
    --si-nav-link-color: #252a44;
    --si-border-color: #f5f5f7;
  }

  .dark-mode .bg-white dd,
  .dark-mode .bg-white ol,
  .dark-mode .bg-white p,
  .dark-mode .bg-white span,
  .dark-mode .bg-white ul {
    color: var(--si-body-color);
  }

  .dark-mode .bg-white .text-muted {
    color: #858c97 !important;
  }

  .dark-mode .bg-white .text-dark {
    color: #000000 !important;
  }

  .dark-mode .img-thumbnail {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .dark-mode .dark-mode-img {
    display: block;
  }

  .dark-mode .light-mode-img {
    display: none;
  }

  .dark-mode .table {
    --si-table-striped-bg: rgba(255, 255, 255, 0.0375);
    --si-table-hover-bg: rgba(255, 255, 255, 0.05);
    --si-table-hover-color: var(--si-body-color);
  }

  .dark-mode .table > :not(:last-child) > :last-child > * {
    border-bottom-color: rgba(255, 255, 255, 0.3);
  }

  .dark-mode .btn-outline-secondary {
    --si-btn-color: rgba(243, 244, 245, 0.85);
    --si-btn-hover-color: #252a44;
    --si-btn-active-color: #252a44;
    --si-btn-border-color: rgba(243, 244, 245, 0.25);
    --si-btn-hover-bg: #f3f4f5;
    --si-btn-active-bg: #f3f4f5;
  }

  .dark-mode .btn-secondary:not(.bg-white) {
    --si-btn-color: rgba(243, 244, 245, 0.85);
    --si-btn-hover-color: #f3f4f5;
    --si-btn-active-color: #f3f4f5;
    --si-btn-border-color: transparent;
    --si-btn-hover-border-color: transparent;
    --si-btn-active-border-color: transparent;
    --si-btn-bg: rgba(243, 244, 245, 0.06);
    --si-btn-hover-bg: rgba(243, 244, 245, 0.12);
    --si-btn-active-bg: rgba(243, 244, 245, 0.12);
  }

  .dark-mode .btn-light,
  .dark-mode .btn-outline-light,
  .dark-mode .btn-outline-secondary,
  .dark-mode .btn-secondary {
    --si-btn-disabled-color: rgba(255, 255, 255, 0.5);
  }

  .dark-mode .btn-dark {
    --si-btn-color: #252a44 !important;
    --si-btn-hover-color: #252a44 !important;
    --si-btn-active-color: #252a44 !important;
    --si-btn-bg: #edf1f5;
    --si-btn-hover-bg: #d9e2ea;
    --si-btn-active-bg: #d9e2ea;
  }

  .dark-mode .btn-scroll-top {
    --si-btn-scroll-top-color: #ffffff;
    --si-btn-scroll-top-hover-color: #ffffff;
    --si-btn-scroll-top-bg: rgba(255, 255, 255, 0.15);
    --si-btn-scroll-top-hover-bg: rgba(255, 255, 255, 0.3);
  }

  .dark-mode .col-form-label,
  .dark-mode .form-label {
    color: #ffffff;
  }

  .dark-mode .form-text {
    color: rgba(255, 255, 255, 0.5);
  }

  .dark-mode .form-control,
  .dark-mode .form-select {
    border-color: rgba(255, 255, 255, 0.18);
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode .form-control:focus,
  .dark-mode .form-select:focus {
    border-color: rgba(255, 255, 255, 0.35);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .dark-mode .form-control:disabled,
  .dark-mode .form-control[readonly],
  .dark-mode .form-select:disabled,
  .dark-mode .form-select[readonly] {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.01);
  }

  .dark-mode .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .form-control::-webkit-file-upload-button {
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode .form-control::file-selector-button {
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode
    .form-control:hover:not(:disabled):not(
      [readonly]
    )::-webkit-file-upload-button {
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode
    .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode
    .form-control:focus:not(:disabled):not(
      [readonly]
    )::-webkit-file-upload-button {
    background-color: #f2f2f2;
    color: #252a44;
  }

  .dark-mode
    .form-control:focus:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2;
    color: #252a44;
  }

  .dark-mode
    .form-control:focus:not(:disabled):not(
      [readonly]
    )::-webkit-file-upload-button {
    background-color: #f2f2f2;
    color: #252a44;
  }

  .dark-mode .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }

  .dark-mode .form-floating .form-control::-webkit-input-placeholder,
  .dark-mode .form-floating .form-select::-webkit-input-placeholder {
    color: transparent;
  }

  .dark-mode .form-floating .form-control::-moz-placeholder,
  .dark-mode .form-floating .form-select::-moz-placeholder {
    color: transparent;
  }

  .dark-mode .form-floating .form-control:-ms-input-placeholder,
  .dark-mode .form-floating .form-select:-ms-input-placeholder {
    color: transparent;
  }

  .dark-mode .form-floating .form-control::-ms-input-placeholder,
  .dark-mode .form-floating .form-select::-ms-input-placeholder {
    color: transparent;
  }

  .dark-mode .form-floating .form-control::placeholder,
  .dark-mode .form-floating .form-select::placeholder {
    color: transparent;
  }

  .dark-mode .form-floating .form-control:focus ~ label,
  .dark-mode .form-floating .form-select:focus ~ label {
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode .form-range::-webkit-slider-runnable-track {
    background-color: rgba(255, 255, 255, 0.09);
  }

  .dark-mode .form-range::-moz-range-track {
    background-color: rgba(255, 255, 255, 0.09);
  }

  .dark-mode .form-check-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode .form-check-input:not(:checked):not(.is-invalid):not(:invalid) {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .dark-mode .form-check-input:not(:checked) {
    background-color: transparent;
  }

  .dark-mode .form-switch .form-check-input {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .dark-mode .form-switch .form-check-input:checked {
    background-color: #146ad3;
  }

  .dark-mode .form-switch.mode-switch .form-check-label:first-of-type,
  .dark-mode .form-switch.price-switch .form-check-label:first-of-type {
    color: #ffffff;
  }

  .dark-mode .form-switch.mode-switch .form-check-input,
  .dark-mode .form-switch.price-switch .form-check-input {
    background-color: #146ad3;
  }

  .dark-mode
    .form-switch.mode-switch
    .form-check-input:checked
    ~ .form-check-label:first-of-type,
  .dark-mode
    .form-switch.price-switch
    .form-check-input:checked
    ~ .form-check-label:first-of-type {
    color: rgba(255, 255, 255, 0.6);
  }

  .dark-mode
    .form-switch.mode-switch
    .form-check-input:checked
    ~ .form-check-label:last-of-type,
  .dark-mode
    .form-switch.price-switch
    .form-check-input:checked
    ~ .form-check-label:last-of-type {
    color: #ffffff;
  }

  .dark-mode .form-switch.form-switch-success .form-check-input:checked {
    background-color: #17f1d2;
  }

  .dark-mode .form-control {
    color: rgba(255, 255, 255, 0.85);
  }

  .dark-mode .is-valid:not(:required):not(.btn),
  .dark-mode .was-validated :valid:not(:required):not(.btn) {
    border-color: rgba(255, 255, 255, 0.18) !important;
  }

  .dark-mode .credit-card-icon {
    background-position: 0 -26px;
  }

  .dark-mode .nav {
    --si-nav-link-color: rgba(255, 255, 255, 0.85);
    --si-nav-link-hover-color: #146ad3;
    --si-nav-link-active-color: #146ad3;
    --si-nav-link-disabled-color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .nav-tabs {
    --si-nav-tabs-link-bg: rgba(255, 255, 255, 0.2);
    --si-nav-tabs-link-hover-bg: rgba(105, 54, 245, 0.12);
    --si-nav-tabs-link-active-bg: #146ad3;
    --si-nav-tabs-link-disabled-bg: rgba(255, 255, 255, 0.04);
    --si-nav-tabs-link-color: rgba(255, 255, 255, 0.85);
    --si-nav-tabs-link-hover-color: #146ad3;
    --si-nav-tabs-link-active-color: #ffffff;
    --si-nav-tabs-link-disabled-color: rgba(255, 255, 255, 0.4);
  }

  .dark-mode .nav-tabs-alt {
    --si-nav-tabs-alt-border-color: rgba(255, 255, 255, 0.14);
  }

  .dark-mode .side-nav {
    --si-side-nav-border-color: rgba(255, 255, 255, 0.14);
  }

  .dark-mode .navbar {
    --si-navbar-stuck-bg: #000000;
    --si-navbar-brand-color: #ffffff;
    --si-navbar-brand-hover-color: #ffffff;
    --si-navbar-toggler-color: rgba(255, 255, 255, 0.85);
    --si-navbar-color: rgba(255, 255, 255, 0.85);
    --si-navbar-hover-color: #146ad3;
    --si-navbar-disabled-color: rgba(255, 255, 255, 0.4);
    --si-navbar-active-color: #146ad3;
  }

  .dark-mode .navbar-stuck {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .dark-mode .placeholder {
    opacity: 0.25;
  }

  @keyframes placeholder-glow {
    50% {
      opacity: 0.1;
    }
  }

  .dark-mode .placeholder-wave {
    -webkit-mask-image: linear-gradient(
      130deg,
      #0b0f19 55%,
      rgba(0, 0, 0, 0.9) 75%,
      #0b0f19 95%
    );
    mask-image: linear-gradient(
      130deg,
      #0b0f19 55%,
      rgba(0, 0, 0, 0.9) 75%,
      #0b0f19 95%
    );
  }

  .dark-mode .card {
    --si-card-border-color: rgba(255, 255, 255, 0.14);
    --si-card-bg: #000000;
    --si-card-color: rgba(255, 255, 255, 0.7);
  }

  .dark-mode .card.border-0,
  .dark-mode .card.shadow,
  .dark-mode .card.shadow-lg,
  .dark-mode .card.shadow-sm {
    background-color: var(--si-secondary-bg);
  }

  .dark-mode .card-hover:hover {
    border-color: rgba(255, 255, 255, 0.3);
    background-color: var(--si-secondary-bg);
  }

  .dark-mode .card-hover.border-0:not(.bg-transparent):not(.bg-light) {
    border: var(--si-border-width) solid transparent !important;
  }

  .dark-mode .card-hover.border-0:not(.bg-transparent):not(.bg-light):hover {
    border-color: var(--si-card-border-color) !important;
  }

  .dark-mode .card-hover.bg-light:hover {
    background-color: var(--si-secondary-bg) !important;
  }

  .dark-mode .popover {
    --si-popover-bg: #14171f;
    --si-popover-border-color: var(--si-border-color);
    --si-popover-body-color: var(--si-body-color);
    --si-popover-header-color: var(--si-heading-color);
    --si-popover-arrow-border: rgba(255, 255, 255, 0.2);
  }

  .dark-mode .progress {
    --si-progress-bg: rgba(255, 255, 255, 0.08);
  }

  .dark-mode .breadcrumb-item {
    --si-breadcrumb-item-active-color: #ffffff;
  }

  .dark-mode .btn-close {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .dark-mode .offcanvas,
  .dark-mode .offcanvas-lg,
  .dark-mode .offcanvas-md,
  .dark-mode .offcanvas-sm,
  .dark-mode .offcanvas-xl {
    --si-offcanvas-bg: #252a44;
  }

  .dark-mode .offcanvas-lg.bg-secondary,
  .dark-mode .offcanvas-md.bg-secondary,
  .dark-mode .offcanvas-sm.bg-secondary,
  .dark-mode .offcanvas-xl.bg-secondary,
  .dark-mode .offcanvas.bg-secondary {
    background-color: #14171f !important;
  }

  .dark-mode :not(pre) > code[class*="language-"],
  .dark-mode pre,
  .dark-mode pre[class*="language-"] {
    --si-pre-bg: rgba(255, 255, 255, 0.04);
    --si-pre-line-numbers-border-color: rgba(255, 255, 255, 0.14);
    --si-pre-line-numbers-color: rgba(255, 255, 255, 0.5);
    --si-pre-tag-color: #5960f3;
    --si-pre-comment-color: rgba(255, 255, 255, 0.5);
    --si-pre-attr-name-color: #4da2f7;
    --si-pre-attr-value-color: #f33185;
    --si-pre-class-name-color: #4ddcda;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .dark-mode code[class*="language-"],
  .dark-mode pre,
  .dark-mode pre > code,
  .dark-mode pre[class*="language-"] {
    --si-pre-color: #ffffff;
  }

  .dark-mode kbd {
    background-color: #010101;
    color: #ffffff;
  }

  .dark-mode .btn-next,
  .dark-mode .btn-prev {
    --si-carousel-nav-btn-bg: rgba(255, 255, 255, 0.05);
    --si-carousel-nav-btn-color: #ffffff;
    --si-carousel-nav-btn-box-shadow: none;
    --si-carousel-nav-btn-disabled-color: rgba(255, 255, 255, 0.5);
  }

  .dark-mode .ap-seek-slider::-webkit-slider-runnable-track {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0.55)),
      to(rgba(255, 255, 255, 0.3))
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.55) var(--buffered-width),
      rgba(255, 255, 255, 0.3) var(--buffered-width)
    );
  }

  .section-heading {
    color: #fafaf6;
    cursor: default;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.03em;
    line-height: 0.95;
  }

  .navbar-sticky-blur {
    -webkit-box-shadow: inset 0px 0px 2px #fefefe;
    box-shadow: inset 0px 0px 2px #fefefe;
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .blur {
    -webkit-box-shadow: inset 0px 0px 2px #fefefe;
    box-shadow: inset 0px 0px 2px #fefefe;
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .blur.saturation-less {
    -webkit-backdrop-filter: saturate(20%) blur(30px);
    backdrop-filter: saturate(20%) blur(30px);
  }

  .blur.blur-rounded {
    border-radius: 40px;
  }

  .blur.blur-light {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .blur.blur-dark {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .shadow-blur {
    -webkit-box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .shadow-card {
    -webkit-box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .navbar-blur {
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: #ffffff !important;
  }

  .blur-section {
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
  }

  .blur-section.blur-gradient-primary {
    background-image: linear-gradient(
      310deg,
      rgba(121, 40, 202, 0.95) 0%,
      rgba(255, 0, 128, 0.95) 100%
    );
  }

  .blur1 {
    -webkit-transition: -webkit-transform 0.5s
      cubic-bezier(0.5, 0.86, 0.36, 0.85);
    transition: -webkit-transform 0.5s cubic-bezier(0.5, 0.86, 0.36, 0.85);
    transition: transform 0.5s cubic-bezier(0.5, 0.86, 0.36, 0.85);
    transition: transform 0.5s cubic-bezier(0.5, 0.86, 0.36, 0.85),
      -webkit-transform 0.5s cubic-bezier(0.5, 0.86, 0.36, 0.85);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
  }

  .bg-opaque-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  .text-gradient-primary {
    background: linear-gradient(314.26deg, #0b0f19 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-primary1 {
    background: linear-gradient(314.26deg, #2c0dab 0%, #7b5ef3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-primary2 {
    background: radial-gradient(
      circle farthest-corner at 100% 100%,
      #00ffd1,
      #6084f8
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-secondary {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#f5f5f7),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #f5f5f7, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-success {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#17f1d2),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #17f1d2, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-dark {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#000000),
      color-stop(#5c88f6),
      to(#c546ef)
    );
    background: linear-gradient(to right, #000000, #5c88f6, #c546ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-light {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#f3f4f5),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #f3f4f5, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-warning {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ffd073),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #ffd073, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-danger {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ff4f00),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #ff4f00, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
