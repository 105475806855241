import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { GridBox } from "../../UIComponents/FourGrid/styles";

import { BackgroundImage } from "../../styles";
import convert3DImg from "../../../assets/images/2d-fp.svg";
import Img20 from "../../../assets/images/20.png";
import Img2 from "../../../assets/images/frame.svg";

export default function Marketing() {
  const Img1 = require("../../../assets/images/adding.gif");

  return (
    <div>
      <section className="bg-dark py-5rem">
        <div className="container pt-0 pt-sm-5">
          <div className="row pt-3 pb-2 py-md-4 align-items-center">
            <div className="col-12 col-sm-6 order-0 animate__animated animate__fadeInLeft">
              <PrimaryHeading
                primaryText="How it helps for Real Estate?"
                className="text-white align-items-start"
              />
              <h2 className="text-white">Marketing</h2>

              <p className="mb-4 pb-4 text-white">
                Give customers the ultimate feeling of being in control and
                boost engagement by letting online shoppers customize and
                personalize your products right from your store.
              </p>
            </div>
            <div className="col-12 col-sm-6 order-1 pt-5 pt-sm-0 animate__animated animate__fadeInRight">
              <img
                src={convert3DImg}
                className="card-img card-img-bottom bg-size-cover bg-repeat-0 bg-position-center"
                alt="Home Interior"
              />
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="bg-gradiant1 py-5rem "
        style={{
          background: `url(${Img2})`,
        }}
      >
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 animate__animated animate__fadeInUp">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6  mt-5 mt-sm-0 order-1 order-sm-0">
                <img src={Img1} alt="Home Interior" />
              </div>
              <div className="col-12 col-sm-6   order-0 order-sm-1 ps-0 ps-sm-5">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="">
                      <PrimaryHeading
                        primaryText="Place the Sofa at your home!"
                        className="text-white align-items-start"
                      />
                      <p className="sub-text text-white">
                        Step into a world where reality and imagination blend
                        seamlessly with AllesXR's augmented reality experiences.
                        Through cutting-edge technology, we enhance your
                        perception of the world around you by overlaying digital
                        information and interactive elements onto your
                        real-world environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </BackgroundImage>

      <Section>
        <div className="container position-relative">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <PrimaryHeading primaryText="How it helps for Real Estate?" />
              <p className="fs-5 mb-4 pb-4 ">
                No matter your industry, 3D Product Configurators can help you
                demonstrate why your products are the best!
              </p>
            </div>
          </div>
          <div>
            <div className="row g-4 pb-2 pb-sm-4 mb-sm-2">
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Include
                      <span className="text-warning"> Interactive</span> to
                      Highlight Product Features
                    </h3>
                    {/* <CanvasContainer>
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: 100,
                          height: "100%",
                        }}
                      />
                    </CanvasContainer> */}
                    <img
                      src={Img20}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img20}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Give customers the ultimate feeling of being in control
                      and boost engagement by letting online shoppers customize
                      and personalize your products right from your store.
                    </p>
                  </div>
                </GridBox>
              </div>
              <div className="col-12 col-sm-6">
                <GridBox>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">
                      Include
                      <span className="text-warning"> Interactive</span> to
                      Highlight Product Features
                    </h3>
                    {/* <CanvasContainer>
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: 100,
                          height: "100%",
                        }}
                      />
                    </CanvasContainer> */}
                    <img
                      src={Img20}
                      alt="Home Interior"
                      className="w-100 d-none d-sm-block"
                    />
                  </div>
                  <div>
                    <p className="text-left mb-3">
                      <img
                        src={Img20}
                        alt="Home Interior"
                        className="w-100 d-block d-sm-none"
                      />
                      Give customers the ultimate feeling of being in control
                      and boost engagement by letting online shoppers customize
                      and personalize your products right from your store.
                    </p>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section className="bg-black">
        <div className="rs-about">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional augmented reality. our team is ready
                    to assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
