import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";

export default function UIDesign() {
  const Lottie1 = require("../../../assets/lottie/ui-design.json");
  const Lottie2 = require("../../../assets/lottie/ui-2.json");

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="UI/UX Design"
                        className="text-white"
                      />
                      <p className="sub-text text-white">
                        UI/UX design is the process of designing user interfaces
                        (UI) and user experiences (UX) for digital products such
                        as websites, mobile apps, and software. The goal of
                        UI/UX design is to create a visually appealing and
                        intuitive interface that is easy to use and provides a
                        seamless user experience.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight mt-5 mt-sm-0">
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{
                    height: 400,
                  }}
                />
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-black">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-5 pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <Lottie
                    animationData={Lottie2}
                    loop={true}
                    style={{
                      height: 400,
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-7 ps-0 ps-sm-5 md-pl-15">
                <div className="sec-title text-white">
                  <h1 className="text-white">
                    Why Choose Us for UI/UX Design?
                  </h1>
                  <p className="margin-0 pt-15">
                    A well-designed UI/UX can improve the user experience and
                    increase engagement with your digital product.
                  </p>
                  <p className="margin-0 pt-15">
                    A good UI/UX design can help retain users by making it easy
                    to use and navigate your product.
                  </p>
                  <p className="margin-0 pt-15">
                    A well-designed UI/UX can also improve conversion rates by
                    guiding users through the conversion process and reducing
                    the likelihood of drop-offs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        ={" "}
      </Section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="UI/UX Design Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">User Research</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We conduct user research to understand user needs and
                        behavior, and use that data .{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">Usability Testing</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We conduct usability testing to ensure that our designs
                        are effective and user-friendly.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Accessibility <br />
                        </a>
                      </h5>
                      <p className="services-txt">
                        We design with accessibility in mind, ensuring that our
                        interfaces are usable by people with disabilities.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Elevate Your Digital Experiences with AllesXR" />
              <p className="mt-5">
                Partner with AllesXR to elevate your digital experiences with
                our expertise in UI/UX design. Whether you need a mobile app,
                website, or custom software interface, our talented designers
                will create a visually stunning and user-friendly design that
                engages your audience, drives user satisfaction, and helps you
                achieve your business goals.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText=" Intuitive and Seamless Interactions" />
              <p className="mt-5">
                We believe that a great UI/UX design should be intuitive and
                provide seamless interactions. Our designers focus on creating
                clear and logical navigation flows, intuitive icons and buttons,
                and visually pleasing layouts that guide users effortlessly
                through the application. We pay attention to the smallest
                details to ensure that every interaction feels natural and
                effortless, resulting in a delightful user experience.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
