import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Button from "../DesignSystem/Button";
import { WhyWorkWithUs, SectionHiring, SectionEachPosition } from "./styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const positionsData = [
    {
      designation: "Data Engineer",
      description:
        "We are seeking a skilled data engineer who can design, build, and maintain data pipelines and data warehouses. The ideal candidate should have experience with ETL tools, data modelling, and SQL.",
    },
    {
      designation: "Hadoop Developer",
      description:
        "We are looking for a Hadoop developer who can develop, maintain, and support our Hadoop infrastructure. The ideal candidate should have experience with Hadoop, Hive, Pig, and Spark.",
    },
    {
      designation: "Cloud Technologies Expert",
      description:
        "We are looking for a Cloud Technologies Expert who can help our clients migrate their applications and infrastructure to the cloud. The ideal candidate should have experience with cloud platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP).",
    },
    {
      designation: "Azure Architect",
      description:
        "We are seeking an experienced Azure Architect who can design and implement Azure-based solutions for our clients. The ideal candidate should have experience with Azure services like Azure App Service, Azure Functions, and Azure Kubernetes Service (AKS).",
    },
    {
      designation: "AWS Developer",
      description:
        "We are looking for an experienced AWS developer who can design, develop, and maintain AWS-based applications. The ideal candidates should have experience with AWS services like EC2, S3, and Lambda",
    },
    {
      designation: "GCP Engineer",
      description:
        "We are seeking a skilled GCP engineer who can design and implement GCP-based solutions for our clients. The ideal candidate should have experience with GCP services like Compute Engine, Cloud Storage, and BigQuery",
    },
    {
      designation: "DevOps Engineer",
      description:
        "We are seeking a skilled DevOps engineer who can design, develop, and maintain our continuous integration and deployment pipelines. The ideal candidate should have experience with containerization technologies like Docker and Kubernetes, as well as CI/CD tools like Jenkins or GitLab",
    },
    {
      designation: "Machine Learning Engineer",
      description:
        "We are seeking a skilled Machine Learning Engineer who can design, develop, and deploy ML models for our clients. The ideal candidate should have experience with Python, TensorFlow, and PyTorch.",
    },
    {
      designation: "AI Developer",
      description:
        "We are looking for an AI Developer who can design, develop,and deploy AI-based applications. The ideal candidate should have experience with AI frameworks like Keras and OpenAI.",
    },
    {
      designation: "Data Scientist",
      description:
        "We are seeking a skilled Data Scientist who can analyze data and develop insights for our clients. The ideal candidate should have experience with statistical analysis, data visualization, and machine learning",
    },
    {
      designation: "Salesforce Developer",
      description:
        "We are seeking a skilled Salesforce developer who can design, develop, and maintain Salesforce applications. The ideal candidate should have experience with Apex, Visualforce, and Salesforce Lightning",
    },
    {
      designation: "Java Developer",
      description:
        "We are looking for a Java developer who can design, develop, and maintain Java applications. The ideal candidate should have experience with Java, Spring, and Hibernate",
    },
    {
      designation: "QA Analyst",
      description:
        "We are seeking a skilled QA analyst who can develop and execute test plans, and ensure that software applications are of the highest quality. The ideal candidate should have experience with test automation, manual testing, and defect tracking.",
    },
    {
      designation: "Front-End Developer",
      description:
        "We are looking for a Front-End developer who can design, develop, and maintain user interfaces for web applications. The ideal candidate should have experience with HTML, CSS, and JavaScript",
    },
    {
      designation: "Business Analyst",
      description:
        "We are seeking a skilled Business Analyst who can analyze business requirements, and work with stakeholders to define business processes and systems. The ideal candidate should have experience with Agile methodologies, requirements gathering, and process modeling.",
    },
    {
      designation: "Cyber Security Expert",
      description:
        "We are looking for a Cyber Security Expert who can design, implement, and maintain security measures to protect our company's systems and data. The ideal candidate should have experience with security frameworks, risk assessments, and security audits.",
    },
    {
      designation: "Project Manager",
      description:
        "We are seeking an experienced Manager who can oversee our team of developers, analysts, and engineers. The ideal candidate should have experience with team management, project management, and software development.",
    },
  ];
  return (
    <div>
      <BgHeading>
        <Section className="bg-gray py-0">
          <div className="container">
            <Section className="about-main-content">
              <Primaryheading primaryText="Careers" secondaryText="" />
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="text-center">
                  Starlight launches branded products with a proprietary system
                  flow that <br />
                  gives you the highest return on investment in the online and
                  advertising
                  <br />
                  super world.
                </p>
                <Link to="/Contact">
                  <Button className="mt-3">Join With Us</Button>
                </Link>
              </div>
            </Section>
            <WhyWorkWithUs className="d-flex justify-content-center flex-column align-items-center mt-0 pb-3">
              <h3 className="pb-5">Why Work with us</h3>
              <div className="m-0 row">
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Mission Statement</h4>
                    <p>
                      Our mission is to empower our clients by providing
                      top-notch IT consulting services that help them navigate
                      the rapidly evolving digital landscape. We strive to build
                      long-lasting relationships with our clients by
                      understanding their unique needs and delivering customized
                      solutions that meet those needs. Our team of dedicated
                      professionals is committed to staying at the forefront of
                      technological advancements and leveraging them to help our
                      clients achieve success
                    </p>
                  </GridBox>
                </div>
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Vision Statement</h4>
                    <p>
                      Our vision is to become the leading provider of IT
                      consulting services in the industry by delivering
                      innovative solutions that empower businesses to achieve
                      their goals and stay ahead of the competition.
                    </p>
                  </GridBox>
                </div>
              </div>
            </WhyWorkWithUs>
          </div>
        </Section>

        <Section className="">
          <div className="container">
            <SectionHiring>
              <p className="badge">Open positions</p>
              <Primaryheading primaryText="We're Hiring" secondaryText="" />
              <p className="text-center">
                Welcome to our IT consulting company's current openings page! We
                are a fast-growing company that is always on the lookout for
                talented and motivated individuals to join our team. Below are
                the current job openings we have available
              </p>
              <div className="section-positions-list mt-5">
                {positionsData.map((item: any) => {
                  return (
                    <SectionEachPosition className="section-each-position">
                      <h2>{item.designation}</h2>
                      <div className="m-0 row d-flex justify-content-between align-items-center">
                        <p className="col-12 col-sm-9 m-0">
                          {item.description}
                        </p>
                        <div className="col-12 col-sm-3 d-flex justify-content-end">
                          <Link to="/Contact">
                            <Button>APPLY NOW</Button>
                          </Link>
                        </div>
                      </div>
                    </SectionEachPosition>
                  );
                })}
              </div>
            </SectionHiring>
          </div>
        </Section>
      </BgHeading>
    </div>
  );
}
