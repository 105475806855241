import React from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";
import convert3DImg from "../../../assets/images/2d-fp.svg";

export default function AugmentedReality() {
  const Lottie1 = require("../../../assets/lottie/virtual-reality-world.json");

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="Augmented Reality"
                        className="text-white"
                      />
                      <span className="sub-text text-white">
                        Step into a world where reality and imagination blend
                        seamlessly with AllesXR's augmented reality experiences.
                        Through cutting-edge technology, we enhance your
                        perception of the world around you by overlaying digital
                        information and interactive elements onto your
                        real-world environment. Explore limitless possibilities,
                        from interactive gaming to immersive educational
                        experiences, and witness how augmented reality
                        transforms the way you see and interact with the world.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight mt-5 mt-sm-0">
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{
                    height: 350,
                  }}
                />
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-white">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-lg-6 pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <img src={convert3DImg} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-lg-6 ps-0 ps-sm-5 md-pl-15">
                <div className="sec-title">
                  <h1>Why Choose Us for Augmented Reality?</h1>
                  <p className="margin-0 pt-15">
                    With extensive experience in augmented reality development,
                    our team possesses the expertise and skills to bring your AR
                    projects to life. We stay up-to-date with the latest AR
                    technologies and trends, ensuring that our solutions are
                    cutting-edge and impactful.
                  </p>
                  <p className="margin-0 pt-15">
                    We understand that every client has unique requirements. We
                    offer customized augmented reality solutions tailored to
                    your specific needs and objectives. Whether you need an AR
                    app, game, or marketing campaign, we can create a solution
                    that aligns with your vision and delivers the desired
                    outcomes.
                  </p>
                  <p className="margin-0 pt-15">
                    Our focus is on creating immersive and engaging user
                    experiences. We leverage AR technology to seamlessly blend
                    virtual content with the real world, providing users with
                    captivating and interactive experiences that leave a lasting
                    impression.
                  </p>
                  <p className="margin-0 pt-15">
                    Our expertise extends across various industries, including
                    education, gaming, marketing, retail, and more. We have
                    successfully delivered AR solutions for diverse use cases,
                    allowing us to adapt and cater to your industry-specific
                    needs effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-black">
        <div className="rs-about">
          <div className="container custom">
            <div className="m-0 row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22 text-white">
                    We're here to help! At AllesXR, we're passionate about
                    providing exceptional augmented reality. our team is ready
                    to assist you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section className=" bg-white">
        <div>
          <div className="container">
            <div className="sec-title mb-5">
              <PrimaryHeading primaryText="Augmented Reality Services " />
            </div>
            <div className="m-0 row">
              <div className="col-12 col-sm-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="/">Timely Delivery and Quality Assurance</a>
                    </h5>
                    <p className="services-txt">
                      {" "}
                      We value your time and understand the importance of timely
                      delivery. Our team works efficiently to meet project
                      deadlines without compromising on quality. We have robust
                      quality assurance processes in place to ensure that your
                      AR solution functions flawlessly and delivers a seamless
                      user experience.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="/">Industry Versatility</a>
                    </h5>
                    <p className="services-txt">
                      {" "}
                      Our extensive expertise spans across multiple industries,
                      encompassing education, gaming, marketing, retail, and
                      more. With a proven track record of delivering successful
                      augmented reality solutions for diverse use cases, we
                      possess the versatility and adaptability to effectively
                      address your industry-specific needs.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12">
                <div className="services-item">
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="/">Post-Development Support</a>
                    </h5>
                    <p className="services-txt">
                      Our commitment to you doesn't end with the delivery of the
                      AR solution. We provide ongoing support and maintenance to
                      address any issues or updates that may arise. Our team is
                      dedicated to ensuring that your augmented reality
                      experience continues to exceed expectations.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
