import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/allesxr-dark-logo.svg";

const Header: FunctionComponent = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg autohide fixed-top navbar-blur shadow-sm py-sm-2">
        <div className="container px-3">
          <Link
            to="/"
            className="navbar-brand pe-3 section-heading fs-3 text-dark"
          >
            <img src={logoImg} alt="" style={{ width: "80%" }} />
          </Link>
          <div id="navbarNav" className="offcanvas offcanvas-end vh-100">
            <div className="offcanvas-header border-bottom border-dark">
              <img src={logoImg} alt="" style={{ width: "30%" }} />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-end">
                <li className="nav-item dropdown dropdown-mega">
                  <a
                    href="/"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Product & Services
                    <i className="fa fa-chevron-down ms-2" />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/AugmentedReality" className="dropdown-item">
                        Augmented Reality
                      </Link>
                    </li>
                    <li>
                      <Link to="/VirtualReality" className="dropdown-item">
                        Virtual Reality
                      </Link>
                    </li>
                    <li>
                      <Link to="/MixedReality" className="dropdown-item">
                        Mixed Reality
                      </Link>
                    </li>
                    <li>
                      <Link to="/WebAR" className="dropdown-item">
                        Web AR
                      </Link>
                    </li>
                    <li>
                      <Link to="/DigitalTwins" className="dropdown-item">
                        Digital Twins
                      </Link>
                    </li>
                    <li>
                      <Link to="/UIDesign" className="dropdown-item">
                        UI/UX Design
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/XRRemoteCollaboration"
                        className="dropdown-item"
                      >
                        XR Remote Collaboration
                      </Link>
                    </li>
                    <li>
                      <Link to="/ThreeDModelling" className="dropdown-item">
                        3D Modelling & Animation
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    href="/"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Solutions <i className="fa fa-chevron-down ms-2" />
                  </a>
                  <div className="dropdown-menu p-0 ">
                    <div className="d-lg-flex">
                      <div className="mega-dropdown-column pt-lg-3 pb-lg-4 ">
                        <h5 className="cta-colorful-text1 px-3 mb-2">
                          Industries
                        </h5>
                        <ul className="custom-unstyled mb-3">
                          <li>
                            <Link
                              to="/RealEstate"
                              className="dropdown-item py-1"
                            >
                              Real Estate
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/Manufacturing"
                              className="dropdown-item py-1"
                            >
                              Manufacturing
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/Ecommerce"
                              className="dropdown-item py-1"
                            >
                              eCommerce
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/Education"
                              className="dropdown-item py-1"
                            >
                              Education
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="/Showcase" className="nav-link">
                    Showcase
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/AboutUs" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <Link
            className="ms-4 btn btn-primary rounded-5 border-0 d-none d-lg-inline-flex"
            rel="noopener"
            to=""
          >
            Experience AR Demo
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
    </>
  );
};

export default Header;
