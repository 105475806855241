import styled from "styled-components";
import { fontSize_SM, fontSize_XXXL } from "../customFonts";

export const Banner = styled.div`
  background-color: ${(props: any) => props.theme.primary.THEME};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: white;

  h2 {
    padding: 15px 0;
    font-weight: 600;

    @media (max-width: 480px) {
      padding-bottom: 20px;
    }

    &::after {
      content: "";
      border-bottom: 8px solid ${(props: any) => props.theme.danger.DANGER};
      width: 52px;
      border-radius: 4.5px;
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  h2,
  p {
    text-align: center;
  }

  section {
    width: 80%;

    img {
      padding: 50px 0;
      width: 100%;
      height: 300px;
    }
  }

  @media (max-width: 480px) {
    p {
      padding: 0 10px;
    }

    section {
      width: 100%;
    }
  }
`;

export const CountableWrapper = styled.div`
  h5 {
    padding-top: 30px;
    ${fontSize_XXXL};
    font-style: normal;
    font-weight: 400;
    line-height: 53px;
    text-align: center;
    color: #ffffff;
  }
`;

export const NumberColumn = styled.div`
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 89px;
    text-align: center;
    color: #ffdd79;
    padding: 30px 0;
  }

  p {
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    ${fontSize_SM};
  }
`;

export const Form = styled.div`
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;

  .row {
    padding-bottom: 20px;
  }

  input,
  textarea {
    background: #eeeeee;
  }
`;

export const StyledTabContainer = styled.section``;
