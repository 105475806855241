import React, { useRef, useEffect } from "react";
import HeaderBanner from "./HeaderBanner";
import SectionTabs from "./SectionTabs";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import Lottie from "lottie-react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CanvasContainer } from "../styles";
import ManThinkingImg from "../../assets/images/man-holding-software-subscription-gift.png";

export default function Home() {
  const canvasRef = useRef(null);

  const BuildHouseImg = require("../../assets/lottie/build-a-house-in-virtual-reality-world.json");
  const VirtualRealityMeetingImg = require("../../assets/lottie/virtual-reality-meeting.json");
  const MixedRealityImg = require("../../assets/lottie/mixed-reality.json");
  const PanoramicImg = require("../../assets/lottie/panoramic-vr.json");
  const ManShoppingHologram = require("../../assets/three/hand.glb");

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      750,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current ?? document.createElement("canvas"),
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    const loader = new GLTFLoader();
    loader.load(ManShoppingHologram, (gltf) => {
      scene.add(gltf.scene);
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(0, 4, 5);
    controls.update();

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(5, 5, 5);
    scene.add(pointLight);

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();
  });

  const gridData = [
    {
      primarytitle: "Immersive Property Tours",
      // title:
      //   "Welcome to AllesXR, where we're dedicated to revolutionizing the way you experience reality.",
      className: "text-primary",
      description:
        "These virtual tours allow buyers to see every detail in 3D and get a more engaging and memorable experience, leading to better sales conversion rates. ",
    },
    {
      primarytitle: "Virtual Design Visualization",
      // title: "Lorem Ipsum is simply dummy",
      className: "text-warning",
      description:
        "AllesXR's virtual design visualization services allow real estate developers and architects to visualize and showcase their designs in a fully immersive and interactive way.",
    },
    {
      primarytitle: "Pre-Construction Visualization",
      // title: "Lorem Ipsum is simply dummy",
      className: "text-primary",
      description:
        "AllesXR's pre-construction visualization services offer clients the chance to see their building or development in a realistic virtual model.",
    },
    {
      primarytitle: "Virtual Prototyping Visualization",
      // title: "Lorem Ipsum is simply dummy",
      className: "text-warning",
      description:
        "AllesXR enables manufacturers to create and test virtual models of their products before building them. VR technologies provide a fully immersive and interactive experience",
    },
  ];

  const tabsData = [
    {
      title: "Augmented Reality",
      description:
        "Step into a world where reality and imagination blend seamlessly with AllesXR's augmented reality experiences. Through cutting-edge technology, we enhance your perception of the world around you by overlaying digital information and interactive elements onto your real-world environment. Explore limitless possibilities, from interactive gaming to immersive educational experiences, and witness how augmented reality transforms the way you see and interact with the world.",
      id: "tabone",
      src: BuildHouseImg,
      show: "active",
    },
    {
      title: "Virtual Reality",
      description:
        "Immerse yourself in a realm of infinite possibilities with AllesXR's virtual reality experiences. Through state-of-the-art technology, we transport you to entirely new worlds, unbound by the constraints of reality. Dive into breathtaking landscapes, embark on thrilling adventures, or step into historical events – all from the comfort of your own space. With AllesXR, the boundaries of reality are shattered, and a universe of unparalleled experiences awaits. ",
      id: "tabtwo",
      src: VirtualRealityMeetingImg,
      show: "",
    },
    {
      title: "Mixed Reality",
      description:
        "Experience a groundbreaking fusion of the physical and digital realms with AllesXR's mixed reality solutions. Through the seamless integration of virtual elements into your real-world environment, we create a truly immersive and interactive experience. Interact with holographic objects, manipulate digital content with natural gestures, and collaborate with others in real-time, all while maintaining a connection to the physical world. With AllesXR's mixed reality, you can bridge the gap between imagination and reality, unlocking endless possibilities for work, play, and exploration.",
      id: "tabthree",
      src: MixedRealityImg,
      show: "",
    },
  ];

  return (
    <main className="page-wrapper">
      <HeaderBanner />

      <section className="mt-n1 mt-md-0 py-5 bg-white">
        <div className="container pt-5">
          <div className="m-0 row justify-content-center text-center animate__animated animate__fadeInUp">
            <div className="col-xl-10 col-lg-10 col-md-12 px-2">
              <Primaryheading
                primaryText="Immerse Yourself in Extraordinary Worlds "
                secondaryText=""
              />
              <p className="">
                Experience the magic of AllesXR as you immerse yourself in
                extraordinary worlds. Our innovative technologies take you on
                thrilling adventures, let you witness breathtaking sights, and
                unleash the full potential of your imagination, all from the
                comfort of your own reality.
              </p>
            </div>
          </div>
          <div className="m-0 row justify-content-center text-center pt-md-3 pb-0 py-lg-5 h-100">
            <ul
              className="nav nav-tabs flex-wrap justify-content-lg-center justify-content-center text-center overflow-auto zindex-5 pt-3 pb-1 fs-sm"
              role="tablist"
            >
              <li className="nav-item ">
                <a
                  href="#tabone"
                  className="nav-link active rounded-pill"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  Augumented Reality
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tabtwo"
                  className="nav-link rounded-pill"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  Virtual Reality
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tabthree"
                  className="nav-link rounded-pill"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  Mixed Reality
                </a>
              </li>
            </ul>
            <div className="tab-content">
              {tabsData.map((item: any, index) => {
                return (
                  <GridBox
                    key={index}
                    className={`bg-light tab-pane fade show ${item.show}`}
                    id={item.id}
                    role="tabpanel"
                    aria-labelledby="ecommerce-tab"
                  >
                    <div className="m-0 row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0 h-100">
                      <div className="col-lg-5 col-md-5 offset-lg-1 text-md-start">
                        <h3 className="mb-lg-4">{item.title}</h3>
                        <p style={{ textAlign: "left" }}>{item.description}</p>
                      </div>
                      <div className="col-lg-5 col-md-5 mt-2 mb-3 mt-md-3 text-center">
                        <div
                          className="position-relative mx-auto"
                          // style={{ maxHeight: "500px" }}
                        >
                          {/* <img src={item.src} alt="Icon" /> */}
                          <Lottie
                            animationData={item.src}
                            loop={true}
                            style={{
                              height: 350,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </GridBox>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark dark-mode mt-n1 mt-md-0 py-0 bg-size-cover bg-repeat-0 bg-position-bottom position-relative overflow-hidden py-5">
        <div className="container">
          <div className="m-0 row justify-content-center text-center pt-md-3 pb-4 py-lg-5 mb-1">
            <div className="col-xl-10 col-lg-8 col-md-10">
              <Primaryheading
                primaryText="Transforming Reality with Immersive VR "
                secondaryText=""
              />
              <p className="fs-5">
                At AllesXR, we believe that virtual reality has the power to
                transform the way we live, work, and play. That's why we're
                dedicated to creating the most immersive and engaging VR
                experiences possible. Our team is constantly pushing the
                boundaries of what's possible with VR technology, and we're
                always on the lookout for new and innovative ways to use VR to
                enhance people's lives.
              </p>
            </div>
          </div>
          <div className="m-0 row align-items-center pt-2">
            <div className="col-md-6 col-sm-6 order-md-1">
              <div className="position-relative ms-auto">
                {/* <img src={Img15} alt="" /> */}
                <CanvasContainer>
                  <canvas
                    ref={canvasRef}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </CanvasContainer>
              </div>
            </div>
            <div className="col-md-5 col-sm-5 order-md-2 text-md-start text-center pt-5 pt-sm-2 ms-0 ms-sm-5">
              <h2 className="h4 mb-4 fw-base">
                {/* <img
                  className="text-md-start text-center me-1 lazyload"
                  src={ARViewImg}
                  width={30}
                  alt="Image"
                />{" "} */}
                <div className="d-flex align-items-center">
                  <Lottie
                    animationData={PanoramicImg}
                    loop={true}
                    style={{
                      height: 100,
                    }}
                  />
                  <div className="ps-3">
                    Experience Next-Level Virtual Reality Today!
                  </div>
                </div>
              </h2>

              <p className="lead mb-4">
                Whether you're a gamer looking for the ultimate immersive
                experience, a business looking to create engaging VR content for
                your customers, or an educator looking to bring your lessons to
                life, AllesXR has the tools and expertise you need to make your
                VR dreams a reality. So why settle for the same old reality when
                you can experience a whole new world with AllesXR?
              </p>
            </div>
          </div>
        </div>
      </section>

      <SectionTabs />

      <section
        className="bg-dark dark-mode mt-0 mt-sm-n1 mt-md-0 py-5"
        id="features"
      >
        <div className="container">
          <div className="m-0 row justify-content-center text-center pt-md-3 pb-4 py-lg-5 mb-1">
            <div className="col-xl-9 col-lg-9 col-md-10">
              <Primaryheading
                primaryText="Revolutionizing Virtual Reality Experiences. "
                secondaryText=""
              />
              <p className="fs-5 mb-3">
                AllesXR is dedicated to creating the most immersive and engaging
                VR experiences by utilizing cutting-edge technology and a
                passionate team of experts.
              </p>
            </div>
          </div>
          <div className="m-0 row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2 g-sm-3 g-lg-4 py-md-3 py-lg-5">
            {gridData.map((item: any, index) => {
              return (
                <div className="col" key={index}>
                  <div className="card card-body card-hover border-0">
                    <i className="bx bx-layer-plus display-4 fw-light cta-colorful-text1 mb-2" />
                    <h2 className="h5 text-dark">
                      <span className={`${item.className} h4`}>
                        {item.primarytitle}
                      </span>{" "}
                      <br />
                      {item.title}
                    </h2>
                    <p className="mb-0">{item.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container py-5">
          <div className="m-0 row justify-content-center align-items-center">
            <div className="col-md-6 order-md-2 order-sm-2">
              <div className="card border-0 bg-transparent text-dark h-100 rounded-5 mb-sm-3">
                <div className="m-0 row g-0">
                  <div className="d-block align-self-center mt-n4 mt-lg-0">
                    <img
                      className="d-block lazyload"
                      src={ManThinkingImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 order-md-1 order-sm-1 text-md-start text-center pt-sm-4 pe-md-4 pe-sm-0">
              <Primaryheading
                primaryText="Driving VR Innovation with Expertise"
                secondaryText=""
              />
              <p className="lead mb-4">
                Our team of experienced developers and engineers are passionate
                about pushing the boundaries of what's possible with virtual
                reality technology. We're constantly innovating and developing
                new products and services to enhance your experience and bring
                you even closer to the world of your dreams.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
