import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";

import AugmentedReality from "../Pages/Products/AugmentedReality";
import VirtualReality from "../Pages/Products/VirtualReality";
import MixedReality from "../Pages/Products/MixedReality";
import WebAR from "../Pages/Products/WebAR";
import DigitalTwins from "../Pages/Products/DigitalTwins";
import XRRemoteCollaboration from "../Pages/Products/XRRemoteCollaboration";
import UIDesign from "../Pages/Products/UIDesign";
import ThreeDModelling from "../Pages/Products/ThreeDModelling";

import RealEstate from "../Pages/Solutions/RealEstate";
import Ecommerce from "../Pages/Solutions/Ecommerce";
import Marketing from "../Pages/Solutions/Marketing";
import Education from "../Pages/Solutions/Education";
import Manufacturing from "../Pages/Solutions/Manufacturing";

import ShowCase from "../Pages/ShowCase";
import Careers from "../Careers";
import Videos from "../Pages/Videos";

import TemplateOne from "../Pages/ShowCase/Works/TemplateOne";
import TemplateTwo from "../Pages/ShowCase/Works/TemplateTwo";

// Renamed the functional component to avoid naming conflict
function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/AugmentedReality" element={<AugmentedReality />} />
      <Route path="/VirtualReality" element={<VirtualReality />} />
      <Route path="/MixedReality" element={<MixedReality />} />
      <Route path="/WebAR" element={<WebAR />} />
      <Route path="/DigitalTwins" element={<DigitalTwins />} />
      <Route
        path="/XRRemoteCollaboration"
        element={<XRRemoteCollaboration />}
      />
      <Route path="/UIDesign" element={<UIDesign />} />
      <Route path="/ThreeDModelling" element={<ThreeDModelling />} />
      <Route path="/RealEstate" element={<RealEstate />} />
      <Route path="/Ecommerce" element={<Ecommerce />} />
      <Route path="/Marketing" element={<Marketing />} />
      <Route path="/Education" element={<Education />} />
      <Route path="/Manufacturing" element={<Manufacturing />} />
      <Route path="/ShowCase" element={<ShowCase />} />
      <Route path="/Careers" element={<Careers />} />
      <Route path="/Videos" element={<Videos />} />
      <Route path="/TemplateOne" element={<TemplateOne />} />
      <Route path="/TemplateTwo" element={<TemplateTwo />} />
    </Routes>
  );
}

export default AppRouter;
