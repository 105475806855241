import React, { useEffect, useRef } from "react";
import { CanvasContainer, Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import P1 from "../../../assets/images/eye.png";

export default function WebAR() {
  const canvasRef = useRef(null);

  const webAR1 = require("../../../assets/three/people-building-metaverse-5984570.glb");

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      750,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current ?? document.createElement("canvas"),
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);

    const loader = new GLTFLoader();
    loader.load(webAR1, (gltf) => {
      const model = gltf.scene;
      model.position.set(1, -3, 1);
      scene.add(model);

      const animate = () => {
        requestAnimationFrame(animate);

        // Rotate the model
        model.rotation.y += 0.01; // Adjust the rotation speed as needed

        renderer.render(scene, camera);
      };

      animate();
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(1, 5, 30);
    controls.update();

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(1, 5, 5);
    scene.add(pointLight);
  });

  return (
    <div>
      <section className="bg-black ">
        <div className="container ">
          <Section className="mt-n1 mt-md-0 py-5 ">
            <div className="row m-0 d-flex align-items-center">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="rs-breadcrumbs img4 aws-banner">
                  <div className="container">
                    <div className="breadcrumbs-inner">
                      <PrimaryHeading
                        primaryText="WebAR"
                        className="text-white"
                      />
                      <p className="sub-text text-white">
                        Web AR is a technology that allows augmented reality
                        experiences to be accessed directly from a web browser,
                        without the need for users to download a separate app.
                        By leveraging the power of the web, Web AR makes
                        augmented reality more accessible and convenient for
                        users.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight mt-5 mt-sm-0">
                <CanvasContainer>
                  <canvas
                    ref={canvasRef}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </CanvasContainer>
              </div>
            </div>
          </Section>
        </div>
      </section>

      <Section className="bg-black">
        <div className="rs-about py-0 animate__animated animate__fadeInUp">
          <div className="container custom">
            <div className="m-0 row d-flex align-items-center">
              <div className="col-12 col-sm-5 pt-5 pt-sm-0 order-1 order-sm-0">
                <div className="images-part">
                  <img src={P1} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-7 ps-0 ps-sm-5 md-pl-15">
                <div className="sec-title text-white">
                  <h1 className="text-white">Why Choose Us for WebAR?</h1>
                  <p className="margin-0 pt-15">
                    With Web AR, users can access augmented reality experiences
                    with just a click of a link, eliminating the need for them
                    to download and install a separate app. Web AR can be
                    developed using standard web development tools, making it
                    more cost-effective than native app development. Web AR
                    experiences can be seamlessly integrated into websites and
                    social media platforms, providing a more engaging and
                    interactive experience for users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        ={" "}
      </Section>

      {/* <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="WebAR Services" />
            </div>
            <div className="m-0 row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                  
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Custom Web AR <br />
                          Solutions
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Our team of experts can work with you to create
                        customized Web AR solutions that meet your specific
                        business or personal needs.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Web AR Content <br /> Creation
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We can help you create engaging and interactive Web AR
                        experiences, such as 3D models, animations, and
                        interactive games.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="/">
                          Consulting and <br />
                          Support
                        </a>
                      </h5>
                      <p className="services-txt">
                        We provide consulting and support services to help you
                        navigate the world of Web AR and ensure that your
                        projects are successful.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section> */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Unlock the potential of WebAR" />
              <p className="mt-5">
                With AllesXR's WebAR solutions, you can engage your audience in
                a whole new way. From interactive product visualizations and
                virtual try-ons to educational experiences and captivating
                marketing campaigns, WebAR opens up a realm of possibilities for
                your online presence. Deliver unique and immersive AR
                experiences directly to your users, enhancing their engagement,
                increasing brand awareness, and driving conversions.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-white">
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="   Seamless integration and widespread accessibility" />
              <p className="mt-5">
                One of the major advantages of WebAR is its seamless integration
                into existing web platforms. AllesXR ensures that our WebAR
                solutions are compatible with a wide range of devices, making it
                accessible to users across desktops, laptops, smartphones, and
                tablets. No app installation or complex setup is required –
                simply access the AR content directly through your web browser,
                and let the magic unfold.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
